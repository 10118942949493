import React from 'react';
import { Popover, Menu, Space, Avatar, Typography } from 'antd';
import { firebase } from '../firebase';

function UserMenu({ data }) {
  return (
    <Popover
      content={
        <Menu mode="vertical">
          <Menu.Item onClick={() => firebase.auth().signOut()}>
            Sign out
          </Menu.Item>
        </Menu>
      }
    >
      <Space style={{ margin: '12px 24px' }} align="center">
        <Avatar
          style={{ backgroundColor: 'orange', verticalAlign: 'middle' }}
          size="large"
        >
          {data?.fullName[0]}
        </Avatar>
        <Space direction="vertical" size={0}>
          <Typography.Title style={{ margin: 0 }} level={4}>
            {data?.fullName}
          </Typography.Title>
          <Typography.Text>{data?.email}</Typography.Text>
        </Space>
      </Space>
    </Popover>
  );
}

export default UserMenu;
