import { Button, Checkbox, message, Modal, Radio } from 'antd';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import api from '../../../common/api';
import { firebase } from '../../../firebase';

function AssignLicense({ orgId, organisationLicenses }) {
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [assignType, setAssignType] = useState('all');
  const [types, setTypes] = useState([]);
  const [availableLicense, setAvailableLicense] = useState([]);

  const showModal = () => {
    setVisible(true);
  };
  useEffect(() => {
    if (assignType === 'selected') {
      const items = [];
      organisationLicenses.map((license) => {
        if (license?.unassigned > 0) {
          items.push(license?.licenseType);
        } else {
          return;
        }
      });
      setAvailableLicense(items);
    } else {
      setTypes(['TEAM', 'CLEARBIT', 'API_AUTO', 'CUSTOM_DIGI']);
    }
  }, [assignType]);

  const assignLicense = async () => {
    setLoading(true);
    await api
      .post(
        `${process.env.REACT_APP_PDF_DOWNLOAD_URL}/mat/license/assign-available`,
        {
          orgId,
          types
        },
        {
          headers: {
            secretkey: process?.env?.REACT_APP_PDF_FIX_SECRET
          }
        }
      )
      .then(() => {
        setVisible(false);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };
  return (
    <>
      <Button type="primary" onClick={() => showModal()}>
        Assign License
      </Button>
      <Modal
        title="Assign License"
        visible={visible}
        onCancel={() => {
          setVisible(false);
        }}
        onOk={assignLicense}
        okButtonProps={{ loading }}
      >
        <Radio.Group
          onChange={(e) => {
            setAssignType(e?.target?.value);
          }}
        >
          <Radio.Button value="all">Assign All Available Licenses</Radio.Button>
          <Radio.Button value="selected">Assign Selected Licenses</Radio.Button>
        </Radio.Group>
        {assignType === 'selected' && availableLicense?.length > 0 && (
          <div>
            <Checkbox.Group
              options={availableLicense}
              onChange={(checkedValues) => {
                setTypes(checkedValues);
              }}
            />
          </div>
        )}
      </Modal>
    </>
  );
}

export default AssignLicense;
