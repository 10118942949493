import React, { useState } from 'react';
import { Button, Modal, Form, Input, Select, DatePicker, message } from 'antd';
import moment from 'moment';
import { LICENSE_TYPES } from '../../../common/constant';
import formatDate from '../../../common/utils';
import { firebase } from '../../../firebase';
import api from '../../../common/api';

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 }
};
const tailLayout = {
  wrapperCol: { offset: 8, span: 16 }
};
const AddLicense = ({ orgId }) => {
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [form] = Form.useForm();

  const showModal = () => {
    setVisible(true);
  };

  const findLicenseKeyByLicenseValue = (object, value) => {
    return Object.keys(object).find((key) => object[key] === value);
  };

  const onFormSubmit = async (formData) => {
    setLoading(true);
    const { type, quantity, expiryDate } = formData;
    const licenseKey = findLicenseKeyByLicenseValue(LICENSE_TYPES, type);
    const updatedDate = formatDate(expiryDate);
    try {
      await api.post(
        `${process.env.REACT_APP_PDF_DOWNLOAD_URL}/mat/license/add`,
        {
          orgId: orgId,
          type: licenseKey,
          expiryDate: updatedDate,
          quantity: quantity
        },
        {
          headers: {
            secretkey: process?.env?.REACT_APP_PDF_FIX_SECRET
          }
        }
      );
      setLoading(false);
    } catch (error) {}
    setVisible(false);
    form.resetFields();
  };

  const disabledDateHandler = (current) => {
    let customDate = moment().format('YYYY-MM-DD');
    return current && current < moment(customDate, 'YYYY-MM-DD');
  };

  return (
    <>
      <Button type="primary" onClick={() => showModal()}>
        Add License
      </Button>
      <Modal
        title="Add License"
        visible={visible}
        footer={null}
        onCancel={() => {
          setVisible(false);
          form.resetFields();
        }}
      >
        <Form {...layout} form={form} name="basic" onFinish={onFormSubmit}>
          <Form.Item label="Select License" name="type">
            <Select placeholder="Please select">
              {Object.entries(LICENSE_TYPES).map(([key, value]) => {
                return (
                  <Select.Option key={key} value={value}>
                    {value}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
          <Form.Item {...layout} label="Select Expiry Date" name="expiryDate">
            <DatePicker
              disabledDate={disabledDateHandler}
              style={{ width: '315px' }}
            />
          </Form.Item>
          <Form.Item {...layout} label="quantity" name="quantity">
            <Input type="number" />
          </Form.Item>
          <Form.Item {...tailLayout}>
            <Button type="primary" htmlType="submit" loading={loading}>
              Add License
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default AddLicense;
