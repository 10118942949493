import React from 'react';
import { Button, Modal, Form, Input, Select, message, Checkbox } from 'antd';
import { firebase } from '../../../firebase';
import { nanoid } from 'nanoid';

const axios = require('axios');

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 }
};
const tailLayout = {
  wrapperCol: { offset: 8, span: 16 }
};

function AddDepartment({ orgId }) {
  const [visible, setVisible] = React.useState(false);
  const [form] = Form.useForm();
  const [loading, setLoading] = React.useState(false);

  const handleSubmit = async (event) => {
    const { name } = event;
    setLoading(true);
    try {
      const departmentId = nanoid(12);
      await firebase
        .firestore()
        .collection('organizations')
        .doc(orgId)
        .collection('departments')
        .doc(departmentId)
        .set({
          name,
          orgId,
          members: [],
          leadPage: '',
          id: departmentId,
          customLeadPage: false,
          customSocialPage: false,
          addressLine1: '',
          addressLine2: '',
          city: '',
          country: '',
          state: '',
          zipcode: '',
          //New fields
          companyName: '',
          facebookUrl: '',
          googlemaps: '',
          hasRawProfileGenerated: false,
          instagramUrl: '',
          logoUrl: '',
          stockTicker: '',
          twitterUrl: '',
          website: '',
          youtubeUrl: ''
        })
        .then(() => {
          const config = { locked: false, show: true };
          firebase
            .firestore()
            .collection('organizations')
            .doc(orgId)
            .collection('departments')
            .doc(departmentId)
            .collection('config')
            .doc('fieldConfig')
            .set({
              addressLine1Config: config,
              addressLine2Config: config,
              cityConfig: config,
              companyConfig: config,
              countryConfig: config,
              emailConfig: config,
              facebookConfig: config,
              fullNameConfig: config,
              instagramConfig: config,
              appleAppStoreConfig: config,
              calendarLinkConfig: config,
              linkedInConfig: config,
              mobileNumConfig: config,
              phoneNumConfig: config,
              pinterestConfig: config,
              playstoreConfig: config,
              soundcloudConfig: config,
              snapchatConfig: config,
              spotifyConfig: config,
              stateConfig: config,
              titleConfig: config,
              twitterConfig: config,
              venmoConfig: config,
              webLinkConfig: config,
              webLink01Config: config,
              webLink02Config: config,
              websiteConfig: config,
              whatsappConfig: config,
              youtubeUrlConfig: config,
              zipcodeConfig: config,
              tikTokConfig: config,
              paypalConfig: config,
              weChatConfig: config,
              twitchConfig: config,
              cashAppConfig: config,
              parlerConfig: config,
              qqAppConfig: config,
              faxConfig: config,
              realEstateLicenseConfig: config,
              profileNoteConfig: config,
              certificatesConfig: config,
              deptNameConfig: config,
              yelpConfig: config,
              signalConfig: config,
              discordConfig: config,
              skypeConfig: config,
              telegramConfig: config,
              messengerConfig: config
            });
          firebase
            .firestore()
            .collection('organizations')
            .doc(orgId)
            .collection('departments')
            .doc(departmentId)
            .collection('config')
            .doc('overrideConfig')
            .set({
              overrideOrgAddress: false,
              overrideOrgCity: false,
              overrideOrgCountry: false,
              overrideOrgState: false,
              overrideOrgZipcode: false,
              overrideOrgWeb: false,
              overrideOrgLogo: false,
              overrideOrgCompany: false,
              overrideOrgAvatar: false,
              overrideOrgFacebook: false,
              overrideOrgInstagram: false,
              overrideOrgYoutube: false,
              overrideOrgLinkedInPage: false,
              overrideOrgTwitter: false
            });
        });
      setLoading(false);
      setVisible(false);
      form.resetFields();
      message.success('Group added successfully');
    } catch (error) {
      alert(error);
      setLoading(false);
    }
  };
  const showModal = () => {
    setVisible(true);
  };
  return (
    <>
      <Button type="primary" onClick={() => showModal()}>
        Create Group
      </Button>
      <Modal
        title="Create Group"
        visible={visible}
        footer={null}
        onCancel={() => setVisible(false)}
      >
        <Form
          {...layout}
          form={form}
          name="basic"
          onFinish={handleSubmit}
          initialValues={{ remember: true }}
        >
          <Form.Item
            label="Name"
            name="name"
            rules={[{ required: true, message: 'Please enter Name!' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item {...tailLayout}>
            <Button
              type="primary"
              loading={loading}
              disabled={loading}
              htmlType="submit"
            >
              Create
            </Button>
            <Button
              type="default"
              onClick={() => {
                form.resetFields();
                setVisible(false);
              }}
              style={{ marginLeft: '1em' }}
            >
              Cancel
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}

export default AddDepartment;
