export const handleOrderLengthAndScrollOffset = (
  dispatch,
  orderLength,
  scrollOffset,
  searchText,
  searchedColumn,
  selectedOrderType
) => {
  dispatch({ type: 'SET_TOTAL_ORDERS', data: orderLength });
  dispatch({
    type: 'SET_SCROLL_OFFSET',
    data: scrollOffset
  });
  dispatch({ type: 'SET_SEARCH_TEXT', data: searchText });
  dispatch({ type: 'SET_SEARCHED_COLUMN', data: searchedColumn });
  dispatch({ type: 'SET_ORDER_TYPE', data: selectedOrderType });
};
