import React, { useEffect } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import 'antd/dist/antd.css';
import Home from './components/Home';
import { AuthProvider } from './Auth';
import Loader from './components/Loader/Loader';
import { auth } from './firebase';

function App() {
  const [user, loading] = useAuthState(auth);

  if (loading) return <Loader />;

  return (
    <div>
      <AuthProvider>
        <Home />
      </AuthProvider>
    </div>
  );
}

export default App;
