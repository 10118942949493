import api from '../common/api';

export const getCodeSnippet = (orgId) => {
  let data = {};
  const URL = `${process.env.REACT_APP_SERVER_URL_V2}/org/snippet?orgId=${orgId}&placement=`;

  return Promise.all([api.get(`${URL}header`), api.get(`${URL}body`)])
    .then((responses) => {
      const [headerResp, bodyResp] = responses;
      data.headerResp = headerResp?.data;
      data.bodyResp = bodyResp?.data;
      return data;
    })
    .catch((err) => console.log(err));
};
