import React, { useState, useEffect, useRef, useContext } from 'react';
import { Link, useHistory } from 'react-router-dom';

import {
  Button,
  Card,
  Col,
  message,
  Row,
  Space,
  Table,
  DatePicker,
  Tooltip,
  Typography,
  Badge,
  Modal
} from 'antd';
import confirm from 'antd/lib/modal/confirm';
import {
  UndoOutlined,
  DeleteOutlined,
  ExportOutlined,
  CheckCircleOutlined,
  FlagOutlined,
  UpCircleOutlined,
  FlagFilled,
  EyeOutlined,
  SyncOutlined,
  SettingOutlined,
  MessageTwoTone,
  MessageOutlined
} from '@ant-design/icons';
import moment from 'moment';
import _isEmpty from 'lodash/isEmpty';

import { LIMIT_PRIMARY } from '../../../common/constant';
import { firebase } from '../../../firebase';
import { generateDate } from '../../../utils/generateDate';
import GetVendorNameFromOrderState from '../../../utils/getVendorNameFromOrderState';
import GetAddOns from './GetAddOns';
import { getColumnSearchPropsV2 } from '../../../utils/searchV2';
import { getNewMessageIndicator } from '../../../utils/getNewMessageIndicator';
import { AuthContext } from '../../../Auth';
import GetOrgLink from '../../../utils/getOrgLink';
import './orders.css';
import { catchErrorInSentry, scrollToTop } from '../../../common/utils';
import ChangePrinter from './ChangePrinter';

const { RangePicker } = DatePicker;

function Orders() {
  const history = useHistory();
  const [orders, setOrders] = useState([]);
  const [date, setDate] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);
  const [offset, setOffset] = useState();
  const [visible, setVisible] = useState(false);
  const [scrollTopVisible, setScrollTopVisible] = useState(false);
  const [sorting, setSorting] = useState('desc');
  const [sortColumn, setSortColumn] = useState('createdAt');
  const [sorterInfo, setSorterInfo] = useState();
  const [selectedOrderType, setSelectedOrderType] = useState('system');
  const [variation, setVariation] = useState([]);
  const [action, setAction] = useState();
  const { data } = useContext(AuthContext);
  let ref = firebase.firestore().collection('orders');
  if (searchedColumn && searchText) {
    if (
      searchedColumn === 'orderNumber' ||
      searchedColumn === 'orderUniqueId'
    ) {
      if (selectedOrderType === 'system') {
        ref = ref
          .orderBy(searchedColumn)
          .startAt(searchText?.toUpperCase())
          .endAt(searchText?.toUpperCase() + '\uf8ff');
      } else if (selectedOrderType === 'woocommerce') {
        ref = ref
          .orderBy('shippingAddress.order_number')
          .startAt(searchText?.toUpperCase())
          .endAt(searchText?.toUpperCase() + '\uf8ff');
      }
    } else if (sorting && sortColumn === 'updatedAt') {
      ref = ref
        .orderBy(searchedColumn)
        .orderBy(sortColumn, sorting)
        .startAt(searchText?.toLowerCase())
        .endAt(searchText?.toLowerCase() + '\uf8ff');
    } else {
      ref = ref
        .orderBy(searchedColumn)
        .startAt(searchText?.toLowerCase())
        .endAt(searchText?.toLowerCase() + '\uf8ff');
    }
  }

  if (variation?.length > 0) {
    ref = ref.where('cardVariation', 'in', variation);
  }
  if (action !== null && action !== undefined) {
    ref = ref.where('bug', '==', action?.[0] === 'true' ? true : false);
  }
  if (
    !searchText &&
    !variation?.length &&
    action == null &&
    action == undefined
  ) {
    ref = ref.orderBy(sortColumn, sorting);
  }
  ref = ref.limit(LIMIT_PRIMARY);
  // Change printer button should only available for below 2 card types
  const CARD_TYPE_TO_ASSIGN_PRINTER = ['Mobilo Card (custom)', 'Mobilo Card'];

  function getOrder() {
    setLoading(true);
    ref.onSnapshot((querySnapshot) => {
      const items = [];
      querySnapshot.forEach((doc) => {
        let orderNumber = doc?.data()?.orderNumber;
        if (doc?.data()?.shippingAddress?.order_number) {
          orderNumber = `${orderNumber} (${
            doc?.data()?.shippingAddress?.order_number
          })`;
        }
        items.push({ ...doc?.data(), docId: doc?.id, orderNumber });
      });
      if (items?.length < LIMIT_PRIMARY) {
        setHasMore(false);
      }
      setOffset(querySnapshot?.docs[querySnapshot?.docs?.length - 1]);
      setOrders(items);
      setLoading(false);
    });
  }

  const getNextOrder = async () => {
    try {
      ref = ref.startAfter(offset);
      ref.onSnapshot((querySnapshot) => {
        const items = [];
        querySnapshot.forEach((doc) => {
          let orderNumber = doc?.data()?.orderNumber;
          if (doc?.data()?.shippingAddress?.order_number) {
            orderNumber = `${orderNumber} (${
              doc?.data()?.shippingAddress?.order_number
            })`;
          }
          items.push({ ...doc?.data(), docId: doc?.id, orderNumber });
        });
        if (items?.length < LIMIT_PRIMARY) {
          setHasMore(false);
        }
        setOffset(querySnapshot?.docs[querySnapshot?.docs?.length - 1]);
        setOrders([...orders, ...items]);
      });
    } catch (err) {
      catchErrorInSentry(err);
      console.log(err);
    }
  };

  const handleExport = () => {
    const url = `${
      process?.env?.REACT_APP_SERVER_URL_V2
    }/orders/export-history?startDate=${moment(date[0]).format(
      'DD/MM/YYYY'
    )}&endDate=${moment(date[1]).format('DD/MM/YYYY')}`;
    window.open(url);
  };
  const columns = [
    {
      title: 'Order ID',
      dataIndex: 'orderUniqueId',
      key: 'id',
      ...getColumnSearchPropsV2(
        'orderUniqueId',
        searchText,
        searchedColumn,
        setSearchText,
        setSearchedColumn,
        searchInput,
        false,
        setHasMore
      ),
      render: function OrderUniqueId(text, record) {
        const [newMessageFlag, setNewMessageFlag] = useState(false);
        const [active, setActive] = useState(false);
        const [spin, setSPin] = useState(true);
        getNewMessageIndicator(
          record?.docId,
          data?.userId,
          setNewMessageFlag,
          setActive,
          setSPin
        );
        return (
          <Space>
            <Link to={`/v2-orders/${text}`}>{text}</Link>
            <Link to={`chat/${text}`}>
              <Tooltip title="Internal chat">
                <Badge dot={newMessageFlag}>
                  {active ? (
                    <MessageTwoTone twoToneColor="#52c41a" />
                  ) : (
                    <MessageOutlined style={{ color: '#a0a0a0', margin: 2 }} />
                  )}
                </Badge>
              </Tooltip>
            </Link>
          </Space>
        );
      }
    },
    {
      title: 'Order Number',
      dataIndex: 'orderNumber',
      key: 'orderNumber',
      ...getColumnSearchPropsV2(
        'orderNumber',
        searchText,
        searchedColumn,
        setSearchText,
        setSearchedColumn,
        searchInput,
        false,
        setHasMore,
        setSelectedOrderType,
        selectedOrderType,
        'orders'
      )
    },
    {
      title: 'Customer Email',
      dataIndex: 'user',
      key: 'customerEmail',
      ...getColumnSearchPropsV2(
        'user',
        searchText,
        searchedColumn,
        setSearchText,
        setSearchedColumn,
        searchInput,
        false,
        setHasMore,
        setSelectedOrderType,
        selectedOrderType,
        'orders',
        setSorting,
        setSortColumn,
        setSorterInfo
      )
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (text) => {
        try {
          return <Typography.Text>{text?.replace(/_/g, ' ')}</Typography.Text>;
        } catch {
          return <></>;
        }
      }
    },
    {
      title: 'Organization',
      dataIndex: 'orgLink',
      key: 'orgLink',
      render: (text, record) => {
        return <GetOrgLink user={text} userEmail={record?.user} />;
      }
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (text) => {
        return <div>{generateDate(text)}</div>;
      }
    },
    {
      title: 'Last Updated At',
      dataIndex: 'updatedAt',
      sorter: true,
      sortOrder: sorterInfo?.columnKey === 'updatedAt' && sorterInfo?.order,
      key: 'updatedAt',
      render: (text) => {
        return <div>{generateDate(text)}</div>;
      }
    },
    {
      title: 'Currently assigned to',
      key: 'vendorName',
      render: (record) => {
        return <GetVendorNameFromOrderState order={record} />;
      }
    },
    {
      title: 'Add-ons',
      key: 'variation',
      dataIndex: 'cardVariation',
      filters: [
        { text: 'No accessories', value: 'Card only' },
        { text: '+ Key Fob', value: '+ Key Fob' },
        { text: '+ Smart Button', value: '+ Smart Button' },
        { text: '+ Key Fob + Smart Button', value: '+ Key Fob + Smart Button' }
      ],
      onFilter: async (text, record) => {
        sessionStorage.setItem('addOnsFilter', JSON.stringify([text]));
      },
      render: (record) => {
        return <GetAddOns text={record} />;
      }
    },
    {
      title: 'Actions',
      key: 'action',
      filters: [
        {
          text: 'bug',
          value: true
        },
        {
          text: 'No bug',
          value: undefined || false
        }
      ],
      render: function Delete(text, record) {
        const [loading, setLoading] = useState(false);
        const [flag, setFlag] = useState(record?.bug || false);
        const redirect = record?.isConfigured ? 'view' : 'configure';
        const disabledRevert =
          (record?.state !== 'SHIPPING' && record?.state !== 'PRINTING') ||
          record?.cardType === 'Mobilo Card' ||
          record?.cardType === 'Free Mobilo Card';
        const disabled =
          record?.isConfigured &&
          (record?.cardType === 'Mobilo Card' ||
            record?.cardType === 'Free Mobilo Card');
        const Icon = record?.isConfigured ? EyeOutlined : SettingOutlined;
        function showConfirmComplete() {
          confirm({
            title: `Are you sure you want to mark order ${record?.orderUniqueId} as completed`,
            onOk() {
              firebase
                .firestore()
                .collection('orders')
                .doc(record?.docId)
                .update({
                  status: 'COMPLETED',
                  state: 'COMPLETED',
                  isConfigured: true,
                  reviewRequired: false,
                  completedManually: true
                })
                .then(() => {
                  firebase
                    .firestore()
                    .collection('sets')
                    .where('orderUniqueId', '==', record?.orderUniqueId)
                    .get()
                    .then((sets) => {
                      sets.forEach((set) => {
                        firebase
                          .firestore()
                          .collection('sets')
                          .doc(set?.id)
                          .update({ completedManually: true });
                      });
                    })
                    .then(() => {
                      message.success('Marked as completed successfully');
                    })
                    .catch((error) => {
                      catchErrorInSentry(error);
                      console.log(error);
                    });
                })
                .catch((error) => {
                  catchErrorInSentry(error);
                  console.log(error);
                });
            },
            onCancel() {}
          });
        }
        function showConfirmDelete() {
          confirm({
            title: `Are you sure you want to delete order ${record?.orderUniqueId}`,
            onOk() {
              firebase
                .firestore()
                .collection('orders')
                .doc(record?.docId)
                .delete()
                .then(() => {
                  message.success('Order deleted successfully');
                });
            },
            onCancel() {}
          });
        }
        function showConfirmRevert() {
          confirm({
            title: `Are you sure you want to revert order ${record?.orderUniqueId} to DESIGN IN PROGRESS state?`,
            onOk() {
              setLoading(true);
              firebase
                .firestore()
                .collection('cards')
                .where('orderUniqueId', '==', record?.orderUniqueId)
                .get()
                .then((cards) => {
                  var batch = firebase.firestore().batch();
                  cards.forEach(function (doc) {
                    try {
                      var storageRef = firebase.storage().ref();
                      const ext =
                        record?.cardType === 'Mobilo Metal (promo)' ||
                        record?.cardType === 'Mobilo Metal'
                          ? 'ai'
                          : 'pdf';
                      var desertRef = storageRef.child(
                        `printToProd/${record?.orderUniqueId}/${
                          record?.orderNumber
                        }_${doc?.data()?.cardCode}.${ext}`
                      );
                      desertRef
                        .delete()
                        .then(function () {})
                        .catch(function (error) {
                          catchErrorInSentry(error);
                          console.log(error);
                        });
                    } catch {}
                    batch.delete(doc?.ref);
                  });
                  return batch.commit();
                })
                .then(() => {
                  firebase
                    .firestore()
                    .collection('sets')
                    .where('orderUniqueId', '==', record?.orderUniqueId)
                    .get()
                    .then((sets) => {
                      sets.forEach((set) => {
                        firebase
                          .firestore()
                          .collection('chats')
                          .doc(set?.data()?.setUniqueId)
                          .collection('messages')
                          .doc(set?.data()?.currentDesignId)
                          .update({ action: 'NO_ACTION_TAKEN' })
                          .then(() => {
                            firebase
                              .firestore()
                              .collection('designSet')
                              .doc(set?.data()?.designSetId || '')
                              .delete()
                              .then(() => {
                                firebase
                                  .firestore()
                                  .collection('sets')
                                  .doc(set?.id)
                                  .update({
                                    designSetId: '',
                                    status: 'DESIGN_IN_PROGRESS'
                                  })
                                  .then(() => {
                                    firebase
                                      .firestore()
                                      .collection('orders')
                                      .doc(record?.docId)
                                      .update({
                                        status: 'DESIGN_IN_PROGRESS',
                                        state: 'DESIGNING',
                                        printingVendorId: '',
                                        reviewRequired: false,
                                        shippingVendorId: '',
                                        isRejected: true
                                      })
                                      .then(() => {
                                        setLoading(false);
                                        message.success(
                                          'order updated successfully'
                                        );
                                      });
                                  });
                              });
                          });
                      });
                    });
                })
                .catch((error) => {
                  catchErrorInSentry(error);
                  console.log(error);
                });
            },
            onCancel() {}
          });
        }
        async function handleFlagClick() {
          await firebase
            .firestore()
            .collection('orders')
            .doc(record?.docId)
            .update({
              ...record,
              bug: !flag
            })
            .then(() => {
              setFlag(!flag);
            })
            .catch((err) => {
              console.log(err);
            });
        }
        return (
          <Space size={0}>
            <Tooltip title="Revert">
              <Button
                disabled={disabledRevert}
                loading={loading}
                type="text"
                icon={<UndoOutlined />}
                onClick={showConfirmRevert}
              />
            </Tooltip>
            <Tooltip title="Delete">
              <Button
                disabled={record?.isConfigured}
                type="text"
                icon={
                  <DeleteOutlined
                    style={{
                      color: 'red',
                      opacity: record?.isConfigured ? 0.4 : 1
                    }}
                  />
                }
                onClick={showConfirmDelete}
              />
            </Tooltip>
            <Tooltip title="Mark order as completed">
              <Button
                type="text"
                disabled={record?.status === 'COMPLETED'}
                icon={<CheckCircleOutlined />}
                onClick={showConfirmComplete}
              />
            </Tooltip>
            <Button
              disabled={disabled}
              href={`${process?.env?.REACT_APP_BYPASS_CONFIG_URL}/#/order/${redirect}?orderUniqueId=${record?.orderUniqueId}`}
              target="_blank"
              type="text"
              icon={<Icon />}
            />
            <Tooltip title={'Report bug'}>
              {!(record?.bug || false) ? (
                <Button
                  type="text"
                  icon={<FlagOutlined />}
                  onClick={handleFlagClick}
                />
              ) : (
                <Button
                  type="text"
                  icon={<FlagFilled style={{ color: '#d33e48' }} />}
                  onClick={handleFlagClick}
                />
              )}
            </Tooltip>
            {CARD_TYPE_TO_ASSIGN_PRINTER?.includes(record?.cardType) && (
              <ChangePrinter record={record} />
            )}
          </Space>
        );
      }
    }
  ];

  useEffect(() => {
    getOrder();
    window.addEventListener('scroll', () => {
      setScrollTopVisible(window.pageYOffset > 300);
    });
  }, [searchText, sorting, variation, action]);

  const handleTableChange = (pagination, filter, sorter) => {
    setAction(filter?.action);
    setVariation(filter?.variation);
    if (!_isEmpty(sorter)) {
      setSorterInfo(sorter);
      const { order, columnKey } = sorter;
      switch (order) {
        case 'ascend':
          switch (columnKey) {
            case 'orderNumber':
              setSorting('asc');
              setSortColumn('orderNumber');
              break;
            case 'updatedAt':
              setSorting('asc');
              setSortColumn('updatedAt');
              break;
            default:
              break;
          }
          break;
        case 'descend':
          switch (columnKey) {
            case 'orderNumber':
              setSorting('desc');
              setSortColumn('orderNumber');
              break;
            case 'updatedAt':
              setSorting('desc');
              setSortColumn('updatedAt');
              break;
            default:
              break;
          }
          break;

        default:
          setSorting('desc');
          setSortColumn('createdAt');
      }
    }
  };
  return (
    <Card
      title={
        <Typography.Title style={{ margin: 0 }} level={3}>
          Orders
        </Typography.Title>
      }
      extra={
        <Space>
          <Button
            type="primary"
            onClick={() => history.push('/v2-orders/sync')}
            icon={<SyncOutlined />}
          >
            Go to Sync
          </Button>

          <Button
            type="primary"
            onClick={() => setVisible(true)}
            icon={<ExportOutlined />}
          >
            Export History
          </Button>
        </Space>
      }
    >
      <Table
        dataSource={orders}
        columns={columns}
        pagination={false}
        bordered={true}
        onChange={handleTableChange}
        loading={loading}
      />
      <Row>
        <Col offset={10} span={12}>
          <Button
            className="margin-t-16"
            onClick={() => {
              if (hasMore) {
                getNextOrder();
              }
            }}
            disabled={!hasMore}
          >
            Load More
          </Button>
        </Col>
        <Col span={2}>
          {scrollTopVisible && (
            <Button
              shape="circle"
              onClick={() => {
                scrollToTop();
              }}
              className="margin-t-16 scroll-top-btn"
            >
              <UpCircleOutlined />
            </Button>
          )}
        </Col>
      </Row>
      <Modal
        title="Export Order History"
        visible={visible}
        footer={null}
        onCancel={() => setVisible(false)}
      >
        <Row>
          <Col flex="auto">
            <RangePicker onChange={(v) => setDate(v)} format="DD/MM/YYYY" />
          </Col>
          <Col span={4}>
            <Button
              type="primary"
              disabled={!date}
              onClick={() => handleExport()}
            >
              Export
            </Button>
          </Col>
        </Row>
      </Modal>
    </Card>
  );
}

export default Orders;
