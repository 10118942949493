import React, { useState, useEffect } from 'react';
import { firebase } from '../../../firebase';
import { Typography, Table, Tag, Card, Row, Col, Button } from 'antd';
import { UpCircleOutlined } from '@ant-design/icons';
import { withRouter } from 'react-router';
import { scrollToTop } from '../../../common/utils';

function SystemUsers() {
  const [users, setUsers] = useState([]);
  const [scrollTopVisible, setScrollTopVisible] = useState(false);
  let ref = firebase.firestore().collection('systemUser');
  const vendorRef = firebase.firestore().collection('vendor');
  const [loading, setLoading] = useState(false);

  const getUsers = async () => {
    try {
      setLoading(true);
      const res = await ref.get();
      const items = [];
      if (res) {
        res.docs.forEach((user) => {
          if (user?.data()?.vendorId) {
            vendorRef
              .doc(user?.data()?.vendorId)
              .get()
              .then((res) => {
                items.push({ ...user?.data(), vendorInfo: res?.data() });
              });
            const wait = (time) =>
              new Promise((resolve) => setTimeout(resolve, time));
            wait(1000).then(() => {
              setUsers(items);
              setLoading(false);
            });
          }
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'fullName',
      key: 'fullName'
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email'
    },
    {
      title: 'Phone',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber'
    },
    {
      title: 'Vendor Name',
      dataIndex: ['vendorInfo', 'name'],
      key: 'vendorName'
    },
    {
      title: 'UserType',
      key: 'userType',
      render: (text, record) => {
        return (
          <Typography.Text>
            {record?.isAdmin ? 'Admin' : 'Vendor'}
          </Typography.Text>
        );
      }
    },
    {
      title: 'Roles',
      dataIndex: 'role',
      key: 'role',
      render: (tags) => (
        <>
          {tags.map((tag) => {
            return <Tag key={tag}>{tag?.toUpperCase()}</Tag>;
          })}
        </>
      )
    }
  ];

  useEffect(() => {
    if (ref) {
      getUsers();
    }
    window.addEventListener('scroll', () => {
      setScrollTopVisible(window.pageYOffset > 300);
    });
  }, []);

  return (
    <Card title={<Typography.Title level={3}>System Users</Typography.Title>}>
      <Table
        dataSource={users}
        columns={columns}
        pagination={false}
        bordered={true}
        loading={loading}
      />
      <Row justify="end">
        <Col span={2}>
          {scrollTopVisible && (
            <Button
              shape="circle"
              onClick={() => {
                scrollToTop();
              }}
              className="margin-t-16 scroll-top-btn"
            >
              <UpCircleOutlined />
            </Button>
          )}
        </Col>
      </Row>
    </Card>
  );
}

export default withRouter(SystemUsers);
