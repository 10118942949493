import React from 'react';
import { RESOLUTION_FACTOR } from '../../../../common/constant';

const AntText = ({
  id,
  fontSize,
  color,
  text,
  url,
  fontFamily,
  coordinates,
  transform
}) => {
  return (
    <span
      id={id}
      style={{
        fontFamily: `${fontFamily}`,
        fontSize: `${fontSize ? fontSize / RESOLUTION_FACTOR : 14}px`,
        color: `${color ? color : '#000'}`,
        whiteSpace: 'pre',
        display: 'inline-block',
        transform: transform
      }}
    >
      {text}
    </span>
  );
};

export default AntText;
