import React from 'react';
import { Tag } from 'antd';

function GetAddOns({ text }) {
  const tags = [];
  try {
    const string = text?.toLowerCase();
    if (!string?.includes('card only')) {
      if (string?.includes('key fob'))
        tags.push({ text: '+ Key Fob', color: 'orange' });
      if (string?.includes('smart button'))
        tags.push({ text: '+ Smart Button', color: 'green' });
    }
  } catch {}
  return (
    <>
      {React.Children.toArray(
        tags?.map((tag) => {
          return <Tag color={tag?.color}>{tag?.text}</Tag>;
        })
      )}
    </>
  );
}

export default GetAddOns;
