import React, { createContext, useReducer } from 'react';
import { LIMIT_PRIMARY } from './common/constant';

const initialState = {
  totalLoadedOrders: LIMIT_PRIMARY,
  scrollOffset: 0,
  variation: '',
  sortColumn: 'updatedAt',
  sorting: 'desc',
  cardTypeFilter: '',
  action: null,
  searchTerm: '',
  searchedField: '',
  orderType: 'system',
  designStatus: null
};

const reducer = (state, action) => {
  switch (action?.type) {
    case 'SET_TOTAL_ORDERS':
      return { ...state, totalLoadedOrders: action?.data };
    case 'SET_SCROLL_OFFSET':
      return { ...state, scrollOffset: action?.data };
    case 'SET_VARIATION':
      return { ...state, variation: action?.data };
    case 'SET_SORTCOLUMN':
      return { ...state, sortColumn: action?.data };
    case 'SET_SORTING':
      return { ...state, sorting: action?.data };
    case 'SET_CARDTYPE_FILTER':
      return { ...state, cardTypeFilter: action?.data };
    case 'SET_ACTION':
      return { ...state, action: action?.data };
    case 'SET_SEARCH_TEXT':
      return { ...state, searchTerm: action?.data };
    case 'SET_SEARCHED_COLUMN':
      return { ...state, searchedField: action?.data };
    case 'SET_ORDER_TYPE':
      return { ...state, orderType: action?.data };
    case 'SET_DESIGN_STATUS':
      return { ...state, designStatus: action?.data };
    default:
      return { ...state };
  }
};

const AppContext = createContext({
  state: initialState,
  dispatch: () => {}
});

function AppContextProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const value = {
    state,
    dispatch
  };

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
}

const AppContextConsumer = AppContext.Consumer;

export { AppContext, AppContextProvider, AppContextConsumer };
