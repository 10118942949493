import {
  Card,
  Input,
  Button,
  message as antdMessage,
  Space,
  Typography,
  Tag
} from 'antd';
import React, { useState, useEffect, useContext, useRef } from 'react';
import { useHistory, useParams, withRouter } from 'react-router';
import { AuthContext } from '../Auth';
import { firebase } from '../firebase';
import { LeftOutlined } from '@ant-design/icons';
import Loader from './Loader/Loader';

function InternalChat() {
  const { data } = useContext(AuthContext);
  const { id } = useParams();
  const [chat, setChat] = useState([]);
  const [messageLoading, setMessageLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [docId, setDocId] = useState('docid');
  const [isOrderCompleted, setIsOrderCompleted] = useState(false);
  const history = useHistory();

  const chatRef = firebase.firestore().collection('orders');
  let orderRef = firebase
    ?.firestore()
    ?.collection('orders')
    ?.where('orderUniqueId', '==', id);

  useEffect(() => {
    orderRef.get().then((querySnapshot) => {
      querySnapshot.forEach((element) => {
        var data = element?.data();
        if (
          (data?.state === 'PRINTING' ||
            data?.state === 'SHIPPING' ||
            data?.state === 'COMPLETED') &&
          data?.status !== 'PRINTING_IN_PROCESS'
        ) {
          setIsOrderCompleted(true);
        }
      });
    });
  }, []);

  const messagesEndRef = useRef(null);
  const scrollToBottom = () => {
    if (messagesEndRef) {
      if (messagesEndRef.current) {
        messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    }
  };

  function getChat(docId) {
    chatRef
      .doc(docId)
      .collection('notes')
      .orderBy('createdAt', 'asc')
      .onSnapshot((querySnapshot) => {
        const items = [];
        querySnapshot.forEach((chat) => {
          items.push(chat?.data());
        });
        setChat(items);
        scrollToBottom();
      });
  }

  function handleSend(value) {
    setMessageLoading(true);
    chatRef
      .doc(docId)
      .collection('notes')
      .add({
        message: value,
        orderUniqueId: id,
        senderId: data?.userId,
        senderName: data?.fullName,
        createdAt: new Date(),
        senderRole: data?.isAdmin ? ['ADMIN'] : data?.role
      })
      .then((ref) => {
        chatRef
          .doc(docId)
          .collection('notes')
          .doc(ref?.id)
          .set(
            {
              docId: ref?.id
            },
            { merge: true }
          )
          .then(() => {
            chatRef
              .doc(docId)
              .update({ lastMessage: new Date() })
              .then(() => {
                updateLastSeen(docId);
                setMessageLoading(false);
                antdMessage.success('Message sent successfully');
              });
          });
      });
  }

  function updateLastSeen(docId) {
    chatRef
      .doc(docId)
      .collection('lastSeen')
      .doc(data?.userId)
      .set({ time: new Date() });
  }
  useEffect(() => {
    setLoading(true);
    firebase
      .firestore()
      .collection('orders')
      .where('orderUniqueId', '==', id)
      .get()
      .then((orders) => {
        updateLastSeen(orders?.docs[0]?.id);
        getChat(orders?.docs[0]?.id);
        setDocId(orders?.docs[0]?.id);
      });
    setLoading(false);
  }, []);
  if (loading) {
    return <Loader />;
  }
  return (
    <Card
      title={
        <Space>
          <Button
            onClick={() => {
              history.goBack();
            }}
            type="text"
            icon={<LeftOutlined />}
          />
          <Typography.Title style={{ margin: 0 }} level={3}>
            {id} | Internal Chat
          </Typography.Title>
        </Space>
      }
      actions={[
        <div style={{ padding: '0px 2rem' }}>
          <Input.Search
            placeholder="Enter Message"
            disabled={isOrderCompleted}
            className={isOrderCompleted && `internal-chat-text`}
            enterButton={
              <Button
                disabled={isOrderCompleted}
                type="primary"
                loading={messageLoading}
              >
                Send
              </Button>
            }
            size="large"
            value={
              isOrderCompleted
                ? 'Please contact a production team member to make modifications to this order'
                : message
            }
            onChange={(e) => setMessage(e?.target?.value)}
            onSearch={(value) => {
              handleSend(value);
              setMessage('');
            }}
          />
        </div>
      ]}
    >
      <div style={{ height: '75vh', overflowY: 'auto' }}>
        <Space direction="vertical" size="large">
          {chat.length
            ? chat.map((value, index) => {
                const date = new Date(value?.createdAt?.seconds * 1000);
                const day = date.getDate();
                const month = date.getMonth() + 1;
                const year = date.getFullYear();
                const hours = date.getHours();
                const minutes = date.getMinutes();
                return (
                  <Space direction="vertical" key={index}>
                    <Space size="small">
                      <Typography.Title level={5} style={{ margin: 0 }}>
                        {value?.senderName}
                      </Typography.Title>
                      <Typography.Text>{`${day}/${month}/${year} ${hours}:${minutes}`}</Typography.Text>
                      {value?.senderRole
                        ? value.senderRole.map((role) => {
                            return <Tag color="blue">{role}</Tag>;
                          })
                        : null}
                    </Space>
                    <Typography.Text>{value?.message}</Typography.Text>
                  </Space>
                );
              })
            : null}
        </Space>
        <div ref={messagesEndRef} />{' '}
      </div>
    </Card>
  );
}

export default withRouter(InternalChat);
