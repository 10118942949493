import React, { useEffect, useState } from 'react';
import { Button, Col, Popconfirm, Row, Spin, Table, Tag } from 'antd';
import api from '../../../common/api';

const CommonTabPan = ({ licenseType, orgId, columns, isRelease = false }) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [licensesData, setLicensesData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [deleteBtnLoader, setDeleteBtnLoader] = useState(false);

  const onSelectChange = (selectedRowKeys) => {
    setSelectedRowKeys(selectedRowKeys);
  };

  // Remove & Release license handler (release true for release and false for remove)
  const handleRemoveReleaseLicense = async () => {
    setDeleteBtnLoader(true);
    setLoading(true);
    const filteredData = licensesData.filter(
      (license) => !selectedRowKeys?.includes(license?.licenseId)
    );
    setLoading(false);
    setLicensesData(filteredData);
    try {
      await api.post(
        `${process.env.REACT_APP_PDF_DOWNLOAD_URL}/mat/license/remove`,
        {
          licenseIds: selectedRowKeys,
          release: isRelease,
          orgId
        },
        {
          headers: {
            secretkey: process?.env?.REACT_APP_PDF_FIX_SECRET
          }
        }
      );
      setDeleteBtnLoader(false);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
      setDeleteBtnLoader(false);
    }
  };

  useEffect(() => {
    // fetch licenses on first render
    const fetchLicensesByType = async () => {
      try {
        setLoading(true);
        const licenses = await api.post(
          `${process.env.REACT_APP_PDF_DOWNLOAD_URL}/mat/license/get`,
          {
            orgId: orgId,
            count: false,
            type: licenseType
          },
          {
            headers: {
              secretkey: process.env.REACT_APP_PDF_FIX_SECRET
            }
          }
        );
        const licensesDetails = [];
        for (const license of licenses?.data?.data[licenseType]) {
          licensesDetails.push({
            ...license,
            key: license?.licenseId
          });
        }
        setLicensesData(licensesDetails);
        setLoading(false);
      } catch (err) {
        console.log(err);
        setLoading(false);
      } finally {
        setLoading(false);
      }
    };
    fetchLicensesByType();
  }, []);

  return (
    <>
      {licensesData?.length > 0 && (
        <Popconfirm
          placement="bottomRight"
          disabled={!selectedRowKeys?.length}
          title={`Are you sure you want to ${
            isRelease ? 'Release' : 'Remove'
          } this license`}
          onConfirm={handleRemoveReleaseLicense}
          okText="Yes"
          cancelText="No"
        >
          <Row justify="end">
            <Col>
              <Button
                type="danger"
                loading={deleteBtnLoader}
                style={{ marginBottom: '1rem' }}
                disabled={!selectedRowKeys?.length}
              >
                {isRelease ? 'Release Licenses' : 'Remove Licenses'}
              </Button>
            </Col>
          </Row>
        </Popconfirm>
      )}
      <Table
        rowSelection={{ selectedRowKeys, onChange: onSelectChange }}
        columns={columns}
        dataSource={licensesData}
        loading={loading}
      />
    </>
  );
};

export default CommonTabPan;
