import { Space, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { catchErrorInSentry } from '../common/utils';
import Loader from '../components/Loader/Loader';
import { firebase } from '../firebase';

function GetOrgLink({ user, userEmail }) {
  const [orgName, setOrgName] = useState();
  const [loading, setLoading] = useState(false);
  const [orgId, setOrgId] = useState();
  let ref = firebase
    .firestore()
    .collection('profiles')
    .where('user', '==', userEmail)
    .limit(1);
  const getOrgDetails = async () => {
    setLoading(true);
    try {
      const data = await ref.get();
      const orgId = data?.docs[0]?.data()?.orgId;
      await getOrgName(orgId);
      setOrgId(orgId);
    } catch (error) {
      catchErrorInSentry(error);
      console.log(error);
    }
  };

  const getOrgName = async (orgId) => {
    if (!orgId) {
      setLoading(false);
      return null;
    }
    try {
      let orgref = firebase.firestore().collection('organizations').doc(orgId);
      const orgData = await orgref.get();
      setOrgName(orgData?.data()?.name);
    } catch (error) {
      catchErrorInSentry(error);
      console.log(error);
    }
    setLoading(false);
  };
  useEffect(() => {
    if (!user) {
      getOrgDetails();
    } else {
      getOrgName(user);
    }
    return () => {
      setOrgName();
    };
  }, [userEmail]);
  if (loading) return <Spin />;
  if (user) {
    return (
      <Link to={`/v2-organizations/${user}`} target={'_blank'}>
        {orgName || user}
      </Link>
    );
  }
  if (orgId && orgName) {
    return (
      <Link to={`/v2-organizations/${orgId}`}>
        <div> {orgName} </div>
      </Link>
    );
  }

  return <p>-</p>;
}

export default GetOrgLink;
