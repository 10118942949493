import { Alert, Space, Typography } from 'antd';
import React from 'react';

function CardUsersDetails({ user, showValidateNote }) {
  return (
    <Alert
      style={{ marginBottom: '1rem' }}
      message={
        <Space direction="vertical">
          <Space>
            <Typography.Text strong>Name :</Typography.Text>
            <Typography.Text>
              {user?.firstName} {user?.lastName}
            </Typography.Text>
          </Space>
          <Space>
            <Typography.Text strong>Email :</Typography.Text>
            <Typography.Text>{user?.email}</Typography.Text>
          </Space>
          <Space>
            <Typography.Text strong>Phone Number (mobile) :</Typography.Text>
            <Typography.Text>{user?.mobilePhone}</Typography.Text>
          </Space>
          <Space>
            <Typography.Text strong>Phone Number (work) :</Typography.Text>
            <Typography.Text>{user?.workPhone}</Typography.Text>
          </Space>
          <Space>
            <Typography.Text strong>Company :</Typography.Text>
            <Typography.Text>{user?.company}</Typography.Text>
          </Space>
          <Space>
            <Typography.Text strong>Title :</Typography.Text>
            <Typography.Text>{user?.title}</Typography.Text>
          </Space>
          {showValidateNote && (
            <Typography.Text type="danger">
              For submitting design first name and email is required!
            </Typography.Text>
          )}
        </Space>
      }
      type="info"
    ></Alert>
  );
}

export default CardUsersDetails;
