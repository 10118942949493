import React, { useEffect, useRef, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';

import {
  Button,
  Card,
  Col,
  message,
  Row,
  Space,
  Table,
  Typography
} from 'antd';
import {
  LeftOutlined,
  DeleteOutlined,
  UpCircleOutlined
} from '@ant-design/icons';
import confirm from 'antd/lib/modal/confirm';

import AddDepartmentMember from './AddDepartmentMembers';
import Settings from './DepartmentSettings';
import { firebase } from '../../../firebase';
import { LIMIT_PRIMARY } from '../../../common/constant';
import { getColumnSearchPropsV2 } from '../../../utils/searchV2';
import api from '../../../common/api';
import { scrollToTop } from '../../../common/utils';

function DepartmentMember() {
  const { id, deptId } = useParams();
  const history = useHistory();
  const [organization, setOrganization] = useState({});
  const [members, setMembers] = useState([]);
  const [scrollTopVisible, setScrollTopVisible] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [offset, setOffset] = useState();
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);
  const [profileLoading, setProfileLoading] = useState(false);

  const ref = firebase
    .firestore()
    .collection('organizations')
    .doc(id)
    .collection('departments')
    .doc(deptId);
  let profileRef = firebase
    .firestore()
    .collection('profiles')
    .where('isDeleted', '==', false)
    .where('isDisabled', '==', false);
  if (searchText && searchedColumn) {
    if (searchedColumn === 'user') {
      profileRef = profileRef
        .orderBy(searchedColumn)
        .startAt(searchText?.toUpperCase())
        .endAt(searchText?.toLowerCase() + '\uf8ff');
    }
  }
  profileRef = profileRef.limit(LIMIT_PRIMARY);

  async function getOrganization() {
    setLoading(true);
    ref.onSnapshot(async (querySnapshot) => {
      setOrganization(querySnapshot?.data());
      setLoading(false);
    });
  }
  useEffect(() => {
    getOrganization();
  }, []);

  const getProfiles = () => {
    setProfileLoading(true);
    const array = [];
    profileRef
      .where('orgId', '==', id)
      .where('deptId', '==', deptId)
      .get()
      .then((profiles) => {
        profiles.forEach((profile) =>
          array.push({ ...profile?.data(), docId: profile?.id })
        );
        setOffset(profiles?.docs[profiles?.docs?.length - 1]);
        setMembers(array);
        setProfileLoading(false);
      });
  };
  const getNextProfiles = () => {
    setProfileLoading(true);
    const array = [];
    profileRef
      .where('orgId', '==', id)
      .where('deptId', '==', deptId)
      .startAfter(offset)
      .get()
      .then((profiles) => {
        profiles.forEach((profile) =>
          array.push({ ...profile?.data(), docId: profile?.id })
        );
        if (array?.length < LIMIT_PRIMARY) {
          setHasMore(false);
        }
        setOffset(profiles?.docs[profiles?.docs?.length - 1]);
        setMembers([...members, ...array]);
        setProfileLoading(false);
      });
  };
  useEffect(() => {
    getProfiles();
  }, [organization?.members, searchText]);

  const columns = [
    {
      title: 'Name',
      dataIndex: 'Full Name',
      key: 'fullName'
    },
    {
      title: 'Email ID',
      dataIndex: 'user',
      key: 'user',
      ...getColumnSearchPropsV2(
        'user',
        searchText,
        searchedColumn,
        setSearchText,
        setSearchedColumn,
        searchInput,
        false,
        setHasMore
      )
    },
    {
      title: 'Phone Number',
      dataIndex: 'Phone Number (work)',
      key: 'Phone Number (work)'
    },
    {
      title: 'Actions',
      key: 'action',
      render: function Delete(text, record) {
        const [loading, setLoading] = useState(false);

        function showConfirm() {
          confirm({
            title: `Are you sure you want to remove member '${record['Full Name']}'`,
            onOk() {
              setLoading(true);
              api
                .post(
                  `${process?.env?.REACT_APP_SERVER_URL_V2}/dept/remove-member/${id}/${deptId}`,
                  { memberIds: [`${record?.userId}`] }
                )
                .then(() => {
                  setLoading(false);
                  getProfiles();
                });
            },
            onCancel() {}
          });
        }
        return (
          <Button
            loading={loading}
            type="text"
            icon={<DeleteOutlined style={{ color: 'red' }} />}
            onClick={showConfirm}
          />
        );
      }
    }
  ];

  useEffect(() => {
    window.addEventListener('scroll', () => {
      setScrollTopVisible(window.pageYOffset > 300);
    });
  }, []);

  return (
    <Card
      title={
        <Space>
          <Button
            type="text"
            icon={<LeftOutlined />}
            onClick={() => {
              history.goBack();
            }}
          />
          <Typography.Title style={{ margin: 0 }} level={3}>
            {organization?.name}
          </Typography.Title>
        </Space>
      }
      extra={
        <Space>
          <AddDepartmentMember orgId={id} deptId={deptId} />
          <Settings orgId={id} deptId={deptId} />
        </Space>
      }
    >
      <Table
        dataSource={members}
        columns={columns}
        loading={profileLoading}
        pagination={false}
        bordered={true}
      />
      <Row>
        <Col offset={10} span={12}>
          <Button
            className="margin-t-16"
            onClick={() => {
              if (hasMore) {
                getNextProfiles();
              }
            }}
            disabled={!hasMore}
          >
            Load More
          </Button>
        </Col>
        <Col span={2}>
          {scrollTopVisible && (
            <Button
              shape="circle"
              onClick={() => {
                scrollToTop();
              }}
              className="margin-t-16 scroll-top-btn"
            >
              <UpCircleOutlined />
            </Button>
          )}
        </Col>
      </Row>
    </Card>
  );
}

export default DepartmentMember;
