import { Button, Form, message, Modal, Tabs, Upload } from 'antd';
import {
  UploadOutlined,
  DownloadOutlined,
  CheckOutlined
} from '@ant-design/icons';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import Papa from 'papaparse';
import { CSVLink } from 'react-csv';
import { isEmpty } from 'lodash';
import { firebase } from '../../../../firebase';
import api from '../../../../common/api';
import { catchErrorInSentry } from '../../../../common/utils';

function ShowTemplateModal({
  orgId,
  set,
  template,
  setTemplate,
  csvData,
  setRedirect
}) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [activeTab, setActiveTab] = useState('1');
  const [customFieldData, setCustomFieldData] = useState();
  const [isFileParsed, setIsFileParsed] = useState(false);
  const [isCustomAvailableInFront, setIsCustomAvailableInFront] =
    useState(false);
  const [isCustomAvailableInBack, setIsCustomAvailableInBack] = useState(false);
  const [proceeding, setProceeding] = useState(false);
  let headers = [
    { label: 'email', key: 'email' },
    { label: 'customField', key: 'customField' }
  ];

  const showModal = () => {
    setIsModalVisible(true);
  };

  const cardDesignPreview = async (orderUniqueId, templateId) => {
    const cardGenerationResponse = await api({
      method: 'get',
      url: `${process?.env?.REACT_APP_PDF_DOWNLOAD_URL}/mat/card-design/create-preview?orderUniqueId=${orderUniqueId}&designTemplateId=${templateId}`,
      headers: {
        'Content-Type': 'application/json',
        secretkey: process?.env?.REACT_APP_PDF_FIX_SECRET
      }
    });
    setIsModalVisible(false);
    setProceeding(false);
    message.success(cardGenerationResponse?.data?.message);
    setRedirect(true);
  };

  const handleOk = async () => {
    try {
      if (
        activeTab === '2' &&
        (isCustomAvailableInFront || isCustomAvailableInBack)
      ) {
        setProceeding(true);
        const response = await axios({
          method: 'post',
          url: `${process.env.REACT_APP_SERVER_URL}/v2/design-template/add-custom-field?orderUniqueId=${set?.orderUniqueId}`,
          data: customFieldData
        });
        message.success(response?.data?.message);
        if (response?.data) {
          setIsFileParsed(false);
          cardDesignPreview(set?.orderUniqueId, template?.templateId);
          setCustomFieldData();
        }
      } else if (isCustomAvailableInFront || isCustomAvailableInBack) {
        setActiveTab('2');
      } else {
        cardDesignPreview(set?.orderUniqueId, template?.templateId);
      }
    } catch (error) {
      catchErrorInSentry(error?.response?.data?.message);
      setIsFileParsed(false);
      setProceeding(false);
      setIsModalVisible(false);
      message.error(error?.response?.data?.message || 'Something went wrong');
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const uploadProps = {
    name: 'file',
    showUploadList: false,
    accept: '.csv',
    multiple: false,
    onChange(info) {
      Papa.parse(info?.file?.originFileObj, {
        header: true,
        skipEmptyLines: true,
        complete: function (results) {
          setIsFileParsed(true);
          setCustomFieldData(results?.data);
        }
      });
    }
  };
  useEffect(() => {
    if (template) {
      setIsCustomAvailableInFront(template?.front?.customField?.dynamic);
      setIsCustomAvailableInBack(template?.back?.customField?.dynamic);
    }
  }, [template]);
  if (orgId) {
    return (
      <div>
        <Button type="primary" onClick={showModal} disabled={isEmpty(template)}>
          {!isEmpty(template) ? 'Use Template' : 'No Template Found'}
        </Button>
        <Modal
          title="Template"
          visible={isModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
          okText="Proceed"
          okButtonProps={{ loading: proceeding }}
        >
          <Tabs
            activeKey={activeTab}
            onTabClick={(key) => {
              setActiveTab(key);
            }}
          >
            <Tabs.TabPane tab="Preview" key="1">
              {template?.frontImage && (
                <div className="flex mb-16">
                  <label>Front: &nbsp;</label>
                  <img
                    src={template?.frontImage}
                    alt="Front card"
                    className="template-image"
                  ></img>
                </div>
              )}
              {template?.backImage && (
                <div className="flex">
                  <label>Back: &nbsp;</label>
                  <img
                    src={template?.backImage}
                    alt="Back card"
                    className="template-image"
                  ></img>
                </div>
              )}
            </Tabs.TabPane>
            {(isCustomAvailableInFront || isCustomAvailableInBack) && (
              <Tabs.TabPane tab="Custom fields" key="2">
                <Form>
                  <Form.Item label="Download file for preview">
                    {csvData ? (
                      <CSVLink
                        filename={'custom_field.csv'}
                        headers={headers}
                        data={csvData}
                      >
                        <Button icon={<DownloadOutlined />} type="primary">
                          Download
                        </Button>
                      </CSVLink>
                    ) : (
                      <></>
                    )}
                  </Form.Item>
                  <Form.Item label="Upload file for custom fields">
                    <Upload {...uploadProps}>
                      <Button
                        icon={
                          isFileParsed ? <CheckOutlined /> : <UploadOutlined />
                        }
                        type="primary"
                      >
                        Upload
                      </Button>
                    </Upload>
                  </Form.Item>
                </Form>
              </Tabs.TabPane>
            )}
          </Tabs>
        </Modal>
      </div>
    );
  }

  return null;
}

export default ShowTemplateModal;
