import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import api from '../../../../common/api';
import _values from 'lodash/isEmpty';
import { Button, Card, message, Space, Table, Tooltip, Typography } from 'antd';
import {
  CheckOutlined,
  LeftOutlined,
  VerticalAlignBottomOutlined
} from '@ant-design/icons';
import { PrintIcon } from '../../../../Assets/icons';
import confirm from 'antd/lib/modal/confirm';
import { STATUS_DEFINATION } from '../../../../common/constant';
import OrderList from './OrderList';

function Batch() {
  const { id } = useParams();
  const [batches, setBatches] = useState();
  const [batchLoading, setBatchLoading] = useState(true);
  const [status, setStatus] = useState('');
  const [orders, setOrders] = useState([]);
  const history = useHistory();
  const getBatchInfo = async () => {
    try {
      setBatchLoading(true);
      const batchResponse = await api.get(
        `${process.env.REACT_APP_PDF_DOWNLOAD_URL}/mat/printer/get-batch?batchId=${id}`,
        { headers: { secretkey: process.env.REACT_APP_PDF_FIX_SECRET } }
      );
      if (batchResponse) {
        setBatchLoading(false);
        setStatus(batchResponse?.data?.data?.status);
        setBatches(batchResponse?.data?.data?.batches);
        setOrders(batchResponse?.data?.data?.orderUniqueIds);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (id) {
      getBatchInfo();
    }
  }, [id]);

  const updateBatchStatus = async (index) => {
    try {
      await api.put(
        `${
          process.env.REACT_APP_PDF_DOWNLOAD_URL
        }/mat/printer/update-status-and-generate-csv?batchId=${id}&batchNumber=${
          index + 1
        }`,
        {},
        { headers: { secretkey: process.env.REACT_APP_PDF_FIX_SECRET } }
      );
    } catch (error) {
      console.log(error);
    }
  };

  const columns = [
    {
      title: 'Batch',
      dataIndex: 'id',
      render: (_, record, index) => <span>{index}</span>
    },
    {
      title: 'Status',
      dataIndex: 'status',
      render: (text) => {
        return (
          <Tooltip title={STATUS_DEFINATION[`${text}`]}>
            <span>{text}</span>
          </Tooltip>
        );
      }
    },
    {
      title: 'Action',
      width: 20,
      render: function Action(_, record, index) {
        function showConfirmComplete() {
          confirm({
            title: `Are you sure you want to mark as completed`,
            onOk() {
              updateBatchStatus(index);
            },
            onCancel() {}
          });
        }
        return record?.status === 'COMPLETED' ? (
          <Space size="middle">
            <Button
              type="text"
              onClick={() => {
                window.open(record?.csvUrl);
              }}
            >
              <VerticalAlignBottomOutlined />
            </Button>
          </Space>
        ) : (
          <Space size="middle">
            <OrderList orders={orders} />
            <Button
              type="text"
              onClick={() => {
                window.open(record?.pdfUrl);
              }}
            >
              <PrintIcon />
            </Button>
            <Tooltip title="Mark as completed">
              <Button
                type="text"
                icon={<CheckOutlined />}
                onClick={showConfirmComplete}
              />
            </Tooltip>
          </Space>
        );
      }
    }
  ];
  const changeOrderStatus = async () => {
    try {
      await api.get(
        `${process.env.REACT_APP_PDF_DOWNLOAD_URL}/mat/printer/update-status-and-assign-work?batchId=${id}`,
        { headers: { secretkey: process.env.REACT_APP_PDF_FIX_SECRET } }
      );
      message.success('Status changed successfully');
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <Card
      title={
        <Typography.Text>
          <LeftOutlined
            onClick={() => {
              history.goBack();
            }}
            className="mr-16"
          />
          Batch - {id}
        </Typography.Text>
      }
      extra={
        status === 'COMPLETED' && (
          <Button
            type="primary"
            onClick={() => {
              changeOrderStatus();
            }}
          >
            Change status
          </Button>
        )
      }
    >
      <Table
        columns={columns}
        dataSource={batches}
        bordered
        loading={batchLoading}
        pagination={false}
      />
    </Card>
  );
}

export default Batch;
