import React, { useState } from 'react';
import { Typography, Card, Input, Button, message } from 'antd';
import { withRouter } from 'react-router-dom';
import Loader from '../../Loader/Loader';
import { GENERATE_CARDS_LIMIT } from '../../../common/constant';
import api from '../../../common/api';

function GenerateCards() {
  const [loading, setLoading] = useState(false);
  const [searchInputText, setSearchInputText] = useState('');

  // TODO : For future ref
  // Note: Consider 2 second time for each card creation process
  const estimateTimeCalculator = (num) => {
    const minutes = Math.floor(num / 60);
    const seconds = Math.floor(num % 60);
    if (searchInputText < 120) {
      return `${seconds} seconds`;
    } else {
      return `${minutes} minutes : ${seconds} seconds`;
    }
  };

  function handleSearch() {
    const totalCards = parseInt(searchInputText, 10);
    if (totalCards === 0) {
      return message.error('Please enter valid number');
    } else if (totalCards > GENERATE_CARDS_LIMIT) {
      return message.error(
        `Please enter a number less than ${GENERATE_CARDS_LIMIT}`
      );
    }
    setLoading(true);

    const body = {
      number: searchInputText
    };

    api
      .post(`${process.env.REACT_APP_PDF_DOWNLOAD_URL}/generateCardCodes`, body)
      .then(() => {
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }
  if (loading) {
    return <Loader />;
  }
  return (
    <Card
      title={
        <Typography.Title style={{ margin: 0 }} level={3}>
          Generate Cards
        </Typography.Title>
      }
    >
      <Input.Search
        placeholder="Enter Number"
        type={'number'}
        enterButton={
          <Button type="primary" loading={loading}>
            Generate
          </Button>
        }
        value={searchInputText}
        onChange={(e) => {
          setSearchInputText(e?.target?.value);
        }}
        onSearch={() => {
          handleSearch();
        }}
      />
    </Card>
  );
}

export default withRouter(GenerateCards);
