import { InfoCircleOutlined } from '@ant-design/icons';
import { Space, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import { firebase } from '../firebase';

function GetUserNameById({ userId }) {
  const [userEmail, setUserEmail] = useState();
  const [isDisabled, setIsDisabled] = useState(false);
  const getuserEmail = async () => {
    if (userId) {
      await firebase
        .firestore()
        .collection('profiles')
        .doc(userId)
        .get()
        .then((res) => {
          if (res?.data()?.isDisabled) {
            setIsDisabled(true);
          }
          setUserEmail(res?.data()?.user);
        });
    } else {
      setUserEmail();
    }
  };
  useEffect(() => {
    getuserEmail();
    return () => {
      setIsDisabled(false);
    };
  }, [userId]);
  return (
    <Space>
      {userEmail ?? '-'}
      {isDisabled && (
        <Tooltip title="Card disabled">
          <InfoCircleOutlined />
        </Tooltip>
      )}
    </Space>
  );
}

export default GetUserNameById;
