import React, { useState, useEffect } from 'react';
import { useParams, useHistory, withRouter } from 'react-router-dom';
import {
  Typography,
  Button,
  Card,
  Space,
  Tag,
  Switch,
  Table,
  Descriptions
} from 'antd';
import { firebase } from '../../../firebase';
import { LeftOutlined } from '@ant-design/icons';
import EditVendor from './EditVendor';
import AddUser from './AddUser';
import Loader from '../../Loader/Loader';

const userColumns = [
  {
    title: 'ID',
    dataIndex: 'userId',
    key: 'userId'
  },
  {
    title: 'Name',
    dataIndex: 'fullName',
    key: 'fullName'
  },
  {
    title: 'Email ID',
    dataIndex: 'email',
    key: 'email'
  },
  {
    title: 'Phone',
    dataIndex: 'phoneNumber',
    key: 'phoneNumber'
  },
  {
    title: 'Roles',
    dataIndex: 'role',
    key: 'role',
    render: (tags) => (
      <>
        {tags.map((tag) => {
          return <Tag key={tag}>{tag?.toUpperCase()}</Tag>;
        })}
      </>
    )
  }
];

function Vendor() {
  let { id } = useParams();
  const history = useHistory();
  const [vendor, setVendor] = useState({});
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const ref = firebase.firestore().collection('vendor').doc(id);
  const userRef = firebase
    .firestore()
    .collection('systemUser')
    .where('vendorId', '==', id);

  function getVendor() {
    setLoading(true);
    ref.onSnapshot((querySnapshot) => {
      setVendor(querySnapshot?.data());
    });
    setLoading(false);
  }

  function getUsers() {
    setLoading(true);
    userRef.onSnapshot((querySnapshot) => {
      const items = [];
      querySnapshot.forEach((doc) => {
        items.push(doc?.data());
      });
      setUsers(items);
      setLoading(false);
    });
  }

  useEffect(() => {
    getVendor();
    getUsers();
  }, []);
  if (loading) {
    return <Loader />;
  }
  return (
    <Card
      title={
        <div className="vendor-header">
          <Button
            type="text"
            icon={<LeftOutlined />}
            onClick={() => {
              history.goBack();
            }}
          />
          <Typography.Title style={{ margin: 0 }} level={3}>
            {vendor?.name}
          </Typography.Title>
        </div>
      }
      extra={
        <Space>
          <Switch
            size="small"
            checked={vendor?.isActive}
            onChange={(e) =>
              firebase.firestore().collection('vendor').doc(vendor?.id).update({
                isActive: e
              })
            }
          />
          <EditVendor record={vendor} />
        </Space>
      }
    >
      <Descriptions layout="horizontal" column={1}>
        <Descriptions.Item label="E-mail">{vendor?.email}</Descriptions.Item>
        <Descriptions.Item label="Phone no.">
          {vendor?.phoneNumber}
        </Descriptions.Item>
        <Descriptions.Item label="Service">
          {vendor?.vendorType
            ? vendor.vendorType.map((tag) => {
                return <Tag key={tag}>{tag?.toUpperCase()}</Tag>;
              })
            : null}
        </Descriptions.Item>
      </Descriptions>
      <Card
        title="Users"
        size="small"
        extra={<AddUser vendorId={vendor?.id} role={vendor?.vendorType} />}
      >
        <Table dataSource={users} columns={userColumns} size="small" />
      </Card>
    </Card>
  );
}

export default withRouter(Vendor);
