import React, { useState, useEffect } from 'react';

import {
  Button,
  Input,
  Switch,
  Modal,
  Form,
  Radio,
  message,
  Spin,
  Select,
  Space,
  Typography,
  Upload,
  Row,
  Col
} from 'antd';
import {
  EditOutlined,
  DeleteOutlined,
  UploadOutlined
} from '@ant-design/icons';
import { useForm } from 'antd/lib/form/Form';
import api from '../../../common/api';
import { firebase } from '../../../firebase';
import './news.css';
import { formValidatorRules } from '../../../common/utils';
import { DEFAULT_NEWS_CATEGORY } from '../../../common/constant';

const { Option } = Select;
const { required } = formValidatorRules;

function NewsForm({ edit = false, initialValues, id, getNews, getNewsById }) {
  const [form] = useForm();
  const [mediaType, setMediaType] = useState('IMAGE');
  const [existingMedia, setExistingMedia] = useState();
  const [existingMediaType, setExistingMediaType] = useState();
  const [isInEditState, setIsInEditState] = useState(false);
  const [newCategory, setNewcategory] = useState();
  const [categories, setCategories] = useState([]);
  const [creatingCategory, setCreatingCategory] = useState(false);
  const [email, setEmail] = useState();
  const [image, setImage] = useState();
  const [loading, setLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [currentcategoryId, setCurrentcategoryId] = useState();

  const handleOk = () => {
    form.submit();
  };
  const handleCancel = () => {
    form.resetFields();
    setIsInEditState(false);
    setNewcategory();
    setModalVisible(false);
  };
  const getAllcategories = async () => {
    const news = await api.get(
      `${process.env.REACT_APP_SERVER_URL_V2}/news/category`
    );
    setCategories(news?.data?.data?.newsCategory);
  };
  const handleSubmit = (event) => {
    const { title, description, isActive, type = 'NO_MEDIA', category } = event;
    setLoading(true);
    let formData = new FormData();
    formData.append('fileName', image?.originFileObj || existingMedia);
    formData.append('title', title);
    formData.append('description', description);
    formData.append('isActive', isActive ?? false);
    formData.append('createdAt', new Date());
    formData.append('type', type);
    formData.append('creator', email);
    formData.append('category', category);
    api({
      method: `${edit ? 'put' : 'post'}`,
      url: `${process.env.REACT_APP_SERVER_URL_V2}/news/${edit ? id : ''}`,
      data: formData
    })
      .then(() => {
        setLoading(false);
        getNews();
        setModalVisible(false);
        setMediaType();
        setImage();
        form.resetFields();
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };
  const options = [
    { label: 'NO MEDIA', value: 'NO_MEDIA' },
    { label: 'IMAGE', value: 'IMAGE' },
    { label: 'VIDEO', value: 'VIDEO' }
  ];
  useEffect(() => {
    setEmail(firebase?.auth()?.currentUser?.email);
    if (modalVisible) {
      getAllcategories();
    }
  }, [modalVisible]);
  useEffect(() => {
    form.resetFields();
    setMediaType(initialValues?.type);
    if (initialValues?.imageLocation || initialValues?.videoLocation) {
      setExistingMedia(
        initialValues?.imageLocation || initialValues?.videoLocation
      );
      setExistingMediaType(initialValues?.type);
    }
  }, [initialValues]);
  if (loading) return <Spin />;
  const onNameChange = (e) => {
    setNewcategory(e?.target?.value);
  };
  const addCategory = () => {
    setCreatingCategory(true);

    api
      .post(`${process.env.REACT_APP_SERVER_URL_V2}/news/category`, {
        category: newCategory
      })
      .then(() => {
        setCreatingCategory(false);
        getAllcategories();
        setNewcategory();
      })
      .catch((err) => {
        setCreatingCategory(false);
      });
  };
  const handleEditCategory = (id, name) => {
    setIsInEditState(true);
    setNewcategory(name);
    setCurrentcategoryId(id);
  };
  const EditCategory = (id) => {
    api
      .put(
        `${process.env.REACT_APP_SERVER_URL_V2}/news/category/${currentcategoryId}`,
        {
          category: newCategory
        }
      )
      .then(() => {
        getAllcategories();
        setNewcategory();
        setIsInEditState(false);
      })
      .catch(() => {});
  };

  const props = {
    name: 'file',
    accept:
      mediaType === 'IMAGE'
        ? '.JPG, .jpg, .PNG, .png, .JPEG, .jpeg'
        : '.mov, .wav, .mp4, .MOV, .WAV, .MP4',
    headers: {
      authorization: 'authorization-text'
    },
    onChange(info) {
      if (info?.file?.status !== 'uploading') {
        const isLt8MB = info?.file?.size / 1024 / 1024 <= 8;
        if (!isLt8MB) {
          message.warning(`File must be smaller than 8MB!`);
        }
        setImage(info?.file);
      }
    }
  };
  const removeImgHandler = (e) => {
    e.stopPropagation();
    setExistingMedia();
  };
  return (
    <div>
      {edit ? (
        <Button
          type="text"
          onClick={() => {
            getNewsById(id);
            setModalVisible(true);
          }}
        >
          <EditOutlined />
        </Button>
      ) : (
        <Button type="primary" onClick={() => setModalVisible(true)}>
          Add News
        </Button>
      )}
      <Modal
        visible={modalVisible}
        title={edit ? 'Edit News' : 'Add news'}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Form
          onFinish={handleSubmit}
          form={form}
          initialValues={initialValues && { ...initialValues, isActive: true }}
        >
          <Form.Item label="Email" name="email">
            <Input defaultValue={email} disabled />
          </Form.Item>
          <Form.Item label="Title" name="title" rules={[required]}>
            <Input />
          </Form.Item>
          <Form.Item label="Description" name="description" rules={[required]}>
            <Input.TextArea />
          </Form.Item>
          <Form.Item
            name="category"
            label="News category"
            rules={[required]}
            colon={false}
          >
            <Select
              optionLabelProp="label"
              mode="multiple"
              dropdownRender={(menu) => (
                <div>
                  {menu}
                  {
                    <Space align="center" className="category-dropdown">
                      <Input
                        placeholder="Please enter item"
                        value={newCategory}
                        onChange={onNameChange}
                      />
                      {creatingCategory ? (
                        <Spin />
                      ) : isInEditState ? (
                        <Typography.Link
                          onClick={() => {
                            EditCategory();
                          }}
                        >
                          Edit Category
                        </Typography.Link>
                      ) : (
                        <Typography.Link onClick={addCategory}>
                          Add category
                        </Typography.Link>
                      )}
                    </Space>
                  }
                </div>
              )}
            >
              {categories?.map((category) => {
                return (
                  <Option value={category?.id} label={category?.categoryName}>
                    <Row justify="space-between">
                      <Col>
                        <p>{category?.categoryName}</p>
                      </Col>
                      {!DEFAULT_NEWS_CATEGORY?.includes(
                        category?.categoryName
                      ) && (
                        <Col>
                          <Button
                            type="text"
                            onClick={(e) => {
                              e.stopPropagation();
                              handleEditCategory(
                                category?.id,
                                category?.categoryName
                              );
                            }}
                          >
                            <EditOutlined />
                          </Button>
                        </Col>
                      )}
                    </Row>
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
          <Form.Item label="Media type" name="type" rules={[required]}>
            <Radio.Group
              onChange={(e) => {
                setMediaType(e?.target?.value);
              }}
              options={options}
              value={mediaType}
              optionType="button"
              buttonStyle="solid"
            />
          </Form.Item>
          {((mediaType !== 'NO_MEDIA' && !edit) || edit) && (
            <Form.Item
              label="Media"
              name="mediaFile"
              rules={[
                {
                  required:
                    mediaType !== 'NO_MEDIA' && !image && !existingMedia,
                  message: 'Please upload media file'
                }
              ]}
            >
              <Upload {...props}>
                {existingMedia ? (
                  <>
                    {existingMediaType === 'IMAGE' ? (
                      <img src={initialValues?.imageLocation} className="img" />
                    ) : (
                      <video width="280" height="300" controls>
                        <source
                          src={initialValues?.videoLocation}
                          type="video/mp4"
                        />
                      </video>
                    )}
                    <DeleteOutlined
                      className="delete-img-btn"
                      onClick={removeImgHandler}
                    />
                  </>
                ) : (
                  <>
                    <Button icon={<UploadOutlined />}>Click to Upload</Button>{' '}
                    &nbsp;
                    <span>(File size must be less than 8MB)</span>
                  </>
                )}
              </Upload>
            </Form.Item>
          )}
          <Form.Item label="Active" name="isActive">
            <Switch defaultChecked={initialValues?.isActive} />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}

export default NewsForm;
