import { Alert, Button, Form, message, Modal, Upload } from 'antd';
import React, { useState } from 'react';
import { firebase } from '../../../firebase';
import { ImportOutlined } from '@ant-design/icons';
import { catchErrorInSentry } from '../../../common/utils';

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 }
};

function AddCards({ orgId }) {
  const [visible, setVisible] = useState(false);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [csvErrors, setCsvErrors] = useState([]);
  const [current, setCurrent] = useState(0);
  const [length, setLength] = useState(0);
  const organizationRef = firebase.firestore().collection('organizations');
  const profileRef = firebase.firestore().collection('profiles');

  return (
    <div>
      <Button
        type="primary"
        onClick={() => {
          setVisible(true);
        }}
      >
        Add Cards
      </Button>
      <Modal
        title="Add Cards"
        visible={visible}
        footer={null}
        onCancel={() => {
          setVisible(false);
          setCsvErrors([]);
        }}
      >
        <Form {...layout} form={form} name="basic">
          <Form.Item {...layout} label="Import CSV">
            <Upload
              accept=".csv"
              showUploadList={false}
              beforeUpload={(file) => {
                setLoading(true);
                const reader = new FileReader();
                reader.onload = async (e) => {
                  try {
                    const cardIds = [];
                    const csvArray = e?.target?.result?.split('\n');
                    for (const id of csvArray) {
                      const trimmedId = id?.replace(/['"]+/g, '')?.trim();
                      if (trimmedId?.length > 0) cardIds.push(trimmedId);
                    }
                    setLength(cardIds?.length);
                    const errors = [];
                    for (const [index, cardId] of cardIds?.entries()) {
                      setCurrent(index + 1);
                      const card = await firebase
                        .firestore()
                        .collection('cards')
                        .doc(cardId)
                        .get();

                      if (card?.exists) {
                        if (!card?.data()?.showProfile) {
                          if (card?.data()?.orgId) {
                            errors.push({
                              cardId,
                              result: 'warning',
                              message:
                                card?.data()?.orgId === orgId
                                  ? 'already added to a organization'
                                  : 'already added to some other organization'
                            });
                          } else {
                            card.ref.update({ orgId });
                            errors.push({
                              cardId,
                              result: 'success',
                              message: 'added successfully to a organization'
                            });
                          }
                          setCsvErrors(errors);
                        } else {
                          profileRef
                            .doc(card?.data()?.showProfile)
                            .get()
                            .then((profileData) => {
                              if (profileData?.data()?.orgId) {
                                errors.push({
                                  cardId,
                                  result: 'warning',
                                  message:
                                    card?.data()?.orgId === orgId
                                      ? 'already added to a organization'
                                      : 'already added to some other organization'
                                });
                                setCsvErrors(errors);
                              } else {
                                card.ref.update({ orgId });
                                organizationRef
                                  .doc(orgId)
                                  .get()
                                  .then((organization) => {
                                    organizationRef.doc(orgId).update({
                                      members: [
                                        ...new Set([
                                          ...organization?.data()?.members,
                                          card?.data()?.showProfile
                                        ])
                                      ]
                                    });
                                  })
                                  .then(() => {
                                    profileRef
                                      .doc(card?.data()?.showProfile)
                                      .update({ orgId });
                                    errors.push({
                                      cardId,
                                      result: 'success',
                                      message:
                                        'added successfully to a organization'
                                    });
                                    setCsvErrors(errors);
                                  });
                              }
                            });
                        }
                      } else {
                        errors.push({
                          cardId,
                          result: 'error',
                          message: 'card code is invalid'
                        });
                        setCsvErrors(errors);
                      }
                    }
                    setCsvErrors([...new Set([...csvErrors, ...errors])]);
                    setLoading(false);
                  } catch (err) {
                    catchErrorInSentry(err);
                    message.error('Some error occurred');
                  }
                };
                reader.readAsText(file);
                return false;
              }}
            >
              <Button
                disabled={loading}
                loading={loading}
                icon={<ImportOutlined />}
              >
                CSV {loading ? `(${current}/${length})` : null}
              </Button>
            </Upload>
          </Form.Item>
        </Form>
        <div style={{ maxHeight: 300, overflow: 'auto' }}>
          {csvErrors.map((card) => {
            return (
              <Alert
                key={card?.cardId}
                style={{ margin: '4px 0' }}
                type={card?.result}
                message={`${card?.cardId} ${card?.message}`}
                closable
              />
            );
          })}
        </div>
      </Modal>
    </div>
  );
}

export default AddCards;
