import React, { useState } from 'react';
import { Button, Modal, Form, Input, Select, message } from 'antd';
import api from '../../../common/api';

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 }
};
const tailLayout = {
  wrapperCol: { offset: 8, span: 16 }
};

function AddUser({ vendorId, role }) {
  const [visible, setVisible] = useState(false);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const handleUser = async (event) => {
    const { email, role, fullName, phoneNumber } = event;
    setLoading(true);
    api
      .post(`${process.env.REACT_APP_SERVER_URL_V2}/user/system-user`, {
        email: email?.toLowerCase()?.trim(),
        fullName,
        phoneNumber,
        role,
        isAdmin: false,
        isActive: true,
        vendorId,
        createdAt: new Date()
      })
      .then(() => {
        setLoading(false);
        setVisible(false);
        form.resetFields();
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };
  const showModal = () => {
    setVisible(true);
  };

  return (
    <>
      <Button type="primary" onClick={() => showModal()}>
        Add User
      </Button>
      <Modal
        title="Add system user"
        visible={visible}
        footer={null}
        onCancel={() => setVisible(false)}
      >
        <Form
          {...layout}
          form={form}
          name="basic"
          initialValues={{ remember: true }}
          onFinish={handleUser}
        >
          <Form.Item
            label="Full Name"
            name="fullName"
            rules={[{ required: true, message: 'Please enter a Full Name!' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Phone Number"
            name="phoneNumber"
            rules={[
              { required: true, message: 'Please enter a Phone number!' }
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Email"
            name="email"
            rules={[{ required: true, message: 'Please enter a username!' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Role"
            name="role"
            rules={[{ required: true, message: 'Please enter a role!' }]}
          >
            <Select
              mode="multiple"
              placeholder="Select Role"
              className="select-w-100"
            >
              {role
                ? role.map((value, key) => (
                    <Select.Option key={value}>{value}</Select.Option>
                  ))
                : null}
            </Select>
          </Form.Item>
          <Form.Item {...tailLayout}>
            <Button loading={loading} type="primary" htmlType="submit">
              Create System User
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}

export default AddUser;
