import {
  CheckCircleOutlined,
  LeftOutlined,
  PlusOutlined,
  SyncOutlined
} from '@ant-design/icons';
import {
  Badge,
  Button,
  Card,
  Col,
  Divider,
  Input,
  message,
  Modal,
  Radio,
  Row,
  Slider,
  Space,
  Spin,
  Switch,
  Tabs,
  Typography,
  Upload
} from 'antd';
import Axios from 'axios';
import React, { useContext, useEffect, useRef, useState } from 'react';
import {
  Prompt,
  Redirect,
  useHistory,
  useParams,
  withRouter
} from 'react-router-dom';
import { AuthContext } from '../../../../Auth';
import api from '../../../../common/api';
import {
  DESIGN_CENTRE,
  LEGACY_DESIGN_EXPERIENCE,
  NOTE_MESSAGE
} from '../../../../common/constant';
import { catchErrorInSentry } from '../../../../common/utils';
import { firebase } from '../../../../firebase';
import './addDesign.css';
import CardUsersDetails from './CardUsersDetails';
import DesignCenter from './DesignCenter';
import QrPosition from './QrPosition';
import QrSettings from './QrSettings';
import ShowTemplateModal from './showTemplateModal';

const axios = require('axios');
const { TabPane } = Tabs;
const layout = {
  labelCol: { span: 12 },
  wrapperCol: { span: 12 }
};
const layout2 = {
  labelCol: { span: 5 },
  wrapperCol: { span: 16 }
};
const rotationMarks = {
  0: '0°',
  90: '90°',
  180: '180°',
  270: '270°'
};
let frontHtml, backHtml;
function AddDesign() {
  const { id } = useParams();
  const history = useHistory();
  const [set, setSet] = useState({});
  const [activeUserTab, setActiveUserTab] = useState(0);
  const [redirect, setRedirect] = useState(false);
  const [uploadInProgress, setUploadInProgress] = useState(false);
  const [loading, setLoading] = useState(false);
  const [qrWidth, setQrWidth] = useState(145);
  const [qrHeight, setQrHeight] = useState(145);
  const [qrX, setQrX] = useState(130);
  const [qrY, setQrY] = useState(55);
  const [qrRotation, setQrRotation] = useState(0);
  const [roundedCorner, setRoundedCorner] = useState(false);
  const [showLogo, setShowLogo] = useState(false);
  const [cardDesign, setCardDesign] = useState({});
  const chatRef = firebase.firestore().collection('chats');
  const { data } = useContext(AuthContext);
  const [qrSide, setQrSide] = useState('BACK');
  const [hasSameFrontSide, setHasSameFrontSide] = useState(false);
  const [hasSameBackSide, setHasSameBackSide] = useState(false);
  const [note, setNote] = useState(NOTE_MESSAGE);
  const [uploadedDesigns, setUploadedDesigns] = useState(0);
  const [showQr, setShowQr] = useState(true);
  const [disableSubmit, setDisableSubmit] = useState(true);
  const [retryIndex, setRetryIndex] = useState(0);
  const [showRetryBtn, setShowRetryBtn] = useState(false);
  const [showQrPositionSetting, setShowQrPositionSetting] = useState(false);
  const [base64Str, setBase64Str] = useState('');
  const [qrSettingLoader, setQrSettingLoader] = useState(false);
  const [showValidateNote, setShowValidateNote] = useState(false);
  const [isPreviousConfig, setIsPreviousConfig] = useState(false);
  const [designExperience, setDesignExperience] = useState(DESIGN_CENTRE);
  const [template, setTemplate] = useState();
  const [templateLoading, setTemplateLoading] = useState(true);
  const [cardData, setCardData] = useState([]);
  const [newField, setNewField] = useState('');
  const [hideField, setHideField] = useState(true);
  const [currentTab, setCurrentTab] = useState('front');
  const [activeTab, setActiveTab] = useState('1');
  const [front, setFront] = useState({});
  const [back, setBack] = useState({});
  const [backgroundColorFront, setBackgroundColorFront] = useState('#ffffff');
  const [backgroundColorBack, setBackgroundColorBack] = useState('#ffffff');
  const [fontData, setFontData] = useState();
  const [sameBackgroundColor, setSameBackgroundColor] = useState(true);
  const [frontCardImage, setFrontCardImage] = useState();
  const [backCardImage, setBackCardImage] = useState();
  const [fontFace, setFontFace] = useState('');
  const [frontImageUploading, setFrontImageUploading] = useState(false);
  const [backgroundImageUploading, setBackgroundImageUploading] =
    useState(false);
  const [orgId, setOrgId] = useState();
  const [frontNode, setFrontNode] = useState();
  const [backNode, setBackNode] = useState();
  const [editingTemplate, setEditingTemplate] = useState(false);
  const [qrConfig, setQrConfig] = useState({
    qrType: 'center-logo',
    qrPadding: 100,
    qrPixelColor: '#000',
    qrRadius: false,
    qrLogoUrl: '',
    showText: true,
    qrInverted: false,
    qrRoundedCorner: true
  });
  const [savingTemplate, setSavingTemplate] = useState(false);
  const [isSaveQrClicked, setIsSaveQrClicked] = useState(false);
  const [isTabSwitched, setIsTabSwitched] = useState(false);
  const [showWarningModal, setShowWarningModal] = useState(false);
  const [fetchingPreviousDesign, setFetchingPreviousDesign] = useState(false);
  const [isEditModeOn, setIsEditModeOn] = useState(false);
  const [overLapUserIndexes, setOverLapUserIndexes] = useState([]);
  let csvData = [];
  let allFontFace = '';

  const getOrgId = async (ownerEmail) => {
    try {
      if (ownerEmail) {
        const profileData = await getProfileData(ownerEmail);
        profileData.docs.forEach((profile) => {
          if (profile?.data()?.orgId) {
            setOrgId(profile?.data()?.orgId);
          } else {
            setDesignExperience(LEGACY_DESIGN_EXPERIENCE);
          }
        });
        if (profileData?.docs?.length === 0) {
          setTemplateLoading(false);
        }
      }
    } catch (error) {
      catchErrorInSentry(error);
      setTemplateLoading(false);
      console.log(error);
    }
  };
  const getProfileData = async (ownerEmail) => {
    const profileData = await firebase
      .firestore()
      .collection('profiles')
      .where('user', '==', ownerEmail)
      .get();
    return profileData;
  };

  function getSet() {
    setLoading(true);
    firebase
      .firestore()
      .collection('sets')
      .where('setUniqueId', '==', id)
      .onSnapshot((querySnapshot) => {
        const updatedCardUsers = [];
        querySnapshot.docs[0].data().cardUsers.map((user, index) => {
          updatedCardUsers.push({
            ...user,
            employeeUniqueId: `${
              querySnapshot?.docs[0]?.data()?.setUniqueId
            }_${index}`
          });
        });
        setSet({
          ...querySnapshot?.docs[0]?.data(),
          cardUsers: updatedCardUsers
        });
      });
    setLoading(false);
  }

  useEffect(() => {
    const obj = {};
    if (set?.cardUsers && set?.owner) {
      set.cardUsers.forEach((user) => {
        obj[user?.employeeUniqueId] = {
          uploadInProgress: false,
          docId: null,
          iFrameUrl: null
        };
      });
      if (set?.owner) {
        getOrgId(set?.owner).then(() => {
          checkValidate();
        });
      }
    }
  }, [set]);

  const handleUpload = (file, side, user, property, ai, hasSameDesign) => {
    setUploadInProgress(true);
    setCardDesign({ ...cardDesign });
    message.loading({
      content: 'Uploading, please wait for a while...',
      key: 'uploading',
      duration: 10
    });
    const isValidExtension = ai
      ? file?.type === 'application/postscript' || 'application/illustrator'
      : file?.type === 'image/png';
    if (!isValidExtension) {
      if (ai) {
        message.error({
          content: 'You can only upload AI file!',
          key: 'uploading',
          duration: 3
        });
      } else
        message.error({
          content: 'You can only upload PNG file!',
          key: 'uploading',
          duration: 3
        });
      setUploadInProgress(false);
      return;
    }
    if (isValidExtension && ai) {
      var storageRef = firebase.storage().ref();
      var uploadTask = storageRef
        .child(`images/${set?.setUniqueId}-${side}-${file?.uid}.ai`)
        .put(file);
      uploadTask.on(
        'state_changed',
        function (snapshot) {},
        function (error) {
          setUploadInProgress(false);
          message.error({
            content: 'Error occurred while uploading, please try again!',
            key: 'uploading',
            duration: 3
          });
          console.log(error);
        },
        function () {
          uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
            if (hasSameDesign) {
              let temp = {};
              set.cardUsers.forEach((user) => {
                temp = {
                  ...cardDesign,
                  ...temp,
                  [user?.employeeUniqueId]: {
                    ...temp[user?.employeeUniqueId],
                    ...cardDesign[user?.employeeUniqueId],
                    [property]: downloadURL,
                    cardUser: user
                  }
                };
              });
              setCardDesign(temp);
              setUploadInProgress(false);
              message.success({
                content: 'Uploaded!',
                key: 'uploading',
                duration: 3
              });
            } else {
              setCardDesign({
                ...cardDesign,
                [user?.employeeUniqueId]: {
                  ...cardDesign[user?.employeeUniqueId],
                  [property]: downloadURL,
                  cardUser: user
                }
              });
              setUploadInProgress(false);
              message.success({
                content: 'Uploaded!',
                key: 'uploading',
                duration: 3
              });
            }
          });
        }
      );
    }

    if (isValidExtension && !ai) {
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function (e) {
        var image = new Image();
        image.src = e?.target?.result;
        image.onload = function () {
          var height = this.height;
          var width = this.width;
          if (width !== 2022 && height !== 1275) {
            setUploadInProgress(false);
            message.error({
              content: 'Image must be of dimension 2022px x 1275px',
              key: 'uploading',
              duration: 3
            });
          } else {
            var storageRef = firebase.storage().ref();
            var uploadTask = storageRef
              .child(`images/${set?.setUniqueId}-${side}-${file?.uid}`)
              .put(file);
            uploadTask.on(
              'state_changed',
              function (snapshot) {},
              function (error) {
                setUploadInProgress(false);
                message.error({
                  content: 'Error occurred while uploading, please try again!',
                  key: 'uploading',
                  duration: 3
                });
                console.log(error);
              },
              function () {
                uploadTask.snapshot.ref
                  .getDownloadURL()
                  .then(function (downloadURL) {
                    if (hasSameDesign) {
                      let temp = {};
                      set.cardUsers.forEach((user) => {
                        temp = {
                          ...cardDesign,
                          ...temp,
                          [user?.employeeUniqueId]: {
                            ...temp[user?.employeeUniqueId],
                            ...cardDesign[user?.employeeUniqueId],
                            [property]: downloadURL,
                            cardUser: user
                          }
                        };
                      });
                      setCardDesign(temp);
                      setUploadInProgress(false);
                      message.success({
                        content: 'Uploaded!',
                        key: 'uploading',
                        duration: 3
                      });
                    } else {
                      setCardDesign({
                        ...cardDesign,
                        [user?.employeeUniqueId]: {
                          ...cardDesign[user?.employeeUniqueId],
                          [property]: downloadURL,
                          cardUser: user
                        }
                      });
                      setUploadInProgress(false);
                      message.success({
                        content: 'Uploaded!',
                        key: 'uploading',
                        duration: 3
                      });
                    }
                  });
              }
            );
          }
        };
      };
    }
  };

  const getQrData = async () => {
    try {
      setQrSettingLoader(true);
      const token = await firebase.auth().currentUser.getIdToken(true);
      const generateQrCode = await Axios.post(
        `${process?.env?.REACT_APP_SERVER_URL_V2}/design-template/generate-qr`,
        {
          link: 'https://www.mobilocard.com',
          pixelColor: qrConfig?.qrPixelColor,
          qrPadding: qrConfig?.qrPadding,
          logoUrl: qrConfig?.qrLogoUrl,
          qrType: qrConfig?.qrType,
          radius: qrConfig?.qrRadius, // radius is true if qr code boxes are rounded
          showText: qrConfig?.showText,
          qrInverted: qrConfig?.qrInverted,
          qrRoundedCorner: qrConfig?.qrRoundedCorner,
          qrShowLogo: qrConfig?.qrShowLogo
        },
        { headers: { authorization: `Bearer ${token}` } }
      );
      setBase64Str(generateQrCode?.data?.data);
      setQrSettingLoader(false);
    } catch (err) {
      catchErrorInSentry(err?.response?.data?.message);
      setQrSettingLoader(false);
      console.log(err?.message);
    }
  };

  useEffect(() => {
    if (qrConfig?.qrType) {
      getQrData();
    }
  }, [qrConfig]);
  const getLastDesingOfOrder = async () => {
    setFetchingPreviousDesign(true);
    const token = await firebase.auth().currentUser.getIdToken(true);
    const response = await axios.get(
      `${process.env.REACT_APP_SERVER_URL}/v2/card-design-template/get?orgId=${orgId}&orderUniqueId=${set?.orderUniqueId}&userIndex=${activeUserTab}`,
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );
    setFetchingPreviousDesign(false);
    if (response?.data) {
      setFrontCardImage(response?.data?.data?.frontCardImage);
      setBackCardImage(response?.data?.data?.backCardImage);
      setFontData(response?.data?.data?.fontsData);
      setNewField(response?.data?.data?.front?.customField?.value);
      setBackgroundColorFront(response?.data?.data?.backgroundColorFront);
      setBackgroundColorBack(response?.data?.data?.backgroundColorBack);
      setFront(response?.data?.data?.front);
      setBack(response?.data?.data?.back);
      setFrontNode(response?.data?.data?.frontHTMLData);
      setBackNode(response?.data?.data?.backHTMLData);
      response.data.data.fontsData.map((font) => {
        const fontFace = new FontFace(font?.name, `url(${font?.link})`);
        addFontFace(font?.name, font?.link);
        document.fonts.add(fontFace);
        fontFace.load();
      });
    }
  };
  const handleDesignForApproval = async () => {
    await api
      .post(`${process?.env?.REACT_APP_SERVER_URL_V2}/orders/approve-design`, {
        orderUniqueId: set?.orderUniqueId,
        setUniqueId: set?.setUniqueId
      })
      .then(() => {
        setRedirect(true);
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  async function handleSubmit() {
    const token = await firebase.auth().currentUser.getIdToken(true);
    setLoading(true);
    const cardDesignArray = Object.values(cardDesign);
    const imagesToCompose = [];
    cardDesignArray.map((cardDesign) => {
      const baseImageToComposite =
        qrSide === 'FRONT'
          ? cardDesign?.frontImageUrl
          : cardDesign?.backImageUrl;
      imagesToCompose.push(baseImageToComposite);
    });

    let i;
    setShowRetryBtn(false);
    for (i = retryIndex; i < cardDesignArray?.length; i++) {
      try {
        if (showQr) {
          const response = await axios.post(
            `${process?.env?.REACT_APP_SERVER_URL_V2}/design-template/preview/qr`,
            {
              images: [imagesToCompose[i]],
              qrHeight: qrHeight * 5,
              qrWidth: qrWidth * 5,
              qrX: qrX * 5,
              qrY: qrY * 5,
              ...(base64Str ? { qrLink: base64Str } : {}),
              ...(qrRotation ? { qrRotation } : {})
            },
            { headers: { authorization: `Bearer ${token}` } }
          );
          cardDesignArray[i].qrReference = response?.data?.data[0];
        } else {
          cardDesignArray[i].qrReference = '';
        }
        setUploadedDesigns(i + 1);
      } catch (err) {
        catchErrorInSentry(err?.response?.data?.message);
        setUploadedDesigns(i);
        setShowRetryBtn(true);
        setRetryIndex(i);
        setLoading(false);
        break;
      }
    }
    if (i === cardDesignArray?.length) {
      chatRef
        .doc(set?.setUniqueId)
        .collection('messages')
        .add({
          action: 'NO_ACTION_TAKEN',
          actionRequired: true,
          message: note,
          orderUniqueId: set?.orderUniqueId,
          senderId: data?.userId,
          senderName: data?.fullName,
          setUniqueId: set?.setUniqueId,
          createdAt: new Date(),
          designSet: {
            qrDesignConfig: qrConfig, // qrCode design config
            cardWidth: 2022,
            cardHeight: 1275,
            qrWidth: qrWidth * 5,
            qrHeight: qrHeight * 5,
            qrX: qrX * 5,
            qrY: qrY * 5,
            cardDesign: cardDesignArray,
            qrSide: showQr ? qrSide : 'NA',
            qrRotation,
            user: set?.owner,
            cardType: set?.cardType
          }
        })
        .then((ref) => {
          chatRef.doc(set?.setUniqueId).collection('messages').doc(ref?.id).set(
            {
              docId: ref?.id
            },
            { merge: true }
          );
          firebase
            .firestore()
            .collection('sets')
            .where('setUniqueId', '==', set?.setUniqueId)
            .get()
            .then((querySnapshot) => {
              firebase
                .firestore()
                .collection('sets')
                .doc(querySnapshot?.docs[0]?.id)
                .update({
                  status: 'NEED_DESIGN_APPROVAL',
                  currentDesignId: ref?.id,
                  updatedAt: new Date()
                })
                .then(() => {
                  if (!set?.isPreApproved) {
                    handleDesignForApproval();
                  } else {
                    message.success('This order is pre-approved!');
                    setRedirect(true);
                    setLoading(false);
                  }
                });
            });
          firebase
            .firestore()
            .collection('orders')
            .where('orderUniqueId', '==', set?.orderUniqueId)
            .get()
            .then((querySnapshot) => {
              firebase
                .firestore()
                .collection('orders')
                .doc(querySnapshot?.docs[0]?.id)
                .update({
                  reviewRequired: true,
                  updatedAt: new Date(),
                  status: 'NEED_DESIGN_APPROVAL'
                });
            });
          setCardDesign({});
          message.success('Design Uploaded successfully');
        });
    }
  }
  async function getLastDesign(id) {
    const designSet = await firebase
      .firestore()
      .collection('chats')
      .doc(set?.setUniqueId)
      .collection('messages')
      .doc(id)
      .get();
    const design = designSet?.data()?.designSet;
    setQrWidth(design?.qrWidth / 5);
    setQrHeight(design?.qrHeight / 5);
    setQrX(design?.qrX / 5);
    setQrY(design?.qrY / 5);
  }

  useEffect(() => {
    getSet();
    if (set?.currentDesignId) {
      getLastDesign(set?.currentDesignId);
    }
  }, []);
  const checkValidate = () => {
    for (const user of set?.cardUsers) {
      if (user?.firstName?.length > 0 && user?.email?.length > 0) {
        setDisableSubmit(false);
        setShowValidateNote(false);
      } else {
        setShowValidateNote(true);
        setDisableSubmit(true);
        break;
      }
    }
  };
  useEffect(() => {
    if (orgId) {
      getTemplateData();
      if (set?.isAlreadyDesigned) {
        setIsEditModeOn(true);
        getLastDesingOfOrder();
      }
    }
  }, [orgId, activeUserTab]);
  const getOverLapUserIndex = async () => {
    try {
      const response = await firebase
        .firestore()
        .collection('orders')
        .where('orderUniqueId', '==', set?.orderUniqueId)
        .get();
        response.docs.forEach((doc) => {
        setOverLapUserIndexes(doc?.data()?.overLapUserIndexes);
      });
    } catch {}
  };

  useEffect(() => {
    if (isEditModeOn) {
      getOverLapUserIndex();
    }
  }, [isEditModeOn]);

  const validate = () => {
    const length = set?.cardUsers ? set?.cardUsers?.length : null;
    let correctSet = 0;
    Object.values(cardDesign).forEach((card, index) => {
      let conditions = true;
      if (
        set?.cardType === 'Mobilo Metal' ||
        set?.cardType === 'Mobilo Metal (promo)'
      ) {
        conditions = 'frontAIUrl' in card && 'backAIUrl' in card;
      }
      if (
        'frontImageUrl' in card &&
        'backImageUrl' in card &&
        conditions &&
        note?.length > 0
      ) {
        correctSet += 1;
      }
    });
    return correctSet !== length;
  };

  useEffect(() => {
    generateHtml(currentTab);
  }, [
    front,
    back,
    backgroundColorBack,
    backgroundColorFront,
    currentTab,
    frontCardImage,
    backCardImage
  ]);

  if (redirect) {
    return <Redirect to="/design/inprogress" />;
  }
  const options = [
    { label: 'Design Center', value: DESIGN_CENTRE },
    { label: 'Legacy Design Experience', value: LEGACY_DESIGN_EXPERIENCE }
  ];
  const handleDesignExperience = (e) => {
    setDesignExperience(e?.target?.value);
  };

  const getTemplateData = async () => {
    try {
      const token = await firebase.auth().currentUser.getIdToken(true);
      if (token) {
        const templateData = await axios({
          method: 'get',
          url: `${process?.env?.REACT_APP_SERVER_URL}/v2/design-template/get?orgId=${orgId}`,
          headers: {
            'Content-Type': 'application/json',
            authorization: `Bearer ${token}`
          }
        });
        setTemplate(templateData?.data?.data);
        set.cardUsers.map((user) =>
          csvData.push({ email: user?.email, value: user?.email })
        );
        setTemplateLoading(false);
      }
    } catch (error) {
      catchErrorInSentry(error?.response?.data?.message);
      setTemplateLoading(false);
    }
  };
  //wait functional is written intentionally.(after forcefully switching the tab, before hitting api, cards' htmlNode is formed during the wait period)
  const wait = (time = 5000) =>
    new Promise((resolve) => setTimeout(resolve, time));
  const editDesign = async () => {
    try {
      setEditingTemplate(true);
      const accessToken = await firebase.auth().currentUser.getIdToken(true);
      let dataToSend = {
        front: front,
        back: back,
        backgroundColorFront: backgroundColorFront,
        backgroundColorBack: backgroundColorBack,
        frontCardImage: frontCardImage,
        backCardImage: backCardImage,
        orgId: orgId,
        showQR: showQr,
        base64Str: base64Str,
        fontsData: fontData || [],
        qrConfig,
        qrWidth,
        qrHeight,
        qrX,
        qrY,
        qrRotation,
        qrSide
      };
      if (accessToken) {
        const data = await axios({
          method: 'post',
          url: `${process?.env?.REACT_APP_SERVER_URL}/v2/card-design-template/save?orgId=${orgId}&orderUniqueId=${set?.orderUniqueId}&userIndex=${activeUserTab}`,
          headers: {
            'Content-Type': 'application/json',
            authorization: `Bearer ${accessToken}`
          },
          data: dataToSend
        });
        if (data) {
          await api({
            method: 'post',
            url: `${process?.env?.REACT_APP_PDF_DOWNLOAD_URL}/save-html?templateId=${data?.data?.data}`,
            headers: {
              'Content-Type': 'application/json'
            },
            data: {
              front: frontNode,
              back: backNode,
              forOrderLevel: true
            }
          })
            .then(async () => {
              setEditingTemplate(false);
            })
            .catch(() => {
              setEditingTemplate(false);
            });
        }
      }
    } catch (error) {
      message.error('something went wrong');
      console.log(error);
    }
  };
  const handleBulkGeneration = async () => {
    try {
      const bulkGeneratioResponseData = await api.get(
        `${process?.env?.REACT_APP_PDF_DOWNLOAD_URL}/mat/card-design/create-preview?orderUniqueId=${set?.orderUniqueId}&designTemplateId=${template?.templateId}`,
        {
          headers: {
            secretkey: process.env.REACT_APP_PDF_FIX_SECRET
          }
        }
      );
      if (bulkGeneratioResponseData) {
        message.success(bulkGeneratioResponseData?.data?.message);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const saveTemplate = async (frontNode, backNode) => {
    try {
      setSavingTemplate(true);
      let imageData;
      const accessToken = await firebase.auth().currentUser.getIdToken(true);
      if (frontNode && backNode) {
        imageData = await api({
          method: 'post',
          url: `${process?.env?.REACT_APP_PDF_DOWNLOAD_URL}/mat/card-design/get-png?orderUniqueId=${set?.orderUniqueId}`,
          headers: {
            'Content-Type': 'application/json',
            secretKey: process.env.REACT_APP_PDF_FIX_SECRET
          },
          data: {
            front: frontNode,
            back: backNode,
            fontsData: fontData || []
          }
        });
      } else {
        message.warn('Something went wrong!');
        setSavingTemplate(false);
      }
      let dataToSend = {
        front: front,
        back: back,
        backgroundColorFront: backgroundColorFront,
        backgroundColorBack: backgroundColorBack,
        frontCardImage: frontCardImage,
        backCardImage: backCardImage,
        orgId: orgId,
        frontImage: imageData?.data?.data[0]?.url,
        backImage: imageData?.data?.data[1]?.url,
        showQR: showQr,
        base64Str: base64Str,
        fontsData: fontData || [],
        qrConfig,
        qrWidth,
        qrHeight,
        qrX,
        qrY,
        qrRotation,
        qrSide
      };
      if (accessToken && imageData) {
        const data = await axios({
          method: 'post',
          url: `${process?.env?.REACT_APP_SERVER_URL}/v2/design-template/save`,
          headers: {
            'Content-Type': 'application/json',
            authorization: `Bearer ${accessToken}`
          },
          data: dataToSend
        });
        setSavingTemplate(false);
        message.success(data?.data?.message);
        if (data) {
          await api({
            method: 'post',
            url: `${process?.env?.REACT_APP_PDF_DOWNLOAD_URL}/save-html?templateId=${data?.data?.data}`,
            headers: {
              'Content-Type': 'application/json',
              authorization: `Bearer ${accessToken}`
            },
            data: {
              front: frontNode,
              back: backNode
            }
          }).then(() => {
            getTemplateData();
          });
        }
      }
    } catch (error) {
      message.error('something went wrong');
      setSavingTemplate(false);
      console.log(error);
    }
  };
  const handleFontUpload = async (fileList, formData) => {
    try {
      const token = await firebase.auth().currentUser.getIdToken(true);
      setUploadInProgress(true);
      const data = await api.post(
        `${process.env.REACT_APP_PDF_DOWNLOAD_URL}/mat/card-design/upload-fonts?orderUniqueId=${set?.orderUniqueId}`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            secretKey: process?.env?.REACT_APP_PDF_FIX_SECRET
          }
        }
      );
      setFontData(data?.data?.data);
      setUploadInProgress(false);
      data.data.data.map((font) => {
        const fontFace = new FontFace(font?.name, `url(${font?.link})`);
        addFontFace(font?.name, font?.link);
        document.fonts.add(fontFace);
        fontFace.load();
      });
    } catch (error) {
      console.log(error);
      setUploadInProgress(false);
    }
  };
  const addFontFace = (fontFamily, src) => {
    if (fontFamily && src) {
      const newFontFace = `@font-face{
        font-family:${fontFamily};
        src: url(${src})
      }`;
      allFontFace = newFontFace + allFontFace;
      setFontFace(allFontFace);
    }
  };
  const generateHtml = async (node) => {
    if (node == 'front') {
      frontHtml = document.getElementById('card-layout-front-wrapper');
      setFrontNode(
        `<html><head><style>${fontFace}</style></head><body style="margin: 0 !important">${frontHtml?.innerHTML}</body></html>`
      );
    } else {
      backHtml = document.getElementById('card-layout-back-wrapper');
      setBackNode(
        `<html><head><style>${fontFace}</style></head><body style="margin: 0 !important">${backHtml?.innerHTML}</body></html>`
      );
    }
  };

  const onFileInput = async (e) => {
    const { files } = e?.currentTarget;
    if (files && files?.length > 0) {
      let formData = new FormData();
      for (let i = 0; i < files?.length; i++) {
        formData.append('fonts', files[i]);
      }
      handleFontUpload(files, formData);
    }
  };
  const handleBackgroundImageUpload = (file, side) => {
    if (side === 'front') {
      setFrontImageUploading(true);
    } else {
      setBackgroundImageUploading(true);
    }
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function (e) {
      var image = new Image();
      image.src = e?.target?.result;
      image.onload = function () {
        var height = this.height;
        var width = this.width;
        if (width !== 2022 && height !== 1275) {
          if (side === 'front') {
            setFrontImageUploading(false);
          } else {
            setBackgroundImageUploading(false);
          }
          message.error({
            content: 'Image must be of dimension 2022px x 1275px',
            key: 'uploading',
            duration: 3
          });
        } else {
          var storageRef = firebase?.storage()?.ref();
          var uploadTask = storageRef
            .child(`backgroundImage/${set?.orderUniqueId}-${side}`)
            .put(file);
          uploadTask.on(
            'state_changed',
            function (snapshot) {},
            function (error) {
              if (side === 'front') {
                setFrontImageUploading(false);
              } else {
                setBackgroundImageUploading(false);
              }
              message.error({
                content: 'Error occurred while uploading, please try again!',
                key: 'uploading',
                duration: 3
              });
              console.log(error);
            },
            function () {
              uploadTask.snapshot.ref
                .getDownloadURL()
                .then(function (downloadURL) {
                  setBackgroundImageUploading(false);
                  if (side === 'front') {
                    setFrontCardImage(downloadURL);
                  } else {
                    setBackCardImage(downloadURL);
                  }
                });
            }
          );
        }
      };
    };
  };
  return (
    <>
      <Prompt
        when={true}
        message="You have unsaved changes, are you sure you want to leave?"
      />
      <Card
        bodyStyle={{
          pointerEvents: loading ? 'none' : 'auto',
          opacity: loading ? 0.4 : 1
        }}
        title={
          <Space>
            <Button
              disabled={loading}
              onClick={() => {
                history.goBack();
              }}
              type="text"
              icon={<LeftOutlined />}
            />
            <Typography.Title style={{ margin: 0 }} level={3}>
              {id} | Add Design
            </Typography.Title>
          </Space>
        }
        extra={
          designExperience === LEGACY_DESIGN_EXPERIENCE ? (
            showRetryBtn ? (
              <Button
                loading={loading}
                icon={<SyncOutlined />}
                disabled={validate() || disableSubmit}
                type="primary"
                onClick={() => handleSubmit()}
              >
                Retry
              </Button>
            ) : (
              <Button
                loading={loading}
                disabled={validate() || disableSubmit}
                type="primary"
                onClick={() => handleSubmit()}
              >
                {loading
                  ? `Uploading (${uploadedDesigns}/${
                      Object.values(cardDesign)?.length
                    })`
                  : 'Submit'}
              </Button>
            )
          ) : null
        }
      >
        {orgId && (
          <div className="design-experience-toggle-section">
            <Radio.Group
              options={options}
              onChange={handleDesignExperience}
              value={designExperience}
              optionType="button"
            />
          </div>
        )}
        <Card
          size="small"
          title="Upload Designs (Designs must be PNG with 2022px x 1275px dimensions)"
          extra={
            set?.cardUsers?.length > 1 ? (
              <Space>
                <Space style={{ marginRight: '1rem' }}>
                  <Typography.Text>Same front design : </Typography.Text>
                  <Switch onChange={(e) => setHasSameFrontSide(e)} />
                </Space>
                <Space>
                  <Typography.Text>Same back design : </Typography.Text>
                  <Switch onChange={(e) => setHasSameBackSide(e)} />
                </Space>
              </Space>
            ) : null
          }
        >
          <Tabs
            type="card"
            onTabClick={(e) => {
              setCurrentTab('front');
              setActiveTab('1');
              setIsTabSwitched(false);
              setIsSaveQrClicked(false);
              setActiveUserTab(Number(e));
            }}
            destroyInactiveTabPane
            tabBarExtraContent={
              <Space>
                {designExperience === DESIGN_CENTRE && templateLoading ? (
                  <Spin spinning={templateLoading} />
                ) : (
                  <ShowTemplateModal
                    orgId={orgId}
                    set={set}
                    template={template}
                    setTemplate={setTemplate}
                    csvData={csvData}
                    setRedirect={setRedirect}
                  />
                )}
              </Space>
            }
          >
            {set?.cardUsers
              ? set.cardUsers.map((user, index) => {
                  return (
                    <TabPane
                      tab={
                        <Badge dot={overLapUserIndexes?.includes(index)}>
                          <Typography.Text strong>
                            {user?.firstName} {user?.lastName}
                          </Typography.Text>
                        </Badge>
                      }
                      key={index}
                    >
                      <>
                        <CardUsersDetails
                          user={user}
                          showValidateNote={showValidateNote}
                        />
                        <Row
                          justify={
                            designExperience === LEGACY_DESIGN_EXPERIENCE
                              ? 'start'
                              : 'end'
                          }
                        >
                          {designExperience === LEGACY_DESIGN_EXPERIENCE && (
                            <Col>
                              <Space>
                                {/* card front image upload */}
                                <Upload
                                  disabled={uploadInProgress}
                                  style={{ width: '50%' }}
                                  name="Front"
                                  listType="picture-card"
                                  showUploadList={false}
                                  action={(file) => {
                                    handleUpload(
                                      file,
                                      'FRONT',
                                      user,
                                      'frontImageUrl',
                                      false,
                                      hasSameFrontSide
                                    );
                                  }}
                                >
                                  {cardDesign[user?.employeeUniqueId]
                                    ?.frontImageUrl ? (
                                    <img
                                      src={
                                        cardDesign[user?.employeeUniqueId]
                                          .frontImageUrl
                                      }
                                      alt="avatar"
                                      style={{ width: '100%' }}
                                    />
                                  ) : (
                                    <div>
                                      <PlusOutlined />
                                      <div style={{ marginTop: 8 }}>
                                        Front PNG
                                      </div>
                                    </div>
                                  )}
                                </Upload>
                                {/* Card back image upload */}
                                <Upload
                                  disabled={uploadInProgress}
                                  name="Back"
                                  listType="picture-card"
                                  showUploadList={false}
                                  action={(file) => {
                                    handleUpload(
                                      file,
                                      'BACK',
                                      user,
                                      'backImageUrl',
                                      false,
                                      hasSameBackSide
                                    );
                                  }}
                                >
                                  {cardDesign[user?.employeeUniqueId]
                                    ?.backImageUrl ? (
                                    <img
                                      src={
                                        cardDesign[user?.employeeUniqueId]
                                          .backImageUrl
                                      }
                                      alt="avatar"
                                      style={{ width: '100%' }}
                                    />
                                  ) : (
                                    <div>
                                      <PlusOutlined />
                                      <div style={{ marginTop: 8 }}>
                                        Back PNG
                                      </div>
                                    </div>
                                  )}
                                </Upload>
                                {set?.cardType === 'Mobilo Metal' ||
                                set?.cardType === 'Mobilo Metal (promo)' ? (
                                  <Space>
                                    {/* Metal card FrontAI upload */}
                                    <Upload
                                      disabled={uploadInProgress}
                                      style={{ width: '50%' }}
                                      name="Front-AI"
                                      listType="picture-card"
                                      showUploadList={false}
                                      action={(file) => {
                                        handleUpload(
                                          file,
                                          'FRONT_AI',
                                          user,
                                          'frontAIUrl',
                                          true,
                                          hasSameFrontSide
                                        );
                                      }}
                                    >
                                      {cardDesign[user?.employeeUniqueId] ? (
                                        cardDesign[user?.employeeUniqueId]
                                          ?.frontAIUrl ? (
                                          <CheckCircleOutlined
                                            style={{ color: 'green' }}
                                          />
                                        ) : (
                                          <div>
                                            <PlusOutlined />
                                            <div style={{ marginTop: 8 }}>
                                              Front AI
                                            </div>
                                          </div>
                                        )
                                      ) : (
                                        <div>
                                          <PlusOutlined />
                                          <div style={{ marginTop: 8 }}>
                                            Front AI
                                          </div>
                                        </div>
                                      )}
                                    </Upload>
                                    {/* Metal card BackAI upload */}
                                    <Upload
                                      disabled={uploadInProgress}
                                      style={{ width: '50%' }}
                                      name="Back-AI"
                                      listType="picture-card"
                                      showUploadList={false}
                                      action={(file) => {
                                        handleUpload(
                                          file,
                                          'BACK_AI',
                                          user,
                                          'backAIUrl',
                                          true,
                                          hasSameBackSide
                                        );
                                      }}
                                    >
                                      {cardDesign[user?.employeeUniqueId] ? (
                                        cardDesign[user?.employeeUniqueId]
                                          ?.backAIUrl ? (
                                          <CheckCircleOutlined
                                            style={{ color: 'green' }}
                                          />
                                        ) : (
                                          <div>
                                            <PlusOutlined />
                                            <div style={{ marginTop: 8 }}>
                                              Back AI
                                            </div>
                                          </div>
                                        )
                                      ) : (
                                        <div>
                                          <PlusOutlined />
                                          <div style={{ marginTop: 8 }}>
                                            Back AI
                                          </div>
                                        </div>
                                      )}
                                    </Upload>
                                  </Space>
                                ) : null}
                              </Space>
                            </Col>
                          )}
                        </Row>

                        <Modal
                          visible={showWarningModal}
                          onOk={() => {
                            setActiveTab('2');
                            setIsTabSwitched(false);
                            setIsSaveQrClicked(false);
                            setShowWarningModal(false);
                          }}
                          onCancel={async () => {
                            setShowWarningModal(false);
                            if (currentTab) {
                              setCurrentTab(
                                currentTab === 'front' ? 'back' : 'front'
                              );
                            }
                            await wait(2000);

                            if (frontNode && backNode) {
                              saveTemplate(frontNode, backNode);
                            }
                          }}
                        >
                          To save your current changes with QR, Kindly configure
                          QR again
                        </Modal>
                        {designExperience === DESIGN_CENTRE && orgId && (
                          <Tabs
                            defaultActiveKey="1"
                            onTabClick={async (key) => {
                              if (isEditModeOn && currentTab === 'front') {
                                setCurrentTab('back');
                                await wait(500);
                                setActiveTab(key);
                              } else {
                                setActiveTab(key);
                              }
                            }}
                            activeKey={activeTab}
                            tabBarExtraContent={
                              <>
                                {set?.isAlreadyDesigned ? (
                                  <Space direction="horizontal">
                                    <Button
                                      onClick={async () => {
                                        if (isSaveQrClicked && isTabSwitched) {
                                          setShowWarningModal(true);
                                        } else {
                                          if (currentTab) {
                                            setCurrentTab(
                                              currentTab === 'front'
                                                ? 'back'
                                                : 'front'
                                            );
                                          }
                                          await wait(2000);

                                          if (frontNode && backNode) {
                                            editDesign(frontNode, backNode);
                                          }
                                        }
                                      }}
                                      loading={editingTemplate}
                                    >
                                      Save Edited
                                    </Button>
                                    <Button
                                      onClick={() => {
                                        handleBulkGeneration();
                                      }}
                                    >
                                      Save all Edits
                                    </Button>
                                  </Space>
                                ) : (
                                  <Button
                                    type="primary"
                                    onClick={async () => {
                                      //before submitting template switch the tab forcefully so that for both the tabs html will be generated.
                                      if (isSaveQrClicked && isTabSwitched) {
                                        setShowWarningModal(true);
                                      } else {
                                        if (currentTab) {
                                          setCurrentTab(
                                            currentTab === 'front'
                                              ? 'back'
                                              : 'front'
                                          );
                                        }
                                        await wait(2000);

                                        if (frontNode && backNode) {
                                          saveTemplate(frontNode, backNode);
                                        }
                                      }
                                    }}
                                    loading={savingTemplate}
                                    disabled={!frontNode || !backNode}
                                  >
                                    Save Template
                                  </Button>
                                )}
                              </>
                            }
                          >
                            <Tabs.TabPane tab="Create Template" key="1">
                              <DesignCenter
                                orderUniqueId={set?.orderUniqueId}
                                uploadInProgress={uploadInProgress}
                                setUploadInProgress={setUploadInProgress}
                                orgId={orgId}
                                set={set}
                                setCardData={setCardData}
                                frontImageUploading={frontImageUploading}
                                backgroundImageUploading={
                                  backgroundImageUploading
                                }
                                handleBackgroundImageUpload={
                                  handleBackgroundImageUpload
                                }
                                frontCardImage={frontCardImage}
                                backCardImage={backCardImage}
                                onFileInput={onFileInput}
                                sameBackgroundColor={sameBackgroundColor}
                                setSameBackgroundColor={setSameBackgroundColor}
                                backgroundColorFront={backgroundColorFront}
                                setFrontCardImage={setFrontCardImage}
                                setBackCardImage={setBackCardImage}
                                setBackgroundColorBack={setBackgroundColorBack}
                                setBackgroundColorFront={
                                  setBackgroundColorFront
                                }
                                backgroundColorBack={backgroundColorBack}
                                saveTemplate={saveTemplate}
                                currentTab={currentTab}
                                setCurrentTab={setCurrentTab}
                                cardData={cardData}
                                hideField={hideField}
                                setHideField={setHideField}
                                newField={newField}
                                setNewField={setNewField}
                                front={front}
                                setFront={setFront}
                                back={back}
                                setBack={setBack}
                                fontData={fontData}
                                setFrontImageUploading={setFrontImageUploading}
                                setBackgroundImageUploading={
                                  setBackgroundImageUploading
                                }
                                frontNode={frontNode}
                                backNode={backNode}
                                isSaveQrClicked={isSaveQrClicked}
                                setIsTabSwitched={setIsTabSwitched}
                                activeUserTab={activeUserTab}
                                fetchingPreviousDesign={fetchingPreviousDesign}
                                isEditModeOn={isEditModeOn}
                                wait={wait}
                              />
                            </Tabs.TabPane>
                            <Tabs.TabPane tab="Set QR code" key="2">
                              {!showQrPositionSetting && (
                                <QrSettings
                                  set={set}
                                  showQrPositionSetting={showQrPositionSetting}
                                  setShowQrPositionSetting={
                                    setShowQrPositionSetting
                                  }
                                  base64Str={base64Str}
                                  setBase64Str={setBase64Str}
                                  qrConfig={qrConfig}
                                  setQrConfig={setQrConfig}
                                  qrPixel={qrConfig.qrPixelColor}
                                  qrSettingLoader={qrSettingLoader}
                                  setQrSettingLoader={setQrSettingLoader}
                                  roundedCorner={roundedCorner}
                                  setRoundedCorner={setRoundedCorner}
                                  showLogo={showLogo}
                                  setShowLogo={setShowLogo}
                                  isPreviousConfig={isPreviousConfig}
                                  qrX={qrX}
                                  setQrX={setQrX}
                                  qrY={qrY}
                                  setQrY={setQrY}
                                  qrRotation={qrRotation}
                                  setQrRotation={setQrRotation}
                                  setQrWidth={setQrWidth}
                                  setQrHeight={setQrHeight}
                                />
                              )}
                              {set?.cardType !== 'Mobilo Metal (promo)' &&
                              set?.cardType !== 'Mobilo Metal' &&
                              showQrPositionSetting ? (
                                <QrPosition
                                  type="designCentre"
                                  showQr={showQr}
                                  setShowQr={setShowQr}
                                  qrSide={qrSide}
                                  setQrSide={setQrSide}
                                  backgroundColorFront={backgroundColorFront}
                                  backgroundColorBack={backgroundColorBack}
                                  frontCardImage={frontCardImage}
                                  backCardImage={backCardImage}
                                  qrWidth={qrWidth}
                                  qrHeight={qrHeight}
                                  qrX={qrX}
                                  qrY={qrY}
                                  setQrX={setQrX}
                                  setQrY={setQrY}
                                  setQrWidth={setQrWidth}
                                  setQrHeight={setQrHeight}
                                  base64Str={base64Str}
                                  qrRotation={qrRotation}
                                  setQrRotation={setQrRotation}
                                  layout={layout}
                                  layout2={layout2}
                                  Slider={Slider}
                                  rotationMarks={rotationMarks}
                                  cardDesign={cardDesign}
                                  frontNode={frontNode}
                                  setFrontNode={setFrontNode}
                                  backNode={backNode}
                                  setBackNode={setBackNode}
                                  fontFace={fontFace}
                                  setIsSaveQrClicked={setIsSaveQrClicked}
                                />
                              ) : null}
                            </Tabs.TabPane>
                          </Tabs>
                        )}
                      </>
                    </TabPane>
                  );
                })
              : null}
          </Tabs>
        </Card>
        {!showQrPositionSetting &&
        designExperience === LEGACY_DESIGN_EXPERIENCE ? (
          <QrSettings
            set={set}
            showQrPositionSetting={showQrPositionSetting}
            setShowQrPositionSetting={setShowQrPositionSetting}
            base64Str={base64Str}
            setBase64Str={setBase64Str}
            qrConfig={qrConfig}
            setQrConfig={setQrConfig}
            qrPixel={qrConfig?.qrPixelColor}
            qrSettingLoader={qrSettingLoader}
            setQrSettingLoader={setQrSettingLoader}
            showLogo={showLogo}
            setShowLogo={setShowLogo}
            isPreviousConfig={isPreviousConfig}
            setQrX={setQrX}
            setQrY={setQrY}
            setQrRotation={setQrRotation}
            setQrWidth={setQrWidth}
            setQrHeight={setQrHeight}
          />
        ) : (
          ''
        )}
        {set?.cardType !== 'Mobilo Metal (promo)' &&
        set?.cardType !== 'Mobilo Metal' &&
        showQrPositionSetting &&
        designExperience === LEGACY_DESIGN_EXPERIENCE ? (
          <QrPosition
            type={LEGACY_DESIGN_EXPERIENCE}
            showQr={showQr}
            setShowQr={setShowQr}
            qrSide={qrSide}
            setQrSide={setQrSide}
            backgroundColorFront={backgroundColorFront}
            backgroundColorBack={backgroundColorBack}
            frontCardImage={frontCardImage}
            backCardImage={backCardImage}
            qrWidth={qrWidth}
            qrHeight={qrHeight}
            qrX={qrX}
            qrY={qrY}
            setQrX={setQrX}
            setQrY={setQrY}
            setQrWidth={setQrWidth}
            setQrHeight={setQrHeight}
            base64Str={base64Str}
            qrRotation={qrRotation}
            setQrRotation={setQrRotation}
            layout={layout}
            layout2={layout2}
            Slider={Slider}
            rotationMarks={rotationMarks}
            cardDesign={cardDesign}
          />
        ) : null}
        {showQrPositionSetting && (
          <>
            <Divider />
            <Card size="small" title="Leave a Message">
              <Input.TextArea
                value={note}
                onChange={(e) => setNote(e?.target?.value)}
              ></Input.TextArea>
            </Card>
          </>
        )}
      </Card>
    </>
  );
}

export default withRouter(AddDesign);
