import { catchErrorInSentry } from '../common/utils';
import { firebase } from '../firebase';

export const getNewMessageIndicator = (
  id,
  userId,
  setFlag,
  setActive,
  setLoading
) => {
  firebase
    .firestore()
    .collection('orders')
    .doc(id)
    .collection('notes')
    .get()
    .then((notes) => {
      if (notes.docs.length) {
        setActive(true);
      } else {
        setActive(false);
      }
      setLoading(false);
    })
    .catch((err) => {
      catchErrorInSentry(err);
      console.log(err);
    });
  firebase
    .firestore()
    .collection('orders')
    .doc(id)
    .get()
    .then((order) => {
      if (userId) {
        firebase
          .firestore()
          .collection(`orders/${id}/lastSeen`)
          .doc(userId)
          .get()
          .then((lastSeen) => {
            const lastSeenTime = lastSeen.data()
              ? lastSeen.data().time.seconds
              : 0;
            const messageTime = order.data().lastMessage
              ? order.data().lastMessage.seconds
              : 0;
            if (lastSeenTime < messageTime) {
              setFlag(true);
            } else {
              setFlag(false);
            }
            setLoading(false);
          });
      }
    })
    .catch((err) => {
      catchErrorInSentry(err);
      console.log(err);
    });
};
