import React, { useState } from 'react';
import { Button, Modal, Form, Select } from 'antd';
import { firebase } from '../../../firebase';
import { EditOutlined } from '@ant-design/icons';
import { COUNTRIES, CARD_TYPE_NAME } from '../../../common/constant';
import { catchErrorInSentry } from '../../../common/utils';

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 }
};
const tailLayout = {
  wrapperCol: { offset: 8, span: 16 }
};

function EditAssign({ record }) {
  const [visible, setVisible] = useState(false);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (event) => {
    const { cardType, countries } = event;
    setLoading(true);
    try {
      await firebase.firestore().collection('vendor').doc(record?.id).update({
        cardType,
        countries
      });
      setVisible(false);
      setLoading(false);
    } catch (error) {
      catchErrorInSentry(error);
      alert(error);
    }
  };

  const handleSelectAllCountries = (value) => {
    if (value?.length && value?.includes('all')) {
      if (value?.length === COUNTRIES?.length + 1) {
        form.setFieldsValue({ countries: [] });
        return;
      }
      const values = [];
      COUNTRIES.map((country) => values.push(country?.code));
      form.setFieldsValue({ countries: values });
      return;
    }
    form.setFieldsValue({ countries: value });
    return;
  };

  const showModal = () => {
    setVisible(true);
  };

  const cardTypeOptions = [];
  CARD_TYPE_NAME.map((cardType) => {
    cardTypeOptions.push(
      <Select.Option key={cardType}>{cardType}</Select.Option>
    );
  });
  const countryOptions = [];
  COUNTRIES.map((country) => {
    countryOptions.push(
      <Select.Option key={country?.code}>{country?.name}</Select.Option>
    );
  });

  return (
    <>
      <Button type="text" icon={<EditOutlined />} onClick={() => showModal()} />

      <Modal
        title={record?.name}
        visible={visible}
        footer={null}
        onCancel={() => setVisible(false)}
      >
        <Form
          {...layout}
          form={form}
          name="basic"
          onFinish={handleSubmit}
          initialValues={record}
        >
          <Form.Item
            label="Card Type"
            name="cardType"
            rules={[{ required: true, message: 'Please select card types!' }]}
          >
            <Select mode="multiple" placeholder="Please select">
              {cardTypeOptions}
            </Select>
          </Form.Item>
          <Form.Item
            label="Countries"
            name="countries"
            rules={[{ required: true, message: 'Please select Countries!' }]}
          >
            <Select
              mode="multiple"
              placeholder="Please select"
              showSearch
              optionFilterProp="children"
              onChange={handleSelectAllCountries}
              maxTagCount={5}
            >
              <Select.Option key="all" value="all">
                ---SELECT ALL---
              </Select.Option>

              {countryOptions}
            </Select>
          </Form.Item>
          <Form.Item {...tailLayout}>
            <Button type="primary" loading={loading} htmlType="submit">
              Submit
            </Button>
            <Button
              type="default"
              onClick={() => {
                form.resetFields();
                setVisible(false);
              }}
              style={{ marginLeft: '1em' }}
            >
              Cancel
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}

export default EditAssign;
