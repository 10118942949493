import React, { useState, useEffect } from 'react';
import { useParams, Link, withRouter, useHistory } from 'react-router-dom';

import { Typography, Button, Table, Card, Space } from 'antd';
import { LeftOutlined } from '@ant-design/icons';

import { lowerCase, capitalize } from 'lodash';
import { firebase } from '../../../firebase';
import Loader from '../../Loader/Loader';
import { generateDate } from '../../../utils/generateDate';
import { catchErrorInSentry } from '../../../common/utils';

function OrderSets() {
  let { id } = useParams();
  const [sets, setSets] = useState([]);
  const [historyData, setHistoryData] = useState([]);
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const ref = firebase
    .firestore()
    .collection('sets')
    .where('orderUniqueId', '==', id);

  const orderRef = firebase
    .firestore()
    .collection('orders')
    .where('orderUniqueId', '==', id);

  const columns = [
    {
      title: 'ID',
      dataIndex: 'setUniqueId',
      key: 'setUniqueId',
      render: (text) => {
        return <Link to={`/v2-orders/${id}/${text}`}>{text}</Link>;
      }
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (text) => {
        if (text)
          if (text?.length)
            return (
              <Typography.Text>{text?.replace(/_/g, ' ')}</Typography.Text>
            );
      }
    },
    {
      title: 'Owner email',
      dataIndex: 'owner',
      key: 'email'
    },
    {
      title: 'No. of cards',
      dataIndex: 'quantity',
      key: 'quantity'
    }
  ];

  const historyColumns = [
    {
      title: '',
      dataIndex: 'id',
      key: 'id',
      render: (text, record, index) => index + 1
    },
    {
      title: 'Vendor',
      dataIndex: 'vendor',
      key: 'vendor'
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (text) => capitalize(lowerCase(text))
    },
    {
      title: 'Current Action',
      dataIndex: 'current_action',
      key: 'current_action',
      render: (text) => capitalize(lowerCase(text))
    },
    {
      title: 'Time Required',
      dataIndex: 'time_required',
      key: 'time_required'
    },
    {
      title: 'Last Updated At',
      dataIndex: 'recorded_at',
      key: 'recorded_at',
      render: (text) => {
        return <div>{generateDate(text)}</div>;
      },
      sorter: (a, b) => a?.recorded_at?.seconds - b?.recorded_at?.seconds,
      sortDirections: ['descend', 'ascend'],
      defaultSortOrder: 'descend'
    }
  ];

  function getSet() {
    setLoading(true);
    ref.onSnapshot((querySnapshot) => {
      const items = [];
      querySnapshot.forEach((set) => {
        items.push(set?.data());
      });
      setSets(items);
      setLoading(false);
    });
  }

  async function getOrderHistory() {
    const historyArr = [];
    try {
      const order = await orderRef.get();
      for (const o of order.docs) {
        const history = await firebase
          .firestore()
          .collection('orders')
          .doc(o?.id)
          .collection('order_history')
          .get();
        for (const h of history?.docs) {
          historyArr.push(h?.data());
        }
      }
      setHistoryData(historyArr);
    } catch (e) {
      catchErrorInSentry(e);
      console.log(e);
    }
  }
  useEffect(() => {
    getSet();
    getOrderHistory();
  }, []);
  if (loading) {
    return <Loader />;
  }

  return (
    <Card
      title={
        <Space>
          <Button
            onClick={() => {
              history.goBack();
            }}
            type="text"
            icon={<LeftOutlined />}
          />
          <Typography.Title style={{ margin: 0 }} level={3}>
            {id}
          </Typography.Title>
        </Space>
      }
    >
      <Card title="Sets" size="large" style={{ overflow: 'auto' }}>
        <Table dataSource={sets} columns={columns} pagination={false} />
      </Card>
      <Card title="Order History" size="large" style={{ overflow: 'auto' }}>
        <Table
          dataSource={historyData}
          columns={historyColumns}
          pagination={false}
        />
      </Card>
    </Card>
  );
}

export default withRouter(OrderSets);
