import moment from 'moment';
import { REGEX, RESOLUTION_FACTOR } from './constant';
import hasIn from 'lodash/hasIn';
import * as Sentry from '@sentry/browser';

const formatDate = (date) => {
  const localDate = moment(date).format('MM/DD/YYYY');
  return localDate;
};
export default formatDate;

export const formValidatorRules = {
  required: {
    required: true,
    message: 'This field is required'
  },
  letters: {
    pattern: REGEX.LETTERS,
    message: 'Please use letters only'
  },
  email: {
    type: 'email',
    message: 'The input is not valid E-mail!'
  },
  media: {
    required: true,
    message: 'Please upload media file'
  },
  name: {
    pattern: REGEX.NAME,
    message: 'Please use letters, special characters or spaces'
  },
  zipcode: {
    pattern: REGEX.ZIPCODE,
    message: 'Please use letters, numbers or spaces'
  },
  phoneNumber: {
    pattern: REGEX.PHONE,
    message: 'Please use numbers, dashes, and periods.'
  },
  number: () => ({
    validator(rule, value) {
      if (!value) {
        return Promise.resolve();
      }
      if (!Number(value) || !/^\d+$/.test(Number(value))) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise.reject('Please use only numbers or +');
      }
      return Promise.resolve();
    }
  })
};

export const checkFieldInObject = (front, back, fieldName, currentTab) => {
  if (currentTab === 'front') {
    return hasIn(front, fieldName);
  }
  return hasIn(back, fieldName);
};

export const getAllCoordinates = (side, setSide, field, currentTab, x, y) => {
  const fieldElement = document?.getElementById(field);
  let card;
  if (currentTab === 'front') {
    card = document?.getElementById('front-inner');
  } else {
    card = document?.getElementById('back-inner');
  }
  const fieldPosition = fieldElement?.getBoundingClientRect();
  const cardPosition = card?.getBoundingClientRect();
  const topLeftX = fieldPosition?.left - cardPosition?.left;
  const topLeftY = fieldPosition?.top - cardPosition?.top;
  const bottomLeftX = fieldPosition?.left - cardPosition?.left;
  const bottomLeftY = fieldPosition?.bottom - cardPosition?.top;
  const topRightX =
    fieldPosition?.left - cardPosition?.left + fieldPosition?.width;
  const topRightY = fieldPosition?.top - cardPosition?.top;
  const bottomRightX =
    fieldPosition?.left - cardPosition?.left + fieldPosition?.width;
  const bottomRightY =
    fieldPosition?.top - cardPosition?.top + fieldPosition?.height;
  if (fieldPosition) {
    if (currentTab === 'front') {
      switch (field) {
        case 'customField-front':
          return setSide({
            ...side,
            customField: {
              ...side?.customField,
              topLeft: {
                x: topLeftX * RESOLUTION_FACTOR,
                y: topLeftY * RESOLUTION_FACTOR
              },
              bottomLeft: {
                x: bottomLeftX * RESOLUTION_FACTOR,
                y: bottomLeftY * RESOLUTION_FACTOR
              },
              topRight: {
                x: topRightX * RESOLUTION_FACTOR,
                y: topRightY * RESOLUTION_FACTOR
              },
              bottomRight: {
                x: bottomRightX * RESOLUTION_FACTOR,
                y: bottomRightY * RESOLUTION_FACTOR
              },
              x: x * RESOLUTION_FACTOR,
              y: y * RESOLUTION_FACTOR
            }
          });
        case 'fullName-front':
          return setSide({
            ...side,
            fullName: {
              ...side?.fullName,
              topLeft: {
                x: topLeftX * RESOLUTION_FACTOR,
                y: topLeftY * RESOLUTION_FACTOR
              },
              bottomLeft: {
                x: bottomLeftX * RESOLUTION_FACTOR,
                y: bottomLeftY * RESOLUTION_FACTOR
              },
              topRight: {
                x: topRightX * RESOLUTION_FACTOR,
                y: topRightY * RESOLUTION_FACTOR
              },
              bottomRight: {
                x: bottomRightX * RESOLUTION_FACTOR,
                y: bottomRightY * RESOLUTION_FACTOR
              },
              x: x * RESOLUTION_FACTOR,
              y: y * RESOLUTION_FACTOR
            }
          });

        case 'firstName-front':
          return setSide({
            ...side,
            firstName: {
              ...side?.firstName,
              topLeft: {
                x: topLeftX * RESOLUTION_FACTOR,
                y: topLeftY * RESOLUTION_FACTOR
              },
              bottomLeft: {
                x: bottomLeftX * RESOLUTION_FACTOR,
                y: bottomLeftY * RESOLUTION_FACTOR
              },
              topRight: {
                x: topRightX * RESOLUTION_FACTOR,
                y: topRightY * RESOLUTION_FACTOR
              },
              bottomRight: {
                x: bottomRightX * RESOLUTION_FACTOR,
                y: bottomRightY * RESOLUTION_FACTOR
              },
              x: x * RESOLUTION_FACTOR,
              y: y * RESOLUTION_FACTOR
            }
          });
        case 'lastName-front':
          return setSide({
            ...side,
            lastName: {
              ...side?.lastName,
              topLeft: {
                x: topLeftX * RESOLUTION_FACTOR,
                y: topLeftY * RESOLUTION_FACTOR
              },
              bottomLeft: {
                x: bottomLeftX * RESOLUTION_FACTOR,
                y: bottomLeftY * RESOLUTION_FACTOR
              },
              topRight: {
                x: topRightX * RESOLUTION_FACTOR,
                y: topRightY * RESOLUTION_FACTOR
              },
              bottomRight: {
                x: bottomRightX * RESOLUTION_FACTOR,
                y: bottomRightY * RESOLUTION_FACTOR
              },
              x: x * RESOLUTION_FACTOR,
              y: y * RESOLUTION_FACTOR
            }
          });
        case 'email-front':
          return setSide({
            ...side,
            email: {
              ...side?.email,
              topLeft: {
                x: topLeftX * RESOLUTION_FACTOR,
                y: topLeftY * RESOLUTION_FACTOR
              },
              bottomLeft: {
                x: bottomLeftX * RESOLUTION_FACTOR,
                y: bottomLeftY * RESOLUTION_FACTOR
              },
              topRight: {
                x: topRightX * RESOLUTION_FACTOR,
                y: topRightY * RESOLUTION_FACTOR
              },
              bottomRight: {
                x: bottomRightX * RESOLUTION_FACTOR,
                y: bottomRightY * RESOLUTION_FACTOR
              },
              x: x * RESOLUTION_FACTOR,
              y: y * RESOLUTION_FACTOR
            }
          });
        case 'mobilePhone-front':
          return setSide({
            ...side,
            mobilePhone: {
              ...side?.mobilePhone,
              topLeft: {
                x: topLeftX * RESOLUTION_FACTOR,
                y: topLeftY * RESOLUTION_FACTOR
              },
              bottomLeft: {
                x: bottomLeftX * RESOLUTION_FACTOR,
                y: bottomLeftY * RESOLUTION_FACTOR
              },
              topRight: {
                x: topRightX * RESOLUTION_FACTOR,
                y: topRightY * RESOLUTION_FACTOR
              },
              bottomRight: {
                x: bottomRightX * RESOLUTION_FACTOR,
                y: bottomRightY * RESOLUTION_FACTOR
              },
              x: x * RESOLUTION_FACTOR,
              y: y * RESOLUTION_FACTOR
            }
          });
        case 'workPhone-front':
          return setSide({
            ...side,
            workPhone: {
              ...side?.workPhone,
              topLeft: {
                x: topLeftX * RESOLUTION_FACTOR,
                y: topLeftY * RESOLUTION_FACTOR
              },
              bottomLeft: {
                x: bottomLeftX * RESOLUTION_FACTOR,
                y: bottomLeftY * RESOLUTION_FACTOR
              },
              topRight: {
                x: topRightX * RESOLUTION_FACTOR,
                y: topRightY * RESOLUTION_FACTOR
              },
              bottomRight: {
                x: bottomRightX * RESOLUTION_FACTOR,
                y: bottomRightY * RESOLUTION_FACTOR
              },
              x: x * RESOLUTION_FACTOR,
              y: y * RESOLUTION_FACTOR
            }
          });
        case 'company-front':
          return setSide({
            ...side,
            company: {
              ...side?.company,
              topLeft: {
                x: topLeftX * RESOLUTION_FACTOR,
                y: topLeftY * RESOLUTION_FACTOR
              },
              bottomLeft: {
                x: bottomLeftX * RESOLUTION_FACTOR,
                y: bottomLeftY * RESOLUTION_FACTOR
              },
              topRight: {
                x: topRightX * RESOLUTION_FACTOR,
                y: topRightY * RESOLUTION_FACTOR
              },
              bottomRight: {
                x: bottomRightX * RESOLUTION_FACTOR,
                y: bottomRightY * RESOLUTION_FACTOR
              },
              x: x * RESOLUTION_FACTOR,
              y: y * RESOLUTION_FACTOR
            }
          });
        case 'title-front':
          return setSide({
            ...side,
            title: {
              ...side?.title,
              topLeft: {
                x: topLeftX * RESOLUTION_FACTOR,
                y: topLeftY * RESOLUTION_FACTOR
              },
              bottomLeft: {
                x: bottomLeftX * RESOLUTION_FACTOR,
                y: bottomLeftY * RESOLUTION_FACTOR
              },
              topRight: {
                x: topRightX * RESOLUTION_FACTOR,
                y: topRightY * RESOLUTION_FACTOR
              },
              bottomRight: {
                x: bottomRightX * RESOLUTION_FACTOR,
                y: bottomRightY * RESOLUTION_FACTOR
              },
              x: x * RESOLUTION_FACTOR,
              y: y * RESOLUTION_FACTOR
            }
          });
        default:
          break;
      }
    } else {
      switch (field) {
        case 'customField-back':
          return setSide({
            ...side,
            customField: {
              ...side?.customField,
              topLeft: {
                x: topLeftX * RESOLUTION_FACTOR,
                y: topLeftY * RESOLUTION_FACTOR
              },
              bottomLeft: {
                x: bottomLeftX * RESOLUTION_FACTOR,
                y: bottomLeftY * RESOLUTION_FACTOR
              },
              topRight: {
                x: topRightX * RESOLUTION_FACTOR,
                y: topRightY * RESOLUTION_FACTOR
              },
              bottomRight: {
                x: bottomRightX * RESOLUTION_FACTOR,
                y: bottomRightY * RESOLUTION_FACTOR
              },
              x: x * RESOLUTION_FACTOR,
              y: y * RESOLUTION_FACTOR
            }
          });
        case 'fullName-back':
          return setSide({
            ...side,
            fullName: {
              ...side?.fullName,
              topLeft: {
                x: topLeftX * RESOLUTION_FACTOR,
                y: topLeftY * RESOLUTION_FACTOR
              },
              bottomLeft: {
                x: bottomLeftX * RESOLUTION_FACTOR,
                y: bottomLeftY * RESOLUTION_FACTOR
              },
              topRight: {
                x: topRightX * RESOLUTION_FACTOR,
                y: topRightY * RESOLUTION_FACTOR
              },
              bottomRight: {
                x: bottomRightX * RESOLUTION_FACTOR,
                y: bottomRightY * RESOLUTION_FACTOR
              },
              x: x * RESOLUTION_FACTOR,
              y: y * RESOLUTION_FACTOR
            }
          });

        case 'firstName-back':
          return setSide({
            ...side,
            firstName: {
              ...side?.firstName,
              topLeft: {
                x: topLeftX * RESOLUTION_FACTOR,
                y: topLeftY * RESOLUTION_FACTOR
              },
              bottomLeft: {
                x: bottomLeftX * RESOLUTION_FACTOR,
                y: bottomLeftY * RESOLUTION_FACTOR
              },
              topRight: {
                x: topRightX * RESOLUTION_FACTOR,
                y: topRightY * RESOLUTION_FACTOR
              },
              bottomRight: {
                x: bottomRightX * RESOLUTION_FACTOR,
                y: bottomRightY * RESOLUTION_FACTOR
              },
              x: x * RESOLUTION_FACTOR,
              y: y * RESOLUTION_FACTOR
            }
          });
        case 'lastName-back':
          return setSide({
            ...side,
            lastName: {
              ...side?.lastName,
              topLeft: {
                x: topLeftX * RESOLUTION_FACTOR,
                y: topLeftY * RESOLUTION_FACTOR
              },
              bottomLeft: {
                x: bottomLeftX * RESOLUTION_FACTOR,
                y: bottomLeftY * RESOLUTION_FACTOR
              },
              topRight: {
                x: topRightX * RESOLUTION_FACTOR,
                y: topRightY * RESOLUTION_FACTOR
              },
              bottomRight: {
                x: bottomRightX * RESOLUTION_FACTOR,
                y: bottomRightY * RESOLUTION_FACTOR
              },
              x: x * RESOLUTION_FACTOR,
              y: y * RESOLUTION_FACTOR
            }
          });
        case 'email-back':
          return setSide({
            ...side,
            email: {
              ...side?.email,
              topLeft: {
                x: topLeftX * RESOLUTION_FACTOR,
                y: topLeftY * RESOLUTION_FACTOR
              },
              bottomLeft: {
                x: bottomLeftX * RESOLUTION_FACTOR,
                y: bottomLeftY * RESOLUTION_FACTOR
              },
              topRight: {
                x: topRightX * RESOLUTION_FACTOR,
                y: topRightY * RESOLUTION_FACTOR
              },
              bottomRight: {
                x: bottomRightX * RESOLUTION_FACTOR,
                y: bottomRightY * RESOLUTION_FACTOR
              },
              x: x * RESOLUTION_FACTOR,
              y: y * RESOLUTION_FACTOR
            }
          });
        case 'mobilePhone-back':
          return setSide({
            ...side,
            mobilePhone: {
              ...side?.mobilePhone,
              topLeft: {
                x: topLeftX * RESOLUTION_FACTOR,
                y: topLeftY * RESOLUTION_FACTOR
              },
              bottomLeft: {
                x: bottomLeftX * RESOLUTION_FACTOR,
                y: bottomLeftY * RESOLUTION_FACTOR
              },
              topRight: {
                x: topRightX * RESOLUTION_FACTOR,
                y: topRightY * RESOLUTION_FACTOR
              },
              bottomRight: {
                x: bottomRightX * RESOLUTION_FACTOR,
                y: bottomRightY * RESOLUTION_FACTOR
              },
              x: x * RESOLUTION_FACTOR,
              y: y * RESOLUTION_FACTOR
            }
          });
        case 'workPhone-back':
          return setSide({
            ...side,
            workPhone: {
              ...side?.workPhone,
              topLeft: {
                x: topLeftX * RESOLUTION_FACTOR,
                y: topLeftY * RESOLUTION_FACTOR
              },
              bottomLeft: {
                x: bottomLeftX * RESOLUTION_FACTOR,
                y: bottomLeftY * RESOLUTION_FACTOR
              },
              topRight: {
                x: topRightX * RESOLUTION_FACTOR,
                y: topRightY * RESOLUTION_FACTOR
              },
              bottomRight: {
                x: bottomRightX * RESOLUTION_FACTOR,
                y: bottomRightY * RESOLUTION_FACTOR
              },
              x: x * RESOLUTION_FACTOR,
              y: y * RESOLUTION_FACTOR
            }
          });
        case 'company-back':
          return setSide({
            ...side,
            company: {
              ...side?.company,
              topLeft: {
                x: topLeftX * RESOLUTION_FACTOR,
                y: topLeftY * RESOLUTION_FACTOR
              },
              bottomLeft: {
                x: bottomLeftX * RESOLUTION_FACTOR,
                y: bottomLeftY * RESOLUTION_FACTOR
              },
              topRight: {
                x: topRightX * RESOLUTION_FACTOR,
                y: topRightY * RESOLUTION_FACTOR
              },
              bottomRight: {
                x: bottomRightX * RESOLUTION_FACTOR,
                y: bottomRightY * RESOLUTION_FACTOR
              },
              x: x * RESOLUTION_FACTOR,
              y: y * RESOLUTION_FACTOR
            }
          });
        case 'title-back':
          return setSide({
            ...side,
            title: {
              ...side?.title,
              topLeft: {
                x: topLeftX * RESOLUTION_FACTOR,
                y: topLeftY * RESOLUTION_FACTOR
              },
              bottomLeft: {
                x: bottomLeftX * RESOLUTION_FACTOR,
                y: bottomLeftY * RESOLUTION_FACTOR
              },
              topRight: {
                x: topRightX * RESOLUTION_FACTOR,
                y: topRightY * RESOLUTION_FACTOR
              },
              bottomRight: {
                x: bottomRightX * RESOLUTION_FACTOR,
                y: bottomRightY * RESOLUTION_FACTOR
              },
              x: x * RESOLUTION_FACTOR,
              y: y * RESOLUTION_FACTOR
            }
          });
        default:
          break;
      }
    }
  }
};

export const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth'
  });
};

export const catchErrorInSentry = (error) => {
  if (error?.message !== 'Missing or insufficient permissions.') {
    Sentry.captureException(new Error(`Message: ${error}`));
  }
};
