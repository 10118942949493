import React, { useEffect, useState, useContext, useRef } from 'react';
import { firebase } from '../../../../firebase';
import {
  Table,
  Typography,
  Card,
  Spin,
  Space,
  Tooltip,
  Badge,
  Button,
  Row,
  Col,
  Select,
  Tag
} from 'antd';
import { AuthContext } from '../../../../Auth';
import { withRouter } from 'react-router';
import { BatchPopModal, PopModal } from '../Popconfirm';
import { getNewMessageIndicator } from '../../../../utils/getNewMessageIndicator';
import { Link } from 'react-router-dom';
import { MessageOutlined, MessageTwoTone } from '@ant-design/icons';
import { LIMIT_PRIMARY } from '../../../../common/constant';
import _isEmpty from 'lodash/isEmpty';
import { UpCircleOutlined } from '@ant-design/icons';
import { scrollToTop } from '../../../../common/utils';
import { getColumnSearchPropsV2 } from '../../../../utils/searchV2';
import { handleTableChange } from '../functions/handleTableChange';
import { handleOrderLengthAndScrollOffset } from '../../../../utils/handleOrderLengthAndScrollOffset';
import { AppContext } from '../../../../AppContext';
import api from '../../../../common/api';
import AddressForm from './AddressForm';
import getSymbolFromCurrency from 'currency-symbol-map';
import './inWarehouse.css';
import { generateDate } from '../../../../utils/generateDate';

const { Option } = Select;

function ShipInWarehouse() {
  const { data } = useContext(AuthContext);
  const [orders, setOrders] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);
  const [hasMore, setHasMore] = useState(true);
  const [offset, setOffset] = useState();
  const [scrollTopVisible, setScrollTopVisible] = useState(false);
  const [sortColumn, setSortColumn] = useState('createdAt');
  const [selectedOrderType, setSelectedOrderType] = useState('system');
  const [variation, setVariation] = useState([]);
  const [sorterInfo, setSorterInfo] = useState();
  const [sorting, setSorting] = useState('desc');
  const [loading, setLoading] = useState(true);
  const [selectedRows, setSelectedRows] = useState([]);
  const [batchLoading, setBatchLoading] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [totalOrders, setTotalOrders] = useState(0);
  const [totalCards, setTotalCards] = useState(0);
  const [selectedRate, setSelectedRate] = useState({});
  const [shippingService, setShippingService] = useState('');
  const [isEditAddress, setIsEditAddress] = useState('');
  const {
    dispatch,
    state: {
      totalLoadedOrders,
      scrollOffset,
      searchTerm,
      searchedField,
      orderType
    }
  } = useContext(AppContext);

  let ref;
  if (data?.vendorId) {
    ref = firebase
      .firestore()
      .collection('orders')
      .where('state', '==', 'SHIPPING')
      .where('status', '==', 'IN_WAREHOUSE')
      .where('shippingVendorId', '==', data?.vendorId);
    if (searchedColumn && searchText) {
      if (
        searchedColumn === 'orderNumber' ||
        searchedColumn === 'orderUniqueId'
      ) {
        if (selectedOrderType === 'system') {
          ref = ref
            .orderBy(searchedColumn)
            .startAt(searchText?.toUpperCase())
            .endAt(searchText?.toUpperCase() + '\uf8ff');
        } else if (selectedOrderType === 'woocommerce') {
          ref = ref
            .orderBy('shippingAddress.order_number')
            .startAt(searchText?.toUpperCase())
            .endAt(searchText?.toUpperCase() + '\uf8ff');
        }
      } else if (sorting && sortColumn === 'updatedAt') {
        ref = ref
          .orderBy(searchedColumn)
          .orderBy(sortColumn, sorting)
          .startAt(searchText?.toLowerCase())
          .endAt(searchText?.toLowerCase() + '\uf8ff');
      } else {
        ref = ref
          .orderBy(searchedColumn)
          .startAt(searchText?.toLowerCase())
          .endAt(searchText?.toLowerCase() + '\uf8ff');
      }
    }
    if (variation?.length > 0) {
      ref = ref.where('cardVariation', 'in', variation);
    }
    if (!searchText) {
      ref = ref.orderBy(sortColumn, sorting);
    }
    ref = ref.limit(totalLoadedOrders || LIMIT_PRIMARY);
  }
  const wait = (time = 5000) =>
    new Promise((resolve) => setTimeout(resolve, time));

  const columns = [
    {
      title: 'ID',
      dataIndex: 'orderUniqueId',
      key: 'id',
      render: function OrderUniqueId(text, record) {
        const [newMessageFlag, setNewMessageFlag] = useState(false);
        const [spin, setSPin] = useState(true);
        const [active, setActive] = useState(false);

        getNewMessageIndicator(
          record?.docId,
          data?.userId,
          setNewMessageFlag,
          setActive,
          setSPin
        );
        if (spin) return <Spin />;

        return (
          <Space>
            <Typography.Text>{text}</Typography.Text>
            <Link
              to={`/chat/${text}`}
              onClick={() => {
                handleOrderLengthAndScrollOffset(
                  dispatch,
                  orders?.length,
                  window?.pageYOffset,
                  searchText,
                  searchedColumn,
                  selectedOrderType
                );
              }}
            >
              <Tooltip title="Internal Chat">
                <Badge dot={newMessageFlag}>
                  {active ? (
                    <MessageTwoTone
                      twoToneColor="#52c41a"
                      style={{ color: 'green', margin: 2 }}
                    />
                  ) : (
                    <MessageOutlined style={{ color: '#a0a0a0', margin: 2 }} />
                  )}
                </Badge>
              </Tooltip>
            </Link>
          </Space>
        );
      }
    },
    {
      title: 'Order Number',
      dataIndex: 'orderNumber',
      key: 'orderNumber',
      ...getColumnSearchPropsV2(
        'orderNumber',
        searchText,
        searchedColumn,
        setSearchText,
        setSearchedColumn,
        searchInput,
        false,
        setHasMore,
        setSelectedOrderType,
        selectedOrderType,
        'orders'
      )
    },
    {
      title: 'Customer Email',
      dataIndex: 'customerEmail',
      key: 'customerEmail'
    },
    {
      title: 'Customer Name(on address)',
      key: 'customerName',
      hidden: shippingService === 'SHIPPO',
      render: (text, record) => {
        return (
          <Typography.Text>
            {record?.shippingAddress?.first_name}{' '}
            {record?.shippingAddress?.last_name}
          </Typography.Text>
        );
      }
    },
    {
      title: 'Shipping Address',
      key: 'shippingAddress',
      hidden: shippingService === 'SHIPPO',
      render: (text, record) => {
        return (
          <Typography.Text>
            {record?.shippingAddress?.address_1},{' '}
            {record?.shippingAddress?.address_2},{' '}
            {record?.shippingAddress?.city}, {record?.shippingAddress?.state},{' '}
            {record?.shippingAddress?.country}
          </Typography.Text>
        );
      }
    },
    {
      title: 'Postal Code',
      key: 'postalCode',
      dataIndex: ['shippingAddress', 'postcode'],
      hidden: shippingService === 'SHIPPO'
    },
    {
      title: 'Company',
      key: 'company',
      dataIndex: ['shippingAddress', 'company'],
      hidden: shippingService === 'SHIPPO'
    },
    {
      title: 'Last Updated At',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      hidden: shippingService === 'SHIPPO',
      render: (text) => {
        return <div>{generateDate(text)}</div>;
      },
      sorter: true,
      sortOrder: sorterInfo?.columnKey === 'updatedAt' && sorterInfo?.order
    },
    {
      title: 'Add-ons',
      key: 'variation',
      dataIndex: 'cardVariation',
      hidden: shippingService === 'SHIPPO',
      filters: [
        { text: 'No accessories', value: 'Card only' },
        { text: '+ Key Fob', value: '+ Key Fob' },
        { text: '+ Smart Button', value: '+ Smart Button' },
        { text: '+ Key Fob + Smart Button', value: '+ Key Fob + Smart Button' }
      ],
      render: (text, record) => {
        const tags = [];
        try {
          const string = text?.toLowerCase();
          if (!string?.includes('card only')) {
            if (string?.includes('key fob'))
              tags.push({ text: '+ Key Fob', color: 'orange' });
            if (string?.includes('smart button'))
              tags.push({ text: '+ Smart Button', color: 'green' });
          }
        } catch {}
        return (
          <>
            {tags.map((tag) => {
              return (
                <Tag key={`${record?.id}-${tag?.text}`} color={tag?.color}>
                  {tag?.text}
                </Tag>
              );
            })}
          </>
        );
      }
    },
    {
      title: 'Shipping Address',
      dataIndex: 'shippingAddress',
      key: 'shippingAddress',
      hidden: shippingService === 'SEND_CLOUD',
      render: (text, record) => {
        return (
          <div className="shipping-address">
            <div>
              <p className="address-text">
                {record?.shippingAddress?.first_name}{' '}
                {record?.shippingAddress?.last_name}
              </p>
              <p className="address-text">
                {record?.shippingAddress?.address_1}{' '}
                {record?.shippingAddress?.address_2}
              </p>
              <p className="address-text">
                {record?.shippingAddress?.city},{' '}
                {record?.shippingAddress?.state}{' '}
                {record?.shippingAddress?.postcode}{' '}
                {record?.shippingAddress?.country}
              </p>
            </div>
            {shippingService === 'SHIPPO' && !record?.orderShippingDetails && (
              <AddressForm
                record={record}
                orderUniqueId={record?.orderUniqueId}
                setIsEditAddress={setIsEditAddress}
              />
            )}
          </div>
        );
      }
    },
    {
      title: 'Rates',
      dataIndex: 'rates',
      key: 'rates',
      hidden: shippingService === 'SEND_CLOUD',
      render: function Rateslist(text, record) {
        const [rates, setRates] = useState([]);

        const fetchRateFromOrderUniqueId = async () => {
          const config = {
            method: 'GET',
            url: `${process.env.REACT_APP_API_GTW_URL}/v1/factory/shipping/get-shipping-rates?orderUniqueId=${record?.orderUniqueId}`
          };
          const response = await api(config);
          setRates(response?.data?.data);
          setIsEditAddress('');
        };

        useEffect(() => {
          if (record?.selectedRateObj) {
            setRates([record?.selectedRateObj]);
          } else {
            fetchRateFromOrderUniqueId();
          }
          if (
            isEditAddress?.length > 0 &&
            isEditAddress === record?.orderUniqueId
          ) {
            handleClear(isEditAddress);
          }
        }, [isEditAddress]);

        const handleSelectRate = async (e) => {
          let rateSelection = JSON?.parse(e);
          const config = {
            method: 'post',
            url: `${process.env.REACT_APP_API_GTW_URL}/v1/factory/shipping/select-rate-object`,
            data: {
              rateObject: rateSelection,
              orderUniqueId: record?.orderUniqueId
            }
          };
          await api(config);
          setSelectedRate(rateSelection);
        };
        const handleClear = async (orderUniqueId) => {
          const config = {
            method: 'post',
            url: `${process.env.REACT_APP_API_GTW_URL}/v1/factory/shipping/clear-rate-object`,
            data: {
              orderUniqueId: record?.orderUniqueId || orderUniqueId
            }
          };
          await api(config);
          setRates([]);
          fetchRateFromOrderUniqueId();
        };

        return !rates?.length > 0 ? (
          <Spin />
        ) : (
          <Select
            placeholder="Please select rate"
            showArrow={false}
            onSelect={(e) => {
              handleSelectRate(e);
            }}
            disabled={record?.orderShippingDetails}
            className="rate-select"
            defaultValue={record?.selectedRateObj && JSON?.stringify(rates[0])}
            allowClear={!record?.orderShippingDetails}
            onClear={(e) => {
              handleClear();
            }}
          >
            {rates?.map((item) => {
              return (
                <Option value={JSON?.stringify(item)} className="rates">
                  <div className="rate-options">
                    <Row align="middle">
                      <Col span={4}>
                        <img
                          src={item?.serviceLogo}
                          alt="serviece logo"
                          className="service-logo"
                        ></img>
                      </Col>
                      <Col span={20}>
                        <Row>
                          <Col span={20}>
                            <span
                              title={`${item?.provider} ${item?.service}`}
                              className="service-name"
                            >
                              {item?.provider} {item?.service}{' '}
                            </span>
                          </Col>
                          <Col span={4}>
                            <span>
                              {getSymbolFromCurrency(item?.currency)}
                              {item?.amount}
                            </span>
                          </Col>
                          <Col span={20}>
                            <span>Parcel</span>
                          </Col>
                          <Col span={4}>
                            <span>{item?.estimateDays} Days</span>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </div>
                </Option>
              );
            })}
          </Select>
        );
      }
    },
    {
      title: 'Buy & Print',
      dataIndex: 'buy_print',
      key: 'buy_print',
      hidden: shippingService === 'SEND_CLOUD',
      render: function BuyPrint(text, record) {
        const [labelGenerating, setLabelGenerating] = useState(false);

        const handleGenerateLabel = async () => {
          const config = {
            method: 'POST',
            url: `${process.env.REACT_APP_API_GTW_URL}/v1/factory/shipping/generate-label`,
            data: {
              rateObjectId:
                record?.selectedRateObj?.objectId || selectedRate?.objectId,
              orderUniqueId: record?.orderUniqueId
            }
          };
          await api(config);
          //Wait function is kept interntionally and can be removed later on (Reason : Go shippo API takes time to generate pdf so loader is kept for 10 sec for now
          setLabelGenerating(true);
          await wait(10000);
          setLabelGenerating(false);
        };
        const getLabelUrl = async () => {
          const config = {
            method: 'GET',
            url: `${process.env.REACT_APP_API_GTW_URL}/v1/factory/shipping/get-shipping-label?orderUniqueId=${record?.orderUniqueId}`
          };
          const labelData = await api(config);
          window.open(
            record?.orderShippingDetails?.labelUrl || labelData?.data?.data,
            '_blank'
          );
        };
        return (
          <Space>
            <Button
              onClick={handleGenerateLabel}
              type="primary"
              disabled={
                record?.orderShippingDetails || !record?.selectedRateObj
              }
            >
              Buy
            </Button>
            <Button
              disabled={!record?.orderShippingDetails}
              onClick={() => {
                getLabelUrl();
              }}
              loading={labelGenerating}
            >
              Print
            </Button>
          </Space>
        );
      }
    },
    {
      title: 'Action',
      dataIndex: 'status',
      key: 'status',
      render: function Action(text, record) {
        return (
          <Space size={0}>
            <PopModal
              record={record}
              firebase={firebase}
              status="PICKUP"
              state="SHIPPING"
              loggerState="Warehouse completed"
              getOrders={getOrders}
            />
          </Space>
        );
      }
    }
  ].filter((item) => !item.hidden);

  function getOrders() {
    setLoading(true);
    ref.onSnapshot((querySnapshot) => {
      const items = [];
      querySnapshot.docs.forEach((doc) => {
        let orderNumber = doc?.data()?.orderNumber;
        let address;
        if (doc?.data()?.shippingAddress?.order_number) {
          orderNumber = `${orderNumber} (${
            doc?.data()?.shippingAddress?.order_number
          })`;
        }
        items.push({
          ...doc?.data(),
          key: doc?.data()?.orderUniqueId,
          docId: doc?.id,
          customerEmail: doc?.data()?.user,
          orderNumber
        });
      });
      if (items?.length < LIMIT_PRIMARY) {
        setHasMore(false);
      }
      setOffset(querySnapshot?.docs[querySnapshot?.docs?.length - 1]);
      setOrders(items);
      setLoading(false);
      window.scrollTo(0, scrollOffset);
      dispatch({ type: 'SET_TOTAL_ORDERS', data: LIMIT_PRIMARY });
      dispatch({ type: 'SET_SCROLL_OFFSET', data: 0 });
      dispatch({ type: 'SET_SEARCH_TEXT', data: '' });
      dispatch({ type: 'SET_SEARCHED_COLUMN', data: '' });
      dispatch({ type: 'SET_ORDER_TYPE', data: 'system' });
    });
  }
  const getNextOrder = async () => {
    try {
      ref = ref.startAfter(offset);
      ref.onSnapshot((querySnapshot) => {
        const items = [];
        querySnapshot.forEach((doc) => {
          let orderNumber = doc?.data()?.orderNumber;
          if (doc?.data()?.shippingAddress?.order_number) {
            orderNumber = `${orderNumber} (${
              doc?.data()?.shippingAddress?.order_number
            })`;
          }
          items.push({
            ...doc?.data(),
            key: doc?.data()?.orderUniqueId,
            docId: doc?.id,
            customerEmail: doc?.data()?.user,
            orderNumber
          });
        });
        if (items?.length < LIMIT_PRIMARY) {
          setHasMore(false);
        }
        setOffset(querySnapshot?.docs[querySnapshot?.docs?.length - 1]);
        setOrders([...orders, ...items]);
      });
    } catch (err) {
      console.log(err);
    }
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRowKeys(selectedRowKeys);
      setSelectedRows(selectedRows);
    }
  };

  const getCountOfOrderCards = async () => {
    const response = await api.get(
      `${process.env.REACT_APP_SERVER_URL_V2}/mat/order-count?status=IN_WAREHOUSE&vendor=shippingVendorId&state=SHIPPING`
    );
    if (response?.data) {
      setTotalOrders(response?.data?.data?.totalOrdersCount);
      setTotalCards(response?.data?.data?.totalCardsCount);
    }
  };
  const getVendorDetails = async () => {
    const vendorData = await firebase
      .firestore()
      .collection('vendor')
      .doc(data?.vendorId)
      .get();
    setShippingService(vendorData?.data()?.defaultShippingService ?? 'SHIPPO');
  };
  useEffect(() => {
    if (data?.vendorId) {
      getVendorDetails();
      getOrders();
    }
    getCountOfOrderCards();
    window.addEventListener('scroll', () => {
      setScrollTopVisible(window?.pageYOffset > 300);
    });
  }, [data, searchText, variation, sorting]);

  useEffect(() => {
    if (data?.vendorId) {
      if (searchTerm?.length > 0 && searchedField && orderType) {
        setSearchText(searchTerm);
        setSearchedColumn(searchedField);
        setSelectedOrderType(orderType);
      }
    }
  }, [data]);

  return (
    <Card
      title={
        <>
          <span className="mr-16">In warehouse</span>
          <Space direction="vertical">
            <span className="font-400 font-14">
              Order count : {totalOrders ?? '-'}
            </span>
            <span className="font-400 font-14">
              Card count : {totalCards ?? '-'}
            </span>
          </Space>
        </>
      }
      size="small"
      extra={
        <BatchPopModal
          record={selectedRows}
          firebase={firebase}
          status="IN_TRANSIT"
          state="SHIPPING"
          loggerState="Warehouse completed"
          setLoader={setBatchLoading}
          loader={batchLoading}
          getOrders={getOrders}
        />
      }
    >
      <Table
        rowSelection={rowSelection}
        dataSource={orders}
        size="small"
        columns={columns}
        pagination={false}
        onChange={(pagination, filters, sorter) => {
          handleTableChange(pagination, filters, sorter, {
            setVariation,
            setSorterInfo,
            setSorting,
            setSortColumn
          });
        }}
        loading={loading}
      />
      <Row>
        <Col offset={10} span={12}>
          <Button
            className="margin-t-16"
            onClick={() => {
              if (hasMore) {
                getNextOrder();
              }
            }}
            disabled={!hasMore}
          >
            Load More
          </Button>
        </Col>
        <Col span={2}>
          {scrollTopVisible && (
            <Button
              shape="circle"
              onClick={() => {
                scrollToTop();
              }}
              className="margin-t-16 scroll-top-btn"
            >
              <UpCircleOutlined />
            </Button>
          )}
        </Col>
      </Row>
    </Card>
  );
}

export default withRouter(ShipInWarehouse);
