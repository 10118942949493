import React, { useEffect, useState } from 'react';
import { useParams, Link, useHistory } from 'react-router-dom';

import { Button, Card, message, Space, Spin, Tabs, Typography } from 'antd';
import { LeftOutlined } from '@ant-design/icons';

import { firebase } from '../../../firebase';
import MembersTable from './MembersTable';
import CardsTable from './CardsTable';
import Settings from './Settings';
import AddMember from './AddMember';
import AddCards from './AddCards';
import Loader from '../../Loader/Loader';
import ManageLicense from './ManageLicense';
import api from '../../../common/api';

const { TabPane } = Tabs;
function SingleOrganisation() {
  const history = useHistory();
  const { id } = useParams();
  const [organisation, setOrganisation] = useState();
  const [loading, setLoading] = useState(false);
  const [downloadingCsv, setDownloadingCsv] = useState(false);
  const [exporting, setExporting] = useState(false);
  const [activeTab, setActiveTab] = useState('members');
  const ref = firebase.firestore().collection('organizations').doc(id);

  const getOrganisation = async () => {
    setLoading(true);
    const orgData = await ref.get();
    setOrganisation(orgData?.data());
    setLoading(false);
  };
  const enableDepartment = () => {
    ref.update({ ...organisation, enabledDepartment: true }).then(() => {
      message.success('Group feature enabled successfully!');
    });
  };
  const handleMemberCsv = async () => {
    try {
      setDownloadingCsv(true);
      const config = {
        method: 'get',
        url: `${process.env.REACT_APP_PDF_DOWNLOAD_URL}/mat/org/members-export?orgId=${id}`,
        headers: {
          secretKey: process.env.REACT_APP_PDF_FIX_SECRET
        }
      };
      const memberData = await api(config);
      if (memberData?.data) {
        message.success(memberData?.data?.message);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setDownloadingCsv(false);
    }
  };
  const handleExportQr = async () => {
    try {
      setExporting(true);
      const config = {
        method: 'get',
        url: `${process.env.REACT_APP_PDF_DOWNLOAD_URL}/mat/org/qr-export?orgId=${id}`,
        headers: {
          secretKey: process.env.REACT_APP_PDF_FIX_SECRET
        }
      };
      const qrData = await api(config);
      if (qrData?.data) {
        message.success(qrData?.data?.message);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setExporting(false);
    }
  };
  useEffect(() => {
    getOrganisation();
  }, []);
  if (loading) return <Loader />;
  return (
    <Card
      title={
        <Space>
          <Button
            type="text"
            icon={<LeftOutlined />}
            onClick={() => {
              if (history.length === 1) {
                history.push('/v2-organizations', { orgId: id });
              } else {
                history.goBack();
              }
            }}
          />
          <Typography.Title style={{ margin: 0 }} level={3}>
            {organisation?.name}
          </Typography.Title>
        </Space>
      }
      extra={
        <Space>
          {organisation?.enabledDepartment ? (
            <Link to={`/v2-organizations/${id}/groups`}>
              <Button type="primary">Go to Group</Button>
            </Link>
          ) : (
            <Button type="primary" onClick={enableDepartment}>
              Enable Group
            </Button>
          )}
          {organisation?.isNotLegacy && (
            <div>
              <ManageLicense orgId={id} />
            </div>
          )}
          {activeTab === 'members' ? (
            <AddMember orgId={id} />
          ) : (
            <AddCards orgId={id} />
          )}
          <Settings orgId={id} />
        </Space>
      }
    >
      <Tabs
        defaultActiveKey="1"
        size="large"
        type="card"
        onChange={(currentTab) => setActiveTab(currentTab)}
        tabBarExtraContent={
          <Space>
            <Button
              type="primary"
              onClick={() => {
                handleMemberCsv();
              }}
              loading={downloadingCsv}
            >
              Export Member .csv
            </Button>
            <Button
              type="primary"
              onClick={() => {
                handleExportQr();
              }}
              loading={exporting}
            >
              Export QR Codes
            </Button>
          </Space>
        }
      >
        <TabPane tab="Members" key="members">
          <MembersTable orgId={id} memberIds={organisation?.members} />
        </TabPane>
        <TabPane tab="Cards" key="cards">
          <CardsTable orgId={id} />
        </TabPane>
      </Tabs>
    </Card>
  );
}

export default SingleOrganisation;
