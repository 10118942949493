import { Button, Descriptions, Modal, Select, Form, Row, Col } from 'antd';
import React from 'react';
import { useState } from 'react';
import GetVendorNameFromOrderState from '../../../utils/getVendorNameFromOrderState';
import { useForm } from 'antd/lib/form/Form';
import { useEffect } from 'react';
import api from '../../../common/api';

function ChangePrinter({ record }) {
  const [form] = useForm();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [vendors, setVendors] = useState([]);
  const [selectedPrinter, setSelectedPrinter] = useState();
  const [showConfirmPopup, setShowConfirmPopup] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleConfirmOk = () => {
    form.submit();
    setShowConfirmPopup(false);
  };
  const handleCancel = (isConfirm) => {
    isConfirm ? setShowConfirmPopup(false) : setIsModalOpen(false);
  };

  const getVendors = async () => {
    const config = {
      method: 'GET',
      url: `${process.env.REACT_APP_SERVER_URL_V2}/mat/printer/get-all-printers`
    };
    const response = await api(config);
    setVendors(response?.data?.data);
  };

  const handleSubmit = async (values) => {
    const config = {
      method: 'put',
      url: `${process.env.REACT_APP_SERVER_URL_V2}/mat/printer/reassign-printer`,
      data: {
        orderUniqueId: record?.orderUniqueId,
        printerId: values?.printerId
      }
    };
    const printerReassigned = await api(config);
    if (printerReassigned?.data?.data) {
      form.resetFields();
      setShowConfirmPopup(false);
      setIsModalOpen(false);
    }
  };
  const handlePrinterChange = (value) => {
    setSelectedPrinter(
      vendors.filter((vendor) => {
        if (vendor?.userId === value) return vendor?.userId;
      })
    );
    form.setFieldsValue({ printerId: value });
  };

  useEffect(() => {
    getVendors();
  }, []);
  const DISABLE_CHANGE_PRINTER = [
    'PRINTING_IN_PROCESS',
    'IN_WAREHOUSE',
    'PICKUP'
  ];

  return (
    <div>
      <Button
        type="text"
        className="assign-print-btn"
        disabled={!DISABLE_CHANGE_PRINTER?.includes(record?.status)}
        onClick={showModal}
        title="Change Printer"
      >
        <img
          src="/icons/printIcon.png"
          className={`assign-print-icon ${
            !DISABLE_CHANGE_PRINTER?.includes(record?.status) && 'grey-img'
          }`}
          alt="release/reassign"
        />
      </Button>
      <Modal
        title="Change Printer Assignment"
        visible={isModalOpen}
        onCancel={() => {
          handleCancel(false);
        }}
        footer={null}
      >
        <Form form={form} onFinish={handleSubmit}>
          <Descriptions column={1}>
            <Descriptions.Item label="Order Id">
              {record?.orderUniqueId}
            </Descriptions.Item>
            <Descriptions.Item label="Current Assigned Printer">
              <GetVendorNameFromOrderState order={record} />
            </Descriptions.Item>
          </Descriptions>
          <Form.Item label="Change Printer" name="printerId">
            <Select
              onChange={(value) => {
                handlePrinterChange(value);
              }}
            >
              {vendors?.map((vendor) => {
                return (
                  <Select.Option key={vendor?.vendorId} value={vendor?.userId}>
                    {vendor?.fullName}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
          <Form.Item>
            <Modal
              visible={showConfirmPopup}
              onOk={handleConfirmOk}
              onCancel={() => {
                handleCancel(true);
              }}
              okText="Confirm"
            >
              Are you sure you want to move this order to{' '}
              {selectedPrinter?.[0]?.fullName}?
            </Modal>
            <Row justify="end" gutter={16}>
              <Col>
                <Button
                  type="primary"
                  onClick={() => {
                    setShowConfirmPopup(true);
                  }}
                >
                  Reassign
                </Button>
              </Col>
              <Col>
                <Button
                  type="default"
                  onClick={() => {
                    form.resetFields();
                    setIsModalOpen(false);
                  }}
                >
                  Cancel
                </Button>
              </Col>
            </Row>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}

export default ChangePrinter;
