import React from "react";
import {
  Button,
  Modal,
  Form,
  Input,
  Select,
  message,
  Switch,
  Upload,
  Space,
  Alert,
} from "antd";
import { firebase } from "../../../firebase";
import { UploadOutlined, CheckCircleOutlined } from "@ant-design/icons";

const axios = require("axios");

const layout = {
  labelCol: { span: 10 },
  wrapperCol: { span: 14 },
};
const tailLayout = {
  wrapperCol: { offset: 10, span: 14 },
};

function Settings({ orgId, deptId }) {
  const [visible, setVisible] = React.useState(false);
  const [form] = Form.useForm();
  const [loading, setLoading] = React.useState(false);
  const [socialPage, setSocialPage] = React.useState(false);
  const [leadPage, setLeadPage] = React.useState(false);
  const [hasSocialPage, setHasSocialPage] = React.useState(false);
  const [hasEmailSignaturePage, setHasEmailSignaturePage] = React.useState(false);
  const [hasLeadGen, setHasLeadGen] = React.useState(false);
  const [socialPageLoading, setSocialPageLoading] = React.useState(false);
  const [leadGenLoading, setLeadGenLoading] = React.useState(false);
  const [emailLoading, setEmailLoading] = React.useState(false);
  // const [activeDirectory, setActiveDirectory] = React.useState(false);
  const [emailSignaturePage, setEmailSignaturePage] = React.useState(false);

  const handleUpload = (file, type, setLoading) => {
    // console.log(file);
    setLoading(true);
    const dir = type === "social" ? "social" : type==='email'  ? 'email_signature'  : "leadGen";
    const isZip =
      file.type === "application/x-zip-compressed" ||
      file.type === "application/zip";

    if (!isZip) {
      message.error("you can only upload zip file");
      setLoading(false);
      return;
    } else {
      var storageRef = firebase.storage().ref();
      const fileName = `${deptId}.zip`;
      var uploadTask = storageRef
        .child(`orgDesign/${dir}/${orgId}/${fileName}`)
        .put(file);
      uploadTask.on(
        "state_changed",
        function (snapshot) { },
        function (error) { },
        function () {
          uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
            axios
              .post(`${process.env.REACT_APP_PDF_DOWNLOAD_URL}/unzip`, {
                filePath: `orgDesign/${dir}/${orgId}/${deptId}.zip`,
                folder: dir,
                orgId: orgId,
                deptId:deptId
              })
              .then(function (response) {
                if (type == "social") {
                  firebase
                    .firestore()
                    .collection("organizations")
                    .doc(orgId)
                    .collection('departments')
                    .doc(deptId)
                    .set({ hasSocialPage: true }, { merge: true })
                    .then(() => {
                      message.success("uploaded successfully");
                      setHasSocialPage(true);
                      setLoading(false);
                    });
                } else if (type == "email") {
                  firebase
                    .firestore()
                    .collection("organizations")
                    .doc(orgId)
                    .collection('departments')
                    .doc(deptId)
                    .set({ hasEmailSignaturePage: true }, { merge: true })
                    .then(() => {
                      message.success("uploaded successfully");
                      setHasEmailSignaturePage(true);
                      setLoading(false);
                    });
                }else{
                  firebase
                    .firestore()
                    .collection("organizations")
                    .doc(orgId)
                    .collection('departments')
                    .doc(deptId)
                    .set({ hasLeadGen: true }, { merge: true })
                    .then(() => {
                      setHasLeadGen(true);
                      setLoading(false);
                      message.success("uploaded successfully");
                    });
                }
              })
              .catch(function (error) {
                console.log(error);
                message.error("Some error occured while uploading..");
                setLoading(false);
              });
          });
        }
      );
    }
  };

  const handleSubmit = (event) => {
    setLoading(true);
    const { customSocialPage, customLeadPage, /*customActiveDirectory,*/ customEmailSignaturePage} = event;
    const socialPageFlag =
      customSocialPage === undefined ? socialPage : customSocialPage;
    const leadPageFlag =
      customLeadPage === undefined ? leadPage : customLeadPage;
    // const activeDirectoryFlag =
    //   customActiveDirectory === undefined ? activeDirectory : customActiveDirectory;
    const emailSignaturePageFlag =
    customEmailSignaturePage === undefined ? emailSignaturePage : customEmailSignaturePage;
    firebase
      .firestore()
      .collection("organizations")
      .doc(orgId)
      .collection('departments')
      .doc(deptId)
      .update({
        customSocialPage: socialPageFlag,
        customLeadPage: leadPageFlag,
        // activeDirectory: activeDirectoryFlag,
        customEmailSignaturePage:emailSignaturePageFlag
      })
      .then(() => {
        message.success("Setting updated successfully");
        setLoading(false);
        setVisible(false);
      });
  };
  const showModal = () => {
    setVisible(true);
  };

  function getSettings() {
    firebase
      .firestore()
      .collection("organizations")
      .doc(orgId)
      .collection('departments')
      .doc(deptId)
      .onSnapshot((dept) => {
        setLeadPage(dept.data() ? dept.data().customLeadPage || false : false);
        // setActiveDirectory(dept.data() ? dept.data().activeDirectory || false : false);
        setEmailSignaturePage(dept.data() ? dept.data().customEmailSignaturePage || false : false);
        setSocialPage(
          dept.data() ? dept.data().customSocialPage || false : false
        );
        setHasLeadGen(dept.data() ? dept.data().hasLeadGen || false : false);
        setHasEmailSignaturePage(dept.data() ? dept.data().hasEmailSignaturePage || false : false);
        setHasSocialPage(
          dept.data() ? dept.data().hasSocialPage || false : false
        );
      });
  }
  React.useEffect(() => {
    getSettings();
  }, []);

  return (
    <>
      <Button type="primary" onClick={() => showModal()}>
        Settings
      </Button>
      <Modal
        title="Settings"
        visible={visible}
        footer={null}
        onCancel={() => {
          setVisible(false);
          form.resetFields();
        }}
      >
        <Alert
          message="Upload zip file for social page and handlebars file for lead generation!"
          type="warning"
        />
        <Space size={12} style={{ margin: "1rem" }}>
          <Upload
            showUploadList={false}
            action={(file) => {
              handleUpload(file, "social", setSocialPageLoading);
            }}
          >
            <Button
              loading={socialPageLoading}
              icon={
                hasSocialPage ? (
                  <CheckCircleOutlined style={{ color: "green" }} />
                ) : (
                  <UploadOutlined />
                )
              }
            >
              {hasSocialPage ? "Re-upload" : "Upload"} social page
            </Button>
          </Upload>
          <Upload
            showUploadList={false}
            action={(file) => {
              handleUpload(file, "lead", setLeadGenLoading);
            }}
          >
            <Button
              loading={leadGenLoading}
              icon={
                hasLeadGen ? (
                  <CheckCircleOutlined style={{ color: "green" }} />
                ) : (
                  <UploadOutlined />
                )
              }
            >
              {hasLeadGen ? "Re-upload" : "Upload"} lead generation
            </Button>
          </Upload>
          </Space>
          <Space size={12} style={{ margin: "0 1rem 1rem " }}>
          <Upload
            showUploadList={false}
            action={(file) => {
              handleUpload(file, "email", setEmailLoading);
            }}
          >
            <Button
              loading={emailLoading}
              icon={
                hasEmailSignaturePage ? (
                  <CheckCircleOutlined style={{ color: "green" }} />
                ) : (
                  <UploadOutlined />
                )
              }
            >
              {hasEmailSignaturePage ? "Re-upload" : "Upload"} email signature
            </Button>
          </Upload>
        </Space>
        <Form {...layout} form={form} name="basic" onFinish={handleSubmit}>
          <Form.Item label="Custom social page" name="customSocialPage">
            <Switch disabled={!hasSocialPage} defaultChecked={socialPage} />
          </Form.Item>
          <Form.Item label="Custom lead generation" name="customLeadPage">
            <Switch disabled={!hasLeadGen} defaultChecked={leadPage} />
          </Form.Item>
          {/* <Form.Item label="Allow Active Directory" name="customActiveDirectory">
            <Switch defaultChecked={activeDirectory} />
          </Form.Item> */}
          <Form.Item label="Custom Email Signature Page" name="customEmailSignaturePage">
            <Switch disabled={!hasEmailSignaturePage} defaultChecked={emailSignaturePage} />
          </Form.Item>

          <Form.Item {...tailLayout}>
            <Button loading={loading} type="primary" htmlType="submit">
              Update
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}

export default Settings;
