import React, { useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';

import {
  Button,
  Card,
  Col,
  Popconfirm,
  Row,
  Space,
  Table,
  Typography,
  Modal,
  Descriptions,
  message
} from 'antd';
import {
  DeleteOutlined,
  EyeOutlined,
  LeftOutlined,
  SettingOutlined,
  UpCircleOutlined,
  SyncOutlined
} from '@ant-design/icons';
import _isEmpty from 'lodash/isEmpty';
import _map from 'lodash/map';
import _startCase from 'lodash/startCase';
import _camelCase from 'lodash/camelCase';

import { LIMIT_PRIMARY } from '../../../common/constant';
import Loader from '../../Loader/Loader';
import { firebase } from '../../../firebase';
import { generateDate } from '../../../utils/generateDate';
import { getColumnSearchPropsV2 } from '../../../utils/searchV2';
import SettingForm from './SettingForm';
import SyncOrderForm from './SyncOrderForm';
import { scrollToTop } from '../../../common/utils';

function SyncOrders() {
  const history = useHistory();
  const [orders, setOrders] = useState([]);
  const [showSyncSetting, setShowSyncSetting] = useState(false);
  const [showSyncModal, setShowSyncModal] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [offset, setOffset] = useState();
  const [scrollTopVisible, setScrollTopVisible] = useState(false);
  const [visible, setVisible] = useState(false);
  const [cardsData, setCardsData] = useState({});
  const [rowData, setRowData] = useState({});
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [sorting, setSorting] = useState();
  const [sortColumn, setSortColumn] = useState();
  const searchInput = useRef(null);
  const [loading, setLoading] = useState(false);
  let ref = firebase
    .firestore()
    .collection('synced_order')
    .limit(LIMIT_PRIMARY);
  const creditsRef = firebase.firestore().collection('credits');
  if (searchedColumn && searchText) {
    if (searchedColumn === 'order_number') {
      ref = ref
        .orderBy(searchedColumn)
        .startAt(searchText?.toUpperCase())
        .endAt(searchText?.toUpperCase() + '\uf8ff');
    } else {
      ref = ref
        .orderBy('billing_address.email')
        .startAt(searchText?.toLowerCase())
        .endAt(searchText?.toLowerCase() + '\uf8ff');
    }
  }
  if (!searchText && sorting) {
    ref = ref.orderBy(sortColumn, sorting);
  }
  ref = ref.limit(LIMIT_PRIMARY);
  function getOrders() {
    setLoading(true);
    ref.onSnapshot((querySnapshot) => {
      const items = [];
      querySnapshot.forEach((doc) => {
        const data = doc?.data();
        items.push({
          ...data,
          docId: doc?.id,
          email: data?.billing_address?.email
        });
      });
      if (items?.length < LIMIT_PRIMARY) {
        setHasMore(false);
      }
      setOffset(querySnapshot?.docs[querySnapshot?.docs?.length - 1]);
      setOrders(items);
      setLoading(false);
    });
  }
  function nextOrders() {
    setLoading(true);
    firebase
      .firestore()
      .collection('synced_order')
      .limit(LIMIT_PRIMARY)
      .startAfter(offset)
      .onSnapshot(function (querySnapshot) {
        const items = [];
        querySnapshot.forEach(function (doc) {
          const data = doc?.data();
          items.push({
            ...data,
            docId: doc?.id,
            email: data?.billing_address?.email
          });
        });
        if (items?.length < LIMIT_PRIMARY) {
          setHasMore(false);
        }
        setOffset(querySnapshot?.docs[querySnapshot?.docs?.length - 1]);
        setOrders([...orders, ...items]);
        setLoading(false);
      });
  }

  useEffect(() => {
    getOrders();
  }, [searchText, sorting]);
  useEffect(() => {
    window.addEventListener('scroll', () => {
      setScrollTopVisible(window.pageYOffset > 300);
    });
  }, []);
  const columns = [
    {
      title: 'Woocommerce Order Number',
      width: '200px',
      dataIndex: 'order_number',
      key: 'orderNumber',
      ...getColumnSearchPropsV2(
        'order_number',
        searchText,
        searchedColumn,
        setSearchText,
        setSearchedColumn,
        searchInput,
        false,
        setHasMore
      )
    },
    {
      title: 'User',
      dataIndex: 'email',
      key: 'user',
      ...getColumnSearchPropsV2(
        'email',
        searchText,
        searchedColumn,
        setSearchText,
        setSearchedColumn,
        searchInput,
        false,
        setHasMore
      )
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      key: 'quantity'
    },
    {
      title: 'Last Updated At',
      dataIndex: 'sync_time',
      key: 'updatedAt',
      render: (text) => {
        return <div>{generateDate(text)}</div>;
      },
      sorter: true
    },
    {
      title: 'Actions',
      key: 'action',
      render: function Delete(text, record) {
        return (
          <Space size={0}>
            <Button
              type="text"
              onClick={() => handleModal(record)}
              icon={<EyeOutlined />}
            />
            <Popconfirm
              title="Are you sure to delete this order?"
              onConfirm={() => handleDelete(record)}
              okText="Yes"
              cancelText="No"
            >
              <Button type="text" icon={<DeleteOutlined />} />
            </Popconfirm>
          </Space>
        );
      }
    }
  ];
  const handleDelete = (record) => {
    for (const card in record?.synced_cards) {
      creditsRef
        .doc(record?.owner_id)
        .collection('new')
        .doc(card)
        .get()
        .then((doc) => {
          const cardData = doc?.data();
          for (const cardType in record?.synced_cards[card]) {
            const count =
              cardData[cardType] - (record?.synced_cards[card][cardType] || 0);
            cardData[cardType] = count >= 0 ? count : 0;
            creditsRef
              .doc(record?.owner_id)
              .collection('new')
              .doc(card)
              .update({ ...cardData });
          }
        });
      if (
        card === 'mobilo_metal' ||
        card === 'mobilo_wood' ||
        card === 'enterprise_service'
      ) {
        creditsRef
          .doc(record?.owner_id)
          .collection('replacement')
          .doc(card)
          .get()
          .then((doc) => {
            const cardData = doc?.data();
            let count = 0;
            for (const cardType in record?.synced_cards[card]) {
              count += record?.synced_cards[card][cardType] || 0;
            }
            cardData['card_only'] =
              cardData['card_only'] - count >= 0
                ? cardData['card_only'] - count
                : 0;
            creditsRef
              .doc(record?.owner_id)
              .collection('replacement')
              .doc(card)
              .update({ ...cardData });
          });
      }
    }
    firebase
      .firestore()
      .collection('synced_order')
      .doc(record?.docId)
      .delete()
      .then(() => {
        message.success('Order deleted successfully');
      });
  };

  const handleTableChange = (pagination, filter, sorter) => {
    if (!_isEmpty(sorter)) {
      const { order, field } = sorter;
      switch (order) {
        case 'ascend':
          switch (field) {
            case 'sync_time':
              setSorting('asc');
              setSortColumn('sync_time');
              break;
            default:
              break;
          }
          break;

        case 'descend':
          switch (field) {
            case 'sync_time':
              setSorting('desc');
              setSortColumn('sync_time');
              break;
            default:
              break;
          }
          break;

        default:
          setSorting();
          setSortColumn();
      }
    }
  };
  const handleModal = (row) => {
    setVisible(true);
    setCardsData(row?.synced_cards);
    setRowData(row);
  };
  return (
    <Card
      title={
        <Row align="middle">
          <Button
            type="text"
            onClick={() => {
              history.goBack();
            }}
            icon={<LeftOutlined />}
          />
          <Col flex="auto">
            <Typography.Title style={{ margin: 0 }} level={3}>
              Sync Credits
            </Typography.Title>
          </Col>
          <Space>
            <Button
              type="primary"
              onClick={() => setShowSyncSetting(true)}
              icon={<SettingOutlined />}
            >
              Setting
            </Button>
            <Button
              type="primary"
              onClick={() => setShowSyncModal(true)}
              icon={<SyncOutlined />}
            >
              Sync credits
            </Button>
          </Space>
        </Row>
      }
    >
      <SettingForm
        showSyncSetting={showSyncSetting}
        setShowSyncSetting={setShowSyncSetting}
      />
      <SyncOrderForm
        showSyncModal={showSyncModal}
        setShowSyncModal={setShowSyncModal}
      />
      <Table
        dataSource={orders}
        columns={columns}
        pagination={false}
        bordered={true}
        onChange={handleTableChange}
        loading={loading}
      />
      <Modal
        visible={visible}
        onCancel={() => setVisible(false)}
        footer={null}
        centered
        title={`Woocommerce order number: ${rowData?.order_number}`}
      >
        <Descriptions bordered column={1}>
          {_map(Object.keys(cardsData), (cardCategory) => (
            <Descriptions.Item label={_startCase(_camelCase(cardCategory))}>
              {_map(Object.keys(cardsData[cardCategory]), (cardName) => (
                <div>
                  {_startCase(_camelCase(cardName))}:{' '}
                  <b>{cardsData[cardCategory][cardName]}</b>
                </div>
              ))}
            </Descriptions.Item>
          ))}
        </Descriptions>
      </Modal>
      <Row>
        <Col offset={10} span={12}>
          <Button
            className="margin-t-16"
            onClick={() => {
              if (hasMore) {
                nextOrders();
              }
            }}
            disabled={!hasMore}
          >
            Load More
          </Button>
        </Col>
        <Col span={2}>
          {scrollTopVisible && (
            <Button
              shape="circle"
              onClick={() => {
                scrollToTop();
              }}
              className="margin-t-16 scroll-top-btn"
            >
              <UpCircleOutlined />
            </Button>
          )}
        </Col>
      </Row>
    </Card>
  );
}

export default SyncOrders;
