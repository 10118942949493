import React, { useContext, useState, useEffect } from 'react';
import { firebase } from '../../../../firebase';
import { Row, Col, Button, Card } from 'antd';
import { withRouter } from 'react-router-dom';
import { AuthContext } from '../../../../Auth';
import TableComponent from '../table';
import { LIMIT_PRIMARY } from '../../../../common/constant';
import { catchErrorInSentry, scrollToTop } from '../../../../common/utils';
import { UpCircleOutlined } from '@ant-design/icons';
import { AppContext } from '../../../../AppContext';

function DesignCompleted() {
  const { data } = useContext(AuthContext);
  const [sets, setSets] = useState([]);
  const [offset, setOffset] = useState();
  const [hasMore, setHasMore] = useState(true);
  const [scrollTopVisible, setScrollTopVisible] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [selectedOrderType, setSelectedOrderType] = useState('system');
  const [sorterInfo, setSorterInfo] = useState();
  const [loading, setLoading] = useState(false);

  let orderRef;
  const {
    dispatch,
    state: {
      totalLoadedOrders,
      scrollOffset,
      variation,
      sortColumn,
      sorting,
      cardTypeFilter,
      action,
      searchTerm,
      searchedField,
      orderType
    }
  } = useContext(AppContext);

  if (data?.vendorId) {
    orderRef = firebase
      .firestore()
      .collection('orders')
      .where('state', 'in', ['PRINTING', 'SHIPPING', 'COMPLETED'])
      .where('designingVendorId', '==', data?.vendorId);
    if (searchedColumn && searchText) {
      if (
        searchedColumn === 'orderNumber' ||
        searchedColumn === 'orderUniqueId'
      ) {
        if (selectedOrderType === 'system') {
          orderRef = orderRef
            .orderBy(searchedColumn)
            .startAt(searchText?.toUpperCase())
            .endAt(searchText?.toUpperCase() + '\uf8ff');
        } else if (selectedOrderType === 'woocommerce') {
          orderRef = orderRef
            .orderBy('shippingAddress.order_number')
            .startAt(searchText?.toUpperCase())
            .endAt(searchText?.toUpperCase() + '\uf8ff');
        }
      } else if (sorting && sortColumn === 'updatedAt') {
        orderRef = orderRef
          .orderBy(searchedColumn)
          .orderBy(sortColumn, sorting)
          .startAt(searchText?.toLowerCase())
          .endAt(searchText?.toLowerCase() + '\uf8ff');
      } else {
        orderRef = orderRef
          .orderBy(searchedColumn)
          .startAt(searchText?.toLowerCase())
          .endAt(searchText?.toLowerCase() + '\uf8ff');
      }
    }
    if (variation?.length > 0) {
      orderRef = orderRef.where('cardVariation', '==', variation);
    }
    if (cardTypeFilter?.length > 0) {
      orderRef = orderRef.where('cardType', '==', cardTypeFilter);
    }
    if (action !== null && action !== undefined) {
      orderRef = orderRef.where('bug', '==', action === 'true' ? true : false);
    }
    if (!searchText && action == null && action == undefined) {
      orderRef = orderRef.orderBy(sortColumn, sorting);
    }
    orderRef = orderRef.limit(totalLoadedOrders || LIMIT_PRIMARY);
  }

  function getOrders() {
    setLoading(true);
    orderRef.get().then((querySnapshot) => {
      const items = [];
      querySnapshot.docs.forEach((doc) => {
        let orderNumber = doc?.data()?.orderNumber;
        if (doc?.data()?.shippingAddress?.order_number) {
          orderNumber = `${orderNumber} (${
            doc?.data()?.shippingAddress?.order_number
          })`;
        }
        items.push({
          ...doc?.data(),
          orderDocId: doc?.id,
          customerEmail: doc?.data()?.user,
          orderNumber
        });
      });
      if (items?.length < LIMIT_PRIMARY) {
        setHasMore(false);
      }
      setOffset(querySnapshot?.docs[querySnapshot?.docs?.length - 1]);
      setSets(items);
      setLoading(false);
      window.scrollTo(0, scrollOffset);
      dispatch({ type: 'SET_TOTAL_ORDERS', data: LIMIT_PRIMARY });
      dispatch({ type: 'SET_SCROLL_OFFSET', data: 0 });
      dispatch({ type: 'SET_SEARCH_TEXT', data: '' });
      dispatch({ type: 'SET_SEARCHED_COLUMN', data: '' });
      dispatch({ type: 'SET_ORDER_TYPE', data: 'system' });
    });
  }

  const getNextOrder = async () => {
    try {
      orderRef = orderRef.startAfter(offset);
      orderRef.onSnapshot((querySnapshot) => {
        const items = [];
        querySnapshot.forEach((doc) => {
          let orderNumber = doc?.data()?.orderNumber;
          if (doc?.data()?.shippingAddress?.order_number) {
            orderNumber = `${orderNumber} (${
              doc?.data()?.shippingAddress?.order_number
            })`;
          }
          items.push({
            ...doc?.data(),
            orderDocId: doc?.id,
            customerEmail: doc?.data()?.user,
            orderNumber
          });
        });
        if (items?.length < LIMIT_PRIMARY) {
          setHasMore(false);
        }
        setOffset(querySnapshot?.docs[querySnapshot?.docs?.length - 1]);
        setSets([...sets, ...items]);
      });
    } catch (err) {
      catchErrorInSentry(err);
      console.log(err);
    }
  };

  useEffect(() => {
    if (data?.vendorId) {
      getOrders();
    }
    window.addEventListener('scroll', () => {
      setScrollTopVisible(window.pageYOffset > 300);
    });
  }, [data, searchText, cardTypeFilter, variation, sorting, action]);

  useEffect(() => {
    if (data?.vendorId) {
      if (searchTerm?.length > 0 && searchedField && orderType) {
        setSearchText(searchTerm);
        setSearchedColumn(searchedField);
        setSelectedOrderType(orderType);
      }
    }
  }, [data]);

  return (
    <Card title="Completed">
      <TableComponent
        data={sets}
        userId={data?.userId}
        loading={loading}
        searchText={searchText}
        setSearchText={setSearchText}
        searchedColumn={searchedColumn}
        setSearchedColumn={setSearchedColumn}
        selectedOrderType={selectedOrderType}
        setSelectedOrderType={setSelectedOrderType}
        setHasMore={setHasMore}
        sorterInfo={sorterInfo}
        setSorterInfo={setSorterInfo}
      />
      <Row>
        <Col offset={10} span={12}>
          <Button
            className="margin-t-16"
            onClick={() => {
              if (hasMore) {
                getNextOrder();
              }
            }}
            disabled={!hasMore}
          >
            Load More
          </Button>
        </Col>
        <Col span={2}>
          {scrollTopVisible && (
            <Button
              shape="circle"
              onClick={() => {
                scrollToTop();
              }}
              className="margin-t-16 scroll-top-btn"
            >
              <UpCircleOutlined />
            </Button>
          )}
        </Col>
      </Row>
    </Card>
  );
}

export default withRouter(DesignCompleted);
