import React, { useEffect, useRef, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';

import {
  Button,
  Card,
  Col,
  Input,
  message,
  Modal,
  Row,
  Select,
  Space,
  Spin,
  Switch,
  Table,
  Tag,
  Form,
  Tooltip
} from 'antd';
import {
  CreditCardOutlined,
  UserSwitchOutlined,
  UpCircleOutlined
} from '@ant-design/icons';
import { useForm } from 'antd/lib/form/Form';
import { capitalize } from 'lodash';

import { firebase } from '../../../firebase';
import { getColumnSearchPropsV2 } from '../../../utils/searchV2';
import {
  CARD_TYPES,
  LIMIT_PRIMARY,
  NEW_COLLECTION_DATA_TYPE
} from '../../../common/constant';
import Loader from '../../Loader/Loader';
import CommonCollapse from './CommonCollapse';
import api from '../../../common/api';
import { catchErrorInSentry, scrollToTop } from '../../../common/utils';

const { Option } = Select;

function CustomerTable({ handleModal }) {
  const [customers, setCustomers] = useState();
  const [offset, setOffset] = useState();
  const [loadingCustomer, setLoadingCustomer] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [scrollTopVisible, setScrollTopVisible] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [userId, setUserId] = useState('');
  const [addCreditsLoader, setAddCreditsLoader] = useState(false);
  const [cardCreditsModalLoader, setCardCreditsModalLoader] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [cardCreditsData, setCardCreditsData] = useState([]);
  const [isAddMoreCardModalVisible, setIsAddMoreCardModalVisible] =
    useState(false);
  const [form] = useForm();
  const history = useHistory();
  const searchInput = useRef(null);
  let ref = firebase
    .firestore()
    .collection('profiles')
    .where('isDeleted', '==', false)
    .where('isDisabled', '==', false);
  const licenseRef = firebase.firestore().collection('licenses');
  const creditsRef = firebase.firestore().collection('credits');

  if (searchText && searchedColumn) {
    if (searchedColumn === 'Full Name') {
      ref = ref
        .orderBy(searchedColumn)
        .startAt(searchText?.toUpperCase())
        .endAt(searchText?.toLowerCase() + '\uf8ff');
    } else {
      ref = ref
        .orderBy(searchedColumn)
        .startAt(searchText?.toLowerCase())
        .endAt(searchText?.toLowerCase() + '\uf8ff');
    }
  }
  ref = ref.limit(LIMIT_PRIMARY);
  const getCustomers = async () => {
    setLoadingCustomer(true);
    const data = await ref.get();
    let items = [];
    data.docs.forEach((doc) => {
      items.push({ ...doc?.data(), orgLink: doc?.data()?.orgId });
    });
    if (items?.length < LIMIT_PRIMARY && !loadingCustomer) {
      setHasMore(false);
    }
    setOffset(data?.docs[data?.docs?.length - 1]);
    setCustomers(items);
    setLoadingCustomer(false);
  };

  const getNextCustomer = async () => {
    try {
      const data = await ref.startAfter(offset).get();
      const items = [];
      data.docs.forEach((doc) => {
        items.push({ ...doc?.data(), orgLink: doc?.data()?.orgId });
      });
      if (items?.length < LIMIT_PRIMARY) {
        setHasMore(false);
      }
      setOffset(data?.docs[data?.docs?.length - 1]);
      setCustomers([...customers, ...items]);
    } catch (err) {
      catchErrorInSentry(err);
      console.log(err);
    }
  };
  useEffect(() => {
    getCustomers();
  }, [searchText]);
  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const handleAccountChange = (value, record) => {
    firebase.firestore().collection('profiles').doc(record?.userId).update({
      account_type: value
    });
  };
  const cardStatusHandler = async (uId) => {
    setCardCreditsModalLoader(true);
    setUserId(uId);
    if (uId) {
      showModal();
      const cardCredits = await (
        await creditsRef.doc(uId).collection('new').get()
      ).docs;
      let initialCardCredits = [];
      cardCredits.forEach((card) => {
        for (const [key, value] of Object.entries(CARD_TYPES)) {
          if (key?.toLowerCase() === card?.id) {
            const tempArr = [];
            // eslint-disable-next-line no-unused-expressions
            Object.entries(card.data())?.forEach(([key, value]) => {
              tempArr.push({ cardKey: key, cardValue: value });
            });
            initialCardCredits.push({
              cardType: value?.name,
              cardData: tempArr
            });
          }
        }
      });
      setCardCreditsData(initialCardCredits);
      setCardCreditsModalLoader(false);
    } else {
      message.error('User not found');
    }
  };
  const sortCardCreditsData = (arr) => {
    arr.sort((a, b) => a?.cardKey?.length - b?.cardKey?.length);
    return arr;
  };
  const addMoreCardHandleOk = () => {
    setIsAddMoreCardModalVisible(false);
  };
  const addMoreCardHandleCancel = () => {
    setIsAddMoreCardModalVisible(false);
  };

  const onFinish = (formData) => {
    setAddCreditsLoader(true);
    const data = {
      userId: userId,
      cardType: formData?.cardType,
      cardVariation: formData?.cardVariation,
      quantity: formData?.numOfEmployees,
      kind: 'new'
    };
    const config = {
      method: 'post',
      url: `${process?.env?.REACT_APP_SERVER_URL_V2}/orders/credits`,
      data: data
    };
    api(config)
      .then(() => {
        addMoreCardHandleCancel();
        setAddCreditsLoader(false);
        form.resetFields();
      })
      .catch((error) => {
        console.log(error);
        setAddCreditsLoader(false);
      });
  };
  const columns = [
    {
      title: 'Name',
      dataIndex: 'Full Name',
      key: 'fullName',
      ...getColumnSearchPropsV2(
        'Full Name',
        searchText,
        searchedColumn,
        setSearchText,
        setSearchedColumn,
        searchInput,
        false,
        setHasMore
      )
    },
    {
      title: 'Email ID',
      dataIndex: 'user',
      key: 'email',
      ...getColumnSearchPropsV2(
        'user',
        searchText,
        searchedColumn,
        setSearchText,
        setSearchedColumn,
        searchInput,
        false,
        setHasMore
      )
    },
    {
      title: 'Organization',
      dataIndex: 'orgLink',
      key: 'orgLink',
      render: (text) => {
        return (
          <div>
            {text ? (
              <Link
                to={`/v2-organizations/${text}`}
                target={'_blank'}
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                {text}
              </Link>
            ) : (
              <>No Org</>
            )}
          </div>
        );
      }
    },
    {
      title: 'Licenses Assigned',
      dataIndex: 'licenseAssigned',
      key: 'licenseAssigned',
      render: function GetLicense(text, record) {
        const [spin, setSpin] = useState(true);
        const [types, setTypes] = useState([]);
        useEffect(() => {
          if (record?.userId) {
            licenseRef
              .where('assignedTo', '==', record?.userId)
              .where('isExpired', '==', false)
              .get()
              .then((record) => {
                const items = [];
                record.forEach((card) => {
                  items.push(card?.data()?.type);
                });
                setTypes(items);
              })
              .finally(() => {
                setSpin(false);
              });
          } else {
            setSpin(false);
          }
        }, [record?.userId]);
        return spin ? (
          <Spin />
        ) : types?.length ? (
          <div>
            {types.map((type) => (
              <Tag>{type}</Tag>
            ))}
          </div>
        ) : (
          '-'
        );
      }
    },
    {
      title: 'Phone',
      dataIndex: 'Phone Number (work)',
      key: 'phoneNumber'
    },
    {
      title: 'Account Type',
      key: 'account_type',
      render: (text, record) => {
        return (
          capitalize(record?.account_type) || (
            <Space
              size={0}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <Select
                placeholder="Not Specified"
                value={record?.account_type}
                onChange={(e) => {
                  handleAccountChange(e, record);
                }}
              >
                <Option value="TEAM">Team</Option>
                <Option value="PERSONAL">Personal</Option>
              </Select>
            </Space>
          )
        );
      }
    },
    {
      title: 'Show Credits',
      dataIndex: 'Show Credits',
      key: 'showStatus',
      render: (_, record) => {
        return (
          <>
            <Button
              type="primary"
              onClick={(e) => {
                e.stopPropagation();
                cardStatusHandler(record?.userId);
              }}
            >
              Show Credits
            </Button>
          </>
        );
      }
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (text, record) => {
        return (
          <div onClick={(e) => e.stopPropagation()}>
            <Space size={0}>
              <Tooltip title="Enterprise Customer">
                <Switch
                  size="small"
                  checked={record?.enterpriseCustomer}
                  onChange={(e) => {
                    firebase
                      .firestore()
                      .collection('profiles')
                      .doc(record?.userId)
                      .update({
                        enterpriseCustomer: e
                      })
                      .then(() => {
                        const successMessage = e
                          ? 'Enterprise Customer Enabled'
                          : 'Enterprise Customer Disabled';
                        message.success(successMessage);
                      })
                      .catch((e) => {
                        catchErrorInSentry(e);
                        message.error('some error occurred');
                        console.log(e);
                      });
                  }}
                />
              </Tooltip>
              <Tooltip title="Cards">
                <Button
                  type="text"
                  icon={<CreditCardOutlined />}
                  onClick={(e) => {
                    e.stopPropagation();
                    history.push(`/v2-customers/${record?.userId}`);
                  }}
                />
              </Tooltip>
              <Button
                href={`${process?.env?.REACT_APP_BYPASS_CONFIG_URL}/#/superPassUser?userId=${record?.userId}`}
                target="_blank"
                type="text"
                icon={<UserSwitchOutlined />}
              />
              <Tooltip title="Change email">
                <Button
                  type="text"
                  icon={<img src="/icons/email-config-1.png" />}
                  onClick={() => handleModal(record)}
                />
              </Tooltip>
            </Space>
          </div>
        );
      }
    }
  ];

  const addMoreCardShowModal = () => {
    setIsAddMoreCardModalVisible(true);
  };
  const addMoreBtnHandler = () => {
    handleCancel();
    addMoreCardShowModal();
  };
  useEffect(() => {
    window.addEventListener('scroll', () => {
      setScrollTopVisible(window.pageYOffset > 300);
    });
  }, []);
  if (loadingCustomer && !customers) {
    return <Loader />;
  }
  return (
    <div>
      <Modal
        title="Show Credits"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Card style={{ backgroundColor: '#FAFAFA' }}>
          {cardCreditsModalLoader ? (
            <Row justify="center">
              <Spin />
            </Row>
          ) : (
            <>
              {!cardCreditsData?.length ? (
                <>
                  <Row>
                    <Col span={18}>
                      <h3>Card Credits Not Found</h3>
                    </Col>
                    <Col span={6}>
                      <Button onClick={addMoreBtnHandler}>Add More</Button>
                    </Col>
                  </Row>
                </>
              ) : (
                <>
                  <Row gutter={[24, 12]} justify="space-between">
                    <Col span={18}>
                      <strong>Card Credits</strong>
                      <p>For new employee</p>
                      {cardCreditsData.map((cardCreditData) => {
                        return (
                          <>
                            <CommonCollapse
                              header={cardCreditData?.cardType}
                              panelData={sortCardCreditsData(
                                cardCreditData?.cardData
                              )}
                            />
                          </>
                        );
                      })}
                    </Col>
                    <Col span={6}>
                      <Button onClick={addMoreBtnHandler}>Add More</Button>
                    </Col>
                  </Row>
                </>
              )}
            </>
          )}
        </Card>
      </Modal>
      <Modal
        title="Add More Card"
        visible={isAddMoreCardModalVisible}
        onOk={addMoreCardHandleOk}
        onCancel={addMoreCardHandleCancel}
        footer=""
      >
        <Card style={{ backgroundColor: '#FAFAFA' }}>
          <Form name="basic" layout="vertical" onFinish={onFinish} form={form}>
            <Form.Item label="Select Card Type" name="cardType">
              <Select placeholder="select card type">
                {Object.entries(CARD_TYPES)?.map(([key, value]) => {
                  if (value?.name !== 'Enterprise Service') {
                    return (
                      <Option value={value?.db_value?.toLowerCase()}>
                        {value?.name}
                      </Option>
                    );
                  }
                })}
              </Select>
            </Form.Item>
            <Form.Item label="Select Card Variation" name="cardVariation">
              <Select placeholder="select card variation">
                {NEW_COLLECTION_DATA_TYPE?.map((variation) => {
                  return (
                    <Option value={variation?.type}>{variation?.name}</Option>
                  );
                })}
              </Select>
            </Form.Item>

            <Form.Item label="Number Of Employees" name="numOfEmployees">
              <Input />
            </Form.Item>

            <Form.Item>
              <Row justify="center">
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={addCreditsLoader}
                  style={{ marginRight: '10px' }}
                >
                  Add
                </Button>
                <Button
                  onClick={addMoreCardHandleCancel}
                  style={{ marginLeft: '10px' }}
                  disabled={addCreditsLoader}
                >
                  Cancel
                </Button>
              </Row>
            </Form.Item>
          </Form>
        </Card>
      </Modal>
      <Table
        dataSource={customers}
        columns={columns}
        pagination={false}
        bordered={true}
        onRow={(record) => {
          return {
            onClick: () => {
              history.push(`/v2-customers/profile/${record?.userId}`);
            }
          };
        }}
      />
      <Row>
        <Col offset={10} span={12}>
          <Button
            className="load-button"
            onClick={() => {
              if (hasMore) {
                getNextCustomer();
              }
            }}
            disabled={!hasMore}
          >
            Load More
          </Button>
        </Col>
        <Col span={2}>
          {scrollTopVisible && (
            <Button
              shape="circle"
              onClick={() => {
                scrollToTop();
              }}
              className="load-button scroll-top-btn"
            >
              <UpCircleOutlined />
            </Button>
          )}
        </Col>
      </Row>
    </div>
  );
}

export default CustomerTable;
