import React, { useState } from 'react';

import { Button, Checkbox, Form, Input, message, Modal, Select } from 'antd';
import { useForm } from 'antd/lib/form/Form';

import {
  PRODUCT_ACTIONS,
  PRODUCT_TYPE_IDENTIFIER_OPT
} from '../../../common/constant';
import { firebase } from '../../../firebase';
import { catchErrorInSentry } from '../../../common/utils';

const { Option } = Select;

function SettingForm({ showSyncSetting, setShowSyncSetting }) {
  const [syncSettingLoader, setSyncSettingLoader] = useState(false);
  const [form] = useForm();

  const handleSettingSubmit = (settingData) => {
    setSyncSettingLoader(true);
    const {
      productTypeIdentifier,
      productId,
      productName,
      productAction,
      isSubscription = false
    } = settingData;
    firebase
      .firestore()
      .collection('wooCommerceProductMap')
      .doc(productId)
      .set({
        productId,
        productName,
        productTypeIdentifier,
        productAction,
        isSubscription
      })
      .then(() => {
        message.success('WooCommerce product added successfully');
        setSyncSettingLoader(false);
        setShowSyncSetting(false);
        form.resetFields();
      })
      .catch((error) => {
        catchErrorInSentry(error);
        setSyncSettingLoader(false);
        setShowSyncSetting(false);
        form.resetFields();
      });
  };
  return (
    <div>
      <Modal
        visible={showSyncSetting}
        onCancel={() => setShowSyncSetting(false)}
        footer={null}
        centered
        title={'Setting'}
      >
        <Form
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          initialValues={{ remember: true }}
          onFinish={handleSettingSubmit}
          autoComplete="off"
          form={form}
        >
          <Form.Item
            label="Product Id"
            name="productId"
            rules={[{ required: true, message: 'Please input product Id!' }]}
          >
            <Input placeholder="Please enter product id" type="number" />
          </Form.Item>
          <Form.Item
            label="Product Name"
            name="productName"
            rules={[
              { required: true, message: 'Please input your product name' }
            ]}
          >
            <Input placeholder="Please enter product name" />
          </Form.Item>
          <Form.Item
            label="Product Type Identifier"
            name="productTypeIdentifier"
            rules={[
              {
                required: true,
                message: 'Please input your product type identifier'
              }
            ]}
          >
            <Select placeholder="Please enter product type identifier">
              {PRODUCT_TYPE_IDENTIFIER_OPT?.map(({ name, value }, index) => {
                return (
                  <Option value={value} key={index}>
                    {name}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
          <Form.Item
            label="Product Action"
            name="productAction"
            rules={[
              { required: true, message: 'Please input your product action' }
            ]}
          >
            <Select placeholder="Please enter product action">
              {PRODUCT_ACTIONS?.map(({ action }, index) => {
                return (
                  <Option value={action} key={index}>
                    {action}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>

          <Form.Item
            name="isSubscription"
            wrapperCol={{
              offset: 8,
              span: 16
            }}
            valuePropName="checked"
          >
            <Checkbox>Is subscription on</Checkbox>
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            <Button
              type="primary"
              htmlType="submit"
              loading={syncSettingLoader}
            >
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}

export default SettingForm;
