import React from 'react';

import { Checkbox, Col, Input, InputNumber, Row, Select } from 'antd';

import omit from 'lodash/omit';
import {
  ALIGNMENT,
  CARD_HALF_WIDTH,
  CARD_WIDTH,
  DEFAULT_FONT_SIZE,
  RESOLUTION_FACTOR
} from '../../../../common/constant';
import {
  checkFieldInObject,
  getAllCoordinates
} from '../../../../common/utils';

function FieldConfig({
  textField,
  fonts,
  currentTab,
  fieldId,
  cardData,
  setCardData,
  front,
  setFront,
  back,
  setBack,
  activeUserTab,
  setHideField,
  newField,
  setNewField
}) {
  const setCustomFieldValue = (e, fieldId) => {
    const items = [];
    if (currentTab === 'front') {
      setFront({
        ...front,
        [fieldId]: {
          x: 0,
          y: 0,
          dynamic: fieldId == 'customField' ? false : true,
          value: e?.target?.value?.trim(),
          fontSize: DEFAULT_FONT_SIZE * RESOLUTION_FACTOR
        }
      });
      getAllCoordinates(
        {
          ...front,
          [fieldId]: {
            x: 0,
            y: 0,
            dynamic: fieldId == 'customField' ? false : true,
            value: e?.target?.value?.trim(),
            fontSize: DEFAULT_FONT_SIZE * RESOLUTION_FACTOR
          }
        },
        setFront,
        `${[fieldId]}-front`,
        currentTab,
        0,
        0
      );
    } else {
      setBack({
        ...back,
        [fieldId]: {
          x: 0,
          y: 0,
          dynamic: fieldId == 'customField' ? false : true,
          value: e?.target?.value?.trim(),
          fontSize: DEFAULT_FONT_SIZE * RESOLUTION_FACTOR
        }
      });
      getAllCoordinates(
        {
          ...back,
          [fieldId]: {
            x: 0,
            y: 0,
            dynamic: fieldId == 'customField' ? false : true,
            value: e?.target?.value?.trim(),
            fontSize: DEFAULT_FONT_SIZE * RESOLUTION_FACTOR
          }
        },
        setBack,
        `${[fieldId]}-back`,
        currentTab,
        0,
        0
      );
    }
    cardData.map((cardUser) => {
      items.push({
        ...cardUser,
        [fieldId]: e?.target?.value
      });
    });
    setCardData(items);
  };
  return (
    <Row justify="space-between" style={{ width: '100%' }}>
      <Col span={4}>
        <Checkbox
          onChange={async (e) => {
            if (fieldId == 'customField') {
              setHideField(!e?.target?.checked);
            }
            if (currentTab === 'front') {
              await setFront({
                ...front,
                [fieldId]: {
                  x: 0,
                  y: 0,
                  dynamic: fieldId == 'customField' ? false : true,
                  value: cardData[activeUserTab]?.[fieldId],
                  fontSize: DEFAULT_FONT_SIZE * RESOLUTION_FACTOR
                }
              });
              getAllCoordinates(
                {
                  ...front,
                  [fieldId]: {
                    x: 0,
                    y: 0,
                    dynamic: fieldId == 'customField' ? false : true,
                    value: cardData[activeUserTab]?.[fieldId],
                    fontSize: DEFAULT_FONT_SIZE * RESOLUTION_FACTOR
                  }
                },
                setFront,
                `${[fieldId]}-front`,
                currentTab,
                0,
                0
              );
            } else {
              await setBack({
                ...back,
                [fieldId]: {
                  x: 0,
                  y: 0,
                  dynamic: fieldId == 'customField' ? false : true,
                  value: cardData[activeUserTab]?.[fieldId],
                  fontSize: DEFAULT_FONT_SIZE * RESOLUTION_FACTOR
                }
              });
              getAllCoordinates(
                {
                  ...back,
                  [fieldId]: {
                    x: 0,
                    y: 0,
                    dynamic: fieldId == 'customField' ? false : true,
                    value: cardData[activeUserTab]?.[fieldId],
                    fontSize: DEFAULT_FONT_SIZE * RESOLUTION_FACTOR
                  }
                },
                setBack,
                `${[fieldId]}-back`,
                currentTab,
                0,
                0
              );
            }
            if (e?.target?.checked === false) {
              if (currentTab === 'front') {
                setFront(omit(front, fieldId));
              } else {
                setBack(omit(back, fieldId));
              }
            }
          }}
          checked={
            currentTab === 'front'
              ? front.hasOwnProperty(fieldId)
              : back.hasOwnProperty(fieldId)
          }
        >
          {textField}
        </Checkbox>
      </Col>
      <Col span={2}>
        <Select
          size="small"
          className="w-80"
          onChange={(e) => {
            if (currentTab === 'front' && front?.[fieldId]) {
              setFront({
                ...front,
                [fieldId]: { ...front?.[fieldId], family: e[0], url: e[1] }
              });
            } else if (back?.[fieldId]) {
              setBack({
                ...back,
                [fieldId]: { ...back?.[fieldId], family: e[0], url: e[1] }
              });
            } else {
              return;
            }
          }}
        >
          {fonts?.map((font) => {
            return (
              <Select.Option value={[font?.name, font?.link]}>
                {font?.name}
              </Select.Option>
            );
          })}
        </Select>
      </Col>
      <Col span={2}>
        <Input
          type="color"
          className="w-80"
          name="firstNameColor"
          onChange={(e) => {
            if (currentTab === 'front' && front?.[fieldId]) {
              setFront({
                ...front,
                [fieldId]: { ...front?.[fieldId], color: e?.target?.value }
              });
            } else if (back?.[fieldId]) {
              setBack({
                ...back,
                [fieldId]: { ...back?.[fieldId], color: e?.target?.value }
              });
            } else {
              return;
            }
          }}
        />
      </Col>
      <Col span={2}>
        <InputNumber
          size="small"
          onChange={(e) => {
            if (currentTab === 'front' && front?.[fieldId]) {
              setFront({
                ...front,
                [fieldId]: {
                  ...front?.[fieldId],
                  fontSize: e * RESOLUTION_FACTOR || front?.[fieldId]?.fontSize
                }
              });
            } else if (back?.[fieldId]) {
              setBack({
                ...back,
                [fieldId]: {
                  ...back?.[fieldId],
                  fontSize: e * RESOLUTION_FACTOR || front?.[fieldId]?.fontSize
                }
              });
            } else {
              return;
            }
          }}
        />
      </Col>
      <Col span={2}>
        <Select
          size="small"
          className="w-80"
          value={
            currentTab === 'front'
              ? front?.[fieldId]?.alignment
              : back?.[fieldId]?.alignment
          }
          onChange={async (e) => {
            let x;
            if (e === 'LEFT') {
              x = 0;
            } else if (e === 'CENTER') {
              x =
                CARD_HALF_WIDTH -
                ((currentTab === 'front'
                  ? front?.[fieldId]?.topRight?.x
                  : back?.[fieldId]?.topRight?.x) /
                  RESOLUTION_FACTOR -
                  (currentTab === 'front'
                    ? front?.[fieldId]?.topLeft?.x
                    : back?.[fieldId]?.topLeft?.x) /
                    RESOLUTION_FACTOR) /
                  2;
            } else if (e === 'RIGHT') {
              x =
                CARD_WIDTH -
                ((currentTab === 'front'
                  ? front?.[fieldId]?.topRight?.x
                  : back?.[fieldId]?.topRight?.x) /
                  RESOLUTION_FACTOR -
                  (currentTab === 'front'
                    ? front?.[fieldId]?.topLeft?.x
                    : back?.[fieldId]?.topLeft?.x) /
                    RESOLUTION_FACTOR);
            } else {
            }
            if (currentTab === 'front' && front?.[fieldId]) {
              await setFront({
                ...front,
                [fieldId]: {
                  ...front?.[fieldId],
                  alignment: e,
                  x: x * RESOLUTION_FACTOR
                }
              });
              await getAllCoordinates(
                {
                  ...front,
                  [fieldId]: {
                    ...front?.[fieldId],
                    alignment: e,
                    x: x * RESOLUTION_FACTOR
                  }
                },
                setFront,
                `${[fieldId]}-front`,
                currentTab,
                x,
                front?.[fieldId]?.y / RESOLUTION_FACTOR
              );
            } else if (back?.[fieldId]) {
              setBack({
                ...back,
                [fieldId]: {
                  ...front?.[fieldId],
                  alignment: e,
                  x: x * RESOLUTION_FACTOR
                }
              });
              await getAllCoordinates(
                {
                  ...back,
                  [fieldId]: {
                    ...back?.[fieldId],
                    alignment: e,
                    x: x * RESOLUTION_FACTOR
                  }
                },
                setBack,
                `${[fieldId]}-back`,
                currentTab,
                x,
                back?.[fieldId]?.y / RESOLUTION_FACTOR
              );
            } else {
              return;
            }
          }}
        >
          {React.Children.toArray(
            ALIGNMENT?.map((font) => {
              return <Select.Option value={font}>{font}</Select.Option>;
            })
          )}
        </Select>
      </Col>
      <Col span={4}>
        <InputNumber
          value={
            currentTab === 'front' ? front?.[fieldId]?.x : back?.[fieldId]?.x
          }
          min={0}
          max={2022}
          size="small"
          onChange={(e) => {
            if (currentTab === 'front' && front?.[fieldId]) {
              setFront({
                ...front,
                [fieldId]: { ...front?.[fieldId], x: e }
              });
            } else if (back?.[fieldId]) {
              setBack({
                ...back,
                [fieldId]: { ...back?.[fieldId], x: e }
              });
            } else {
              return;
            }
          }}
          className="input-number mr-4"
        />
        <InputNumber
          value={
            currentTab === 'front' ? front?.[fieldId]?.y : back?.[fieldId]?.y
          }
          min={0}
          max={2022}
          size="small"
          onChange={async (e) => {
            if (currentTab === 'front') {
              await setFront({
                ...front,
                [fieldId]: { ...front?.[fieldId], y: e }
              });
              await getAllCoordinates(
                {
                  ...front,
                  [fieldId]: {
                    ...front?.[fieldId],
                    y: e
                  }
                },
                setFront,
                `${[fieldId]}-front`,
                currentTab,
                front?.[fieldId]?.x / RESOLUTION_FACTOR,
                e / RESOLUTION_FACTOR
              );
            } else {
              await setBack({
                ...back,
                [fieldId]: { ...back?.[fieldId], y: e }
              });
              await getAllCoordinates(
                {
                  ...back,
                  [fieldId]: {
                    ...back?.[fieldId],
                    y: e
                  }
                },
                setBack,
                `${[fieldId]}-back`,
                currentTab,
                back?.[fieldId]?.x / RESOLUTION_FACTOR,
                e / RESOLUTION_FACTOR
              );
            }
          }}
          className="input-number"
        />
      </Col>
      {/* Rotation is the part of next sprint so that commenting UI for now */}
      {/* <Col span={2}>
        <InputNumber
          min={0}
          max={360}
          size="small"
          onChange={(e) => {
            if (currentTab === 'front' && front?.[fieldId]) {
              setFront({
                ...front,
                [fieldId]: {
                  ...front?.[fieldId],
                  rotation: e || 0
                }
              });
            } else if (back?.[fieldId]) {
              setBack({
                ...back,
                [fieldId]: {
                  ...back?.[fieldId],
                  rotation: e || 0
                }
              });
            } else {
              return;
            }
          }}
        />
      </Col> */}
      <Col span={2}>
        <Checkbox
          defaultChecked={fieldId == 'customField' ? false : true}
          onChange={(e) => {
            if (currentTab === 'front' && front?.[fieldId]) {
              setFront({
                ...front,
                [fieldId]: {
                  ...front?.[fieldId],
                  dynamic: e?.target?.checked
                }
              });
            } else if (back?.[fieldId]) {
              setBack({
                ...back,
                [fieldId]: { ...back?.[fieldId], dynamic: e?.target?.checked }
              });
            } else {
              return;
            }
          }}
        ></Checkbox>
      </Col>
      <Col span={8}>
        {fieldId === 'customField' &&
          checkFieldInObject(front, back, 'customField', currentTab) && (
            <>
              <label>Custom field</label>
              <Input
                size="small"
                onPressEnter={(e) => {
                  setCustomFieldValue(e, fieldId);
                  setNewField(e?.target?.value);
                }}
                onChange={(e) => {
                  setCustomFieldValue(e, fieldId);
                  setNewField(e?.target?.value);
                }}
                value={newField}
              />
            </>
          )}
      </Col>
    </Row>
  );
}

export default FieldConfig;
