import { Button, Form, Input, message, Table, Upload } from 'antd';
import React, { useState, useContext, useRef, useEffect } from 'react';
import {
  DownloadOutlined,
  UploadOutlined,
  SaveOutlined
} from '@ant-design/icons';
import { CSVLink } from 'react-csv';
import _map from 'lodash/map';
import { useForm } from 'antd/lib/form/Form';
import Papa from 'papaparse';
import { firebase } from '../../../firebase';
import { EDITABLE_STATE } from '../../../common/constant';
import { formValidatorRules } from '../../../common/utils';
import api from '../../../common/api';

const EditableContext = React.createContext(null);
const { name, number, phoneNumber, zipcode, letters, email } =
  formValidatorRules;

const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef(null);
  const form = useContext(EditableContext);

  useEffect(() => {
    if (editing) {
      inputRef.current.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({
      [dataIndex]: record[dataIndex]
    });
  };

  const save = async () => {
    try {
      const values = await form.validateFields();
      toggleEdit();
      handleSave({ ...record, ...values });
    } catch (errInfo) {
      console.log('Save failed:', errInfo);
    }
  };

  const CommonInput = ({ rules }) => {
    return (
      <Form.Item
        style={{
          margin: 0
        }}
        name={dataIndex}
        rules={rules && [rules]}
      >
        <Input
          ref={inputRef}
          onPressEnter={save}
          onBlur={save}
          className="input-width-100"
        />
      </Form.Item>
    );
  };

  const DescriptionText = () => {
    return (
      <div className="editable-cell-value-wrap" onClick={toggleEdit}>
        {children}
      </div>
    );
  };

  let childNode = children;

  if (editable) {
    switch (dataIndex) {
      case 'firstName':
        {
          childNode = editing ? (
            <CommonInput rules={name} />
          ) : (
            <DescriptionText />
          );
        }
        break;
      case 'lastName':
        {
          childNode = editing ? (
            <CommonInput rules={name} />
          ) : (
            <DescriptionText />
          );
        }
        break;
      case 'company':
        {
          childNode = editing ? <CommonInput /> : <DescriptionText />;
        }
        break;
      case 'title':
        {
          childNode = editing ? <CommonInput /> : <DescriptionText />;
        }
        break;
      case 'email':
        {
          childNode = editing ? (
            <CommonInput rules={email} />
          ) : (
            <DescriptionText />
          );
        }
        break;
      case 'mobileCountryCode':
        {
          childNode = editing ? (
            <CommonInput rules={number} />
          ) : (
            <DescriptionText />
          );
        }
        break;
      case 'mobilePhone':
        {
          childNode = editing ? (
            <CommonInput rules={phoneNumber} />
          ) : (
            <DescriptionText />
          );
        }
        break;
      case 'workPhoneCountryCode':
        {
          childNode = editing ? (
            <CommonInput rules={number} />
          ) : (
            <DescriptionText />
          );
        }
        break;
      case 'workPhone':
        {
          childNode = editing ? (
            <CommonInput rules={phoneNumber} />
          ) : (
            <DescriptionText />
          );
        }
        break;
      case 'addressLine1':
        {
          childNode = editing ? <CommonInput /> : <DescriptionText />;
        }
        break;
      case 'addressLine2':
        {
          childNode = editing ? <CommonInput /> : <DescriptionText />;
        }
        break;
      case 'city':
        {
          childNode = editing ? (
            <CommonInput rules={name} />
          ) : (
            <DescriptionText />
          );
        }
        break;
      case 'zipcode':
        {
          childNode = editing ? (
            <CommonInput rules={zipcode} />
          ) : (
            <DescriptionText />
          );
        }
        break;
      case 'state':
        {
          childNode = editing ? (
            <CommonInput rules={letters} />
          ) : (
            <DescriptionText />
          );
        }
        break;
      case 'country':
        {
          childNode = editing ? (
            <CommonInput rules={letters} />
          ) : (
            <DescriptionText />
          );
        }
        break;
      case 'linkedIn':
        {
          childNode = editing ? <CommonInput /> : <DescriptionText />;
        }
        break;
      default:
        return null;
    }
  }

  return <td {...restProps}>{childNode}</td>;
};

function EditableTable({
  data,
  set,
  setSet,
  orderUniqueId,
  cardUsers,
  setCardUsers,
  setIsPromptRequired,
  isPromptRequired,
  status,
  getSet
}) {
  const [form] = useForm();
  const [loading, setLoading] = useState(false);

  const column = [
    {
      title: 'First Name',
      dataIndex: 'firstName',
      key: 'firstName',
      editable: true,
      render: (text) => {
        return <span>{text?.length > 0 ? text : '-'}</span>;
      }
    },
    {
      title: 'Last Name',
      dataIndex: 'lastName',
      key: 'lastName',
      editable: true,
      render: (text) => {
        return <span>{text?.length > 0 ? text : '-'}</span>;
      }
    },
    {
      title: 'Company',
      dataIndex: 'company',
      key: 'company',
      editable: true,
      render: (text) => {
        return <span>{text?.length > 0 ? text : '-'}</span>;
      }
    },
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      editable: true,
      render: (text) => {
        return <span>{text?.length > 0 ? text : '-'}</span>;
      }
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      editable: true,
      render: (text) => {
        return <span>{text?.length > 0 ? text : '-'}</span>;
      }
    },
    {
      title: 'Mobile country code',
      dataIndex: 'mobileCountryCode',
      key: 'mobileCountryCode',
      editable: true,
      render: (text) => {
        return <span>{text?.length > 0 ? text : '-'}</span>;
      }
    },
    {
      title: 'Mobile Phone',
      dataIndex: 'mobilePhone',
      key: 'mobilePhone',
      editable: true,
      render: (text) => {
        return <span>{text?.length > 0 ? text : '-'}</span>;
      }
    },
    {
      title: 'Work country code',
      dataIndex: 'workPhoneCountryCode',
      key: 'workPhoneCountryCode',
      editable: true,
      render: (text) => {
        return <span>{text?.length > 0 ? text : '-'}</span>;
      }
    },
    {
      title: 'Work Phone',
      dataIndex: 'workPhone',
      key: 'workPhone',
      editable: true,
      render: (text) => {
        return <span>{text?.length > 0 ? text : '-'}</span>;
      }
    },
    {
      title: 'Address line 1',
      dataIndex: 'addressLine1',
      key: 'addressLine1',
      editable: true,
      width: '200px',
      render: (text) => {
        return <span>{text?.length > 0 ? text : '-'}</span>;
      }
    },
    {
      title: 'Address line 2',
      dataIndex: 'addressLine2',
      key: 'addressLine2',
      editable: true,
      width: '200px',
      render: (text) => {
        return <span>{text?.length > 0 ? text : '-'}</span>;
      }
    },
    {
      title: 'City',
      dataIndex: 'city',
      key: 'city',
      editable: true,
      render: (text) => {
        return <span>{text?.length > 0 ? text : '-'}</span>;
      }
    },
    {
      title: 'Zipcode',
      dataIndex: 'zipcode',
      key: 'zipcode',
      editable: true,
      render: (text) => {
        return <span>{text?.length > 0 ? text : '-'}</span>;
      }
    },
    {
      title: 'State',
      dataIndex: 'state',
      key: 'state',
      editable: true,
      render: (text) => {
        return <span>{text?.length > 0 ? text : '-'}</span>;
      }
    },
    {
      title: 'Country',
      dataIndex: 'country',
      key: 'country',
      editable: true,
      render: (text) => {
        return <span>{text?.length > 0 ? text : '-'}</span>;
      }
    },
    {
      title: 'Linked In',
      dataIndex: 'linkedIn',
      key: 'linkedIn',
      editable: true,
      render: (text) => {
        return <span>{text?.length > 0 ? text : '-'}</span>;
      }
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      key: 'quantity',
      render: (text) => {
        return <span>{text ? text : '-'}</span>;
      }
    }
  ];

  const handleSave = (row) => {
    const newData = [...set];
    const index = newData.findIndex((item) => row?.key === item?.key);
    const item = newData[index];
    newData.splice(index, 1, { ...item, ...row });
    setIsPromptRequired(true);
    setSet(newData);
  };

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell
    }
  };

  const tableColumns = _map(column, (col) => {
    if (!col?.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col?.editable,
        dataIndex: col?.dataIndex,
        title: col?.title,
        handleSave: handleSave
      })
    };
  });

  const handleFiles = (file) => {
    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      complete: function (results) {
        setSet(results?.data);
      }
    });
  };

  const handleEditData = () => {
    firebase
      .auth()
      .currentUser.getIdToken(true)
      .then((token) => {
        const dataToSend = cardUsers?.map((item, i) => {
          return {
            ...item,
            firstName: set[i]?.firstName,
            lastName: set[i]?.lastName,
            company: set[i]?.company,
            title: set[i]?.title,
            email: set[i]?.email?.replace(/\s/g, '')?.toLowerCase()?.trim(),
            mobileCountryCode: set[i]?.mobileCountryCode,
            mobilePhone: set[i]?.mobilePhone,
            workPhoneCountryCode: set[i]?.workPhoneCountryCode,
            workPhone: set[i]?.workPhone,
            ...(set[i]?.addressLine1?.length > 0
              ? { addressLine1: set[i]?.addressLine1 }
              : {}),
            ...(set[i]?.addressLine2?.length > 0
              ? { addressLine2: set[i]?.addressLine2 }
              : {}),
            ...(set[i]?.city?.length > 0 ? { city: set[i]?.city } : {}),
            ...(set[i]?.zipcode?.length > 0
              ? { zipcode: set[i]?.zipcode }
              : {}),
            ...(set[i]?.state?.length > 0 ? { state: set[i]?.state } : {}),
            ...(set[i]?.country?.length > 0
              ? { country: set[i]?.country }
              : {}),
            ...(set[i]?.linkedIn?.length > 0
              ? { linkedIn: set[i]?.linkedIn }
              : {})
          };
        });
        setLoading(true);
        api({
          method: 'put',
          url: `${process.env.REACT_APP_SERVER_URL_V2}/orders/${orderUniqueId}/update-order`,
          data: dataToSend
        })
          .then(() => {
            setIsPromptRequired(false);
            setLoading(false);
            getSet();
          })
          .catch((error) => {
            setLoading(false);
            message.error(
              error?.response?.data?.message ?? 'Something went wrong'
            );
          });
      });
  };

  const uploadProps = {
    name: 'file',
    showUploadList: false,
    accept: '.csv',
    multiple: false,
    onChange(info) {
      handleFiles(info?.file?.originFileObj);
    }
  };
  return (
    <div>
      <div className="csv-download-btn">
        {set && (
          <div>
            <CSVLink filename={'Employee_detail.csv'} data={set}>
              <Button icon={<DownloadOutlined />} type="primary">
                Download csv file
              </Button>
            </CSVLink>
          </div>
        )}
        <Upload {...uploadProps}>
          <Button icon={<UploadOutlined />} type="primary">
            Click to upload
          </Button>
        </Upload>
        <Button
          type="primary"
          icon={<SaveOutlined />}
          onClick={handleEditData}
          loading={loading}
          disabled={EDITABLE_STATE[status] || false}
        >
          Save changes
        </Button>
      </div>
      <Form form={form} component={false}>
        <Table
          columns={tableColumns}
          components={components}
          rowClassName={() => 'editable-row'}
          dataSource={set}
        />
      </Form>
    </div>
  );
}

export default EditableTable;
