import { Button, List, Modal, Typography } from 'antd';
import React, { useState } from 'react';
import { UnorderedListOutlined } from '@ant-design/icons';

function OrderList({ orders }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  return (
    <div>
      <Button type="text" onClick={showModal}>
        <UnorderedListOutlined />
      </Button>
      <Modal
        title="Orders of the batch"
        visible={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={false}
      >
        <List
          bordered
          dataSource={orders}
          renderItem={(item) => <List.Item>{item}</List.Item>}
          className="list-overflow"
        />
      </Modal>
    </div>
  );
}

export default OrderList;
