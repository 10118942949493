import React from 'react';
import { Button, message, Modal } from 'antd';
import { CheckOutlined } from '@ant-design/icons';
import api from '../../../common/api';

const { confirm } = Modal;

const handleRecordHistory = (orderUniqueId, status, loggerState) => {
  api({
    method: 'post',
    url: `${process.env.REACT_APP_SERVER_URL_V2}/orders/record-history`,
    data: {
      orderUniqueId: orderUniqueId,
      status,
      state: loggerState
    }
  }).catch((error) => {
    console.log(error);
  });
};
export function PopModal({
  record,
  firebase,
  status,
  state,
  loggerState,
  getOrders
}) {
  function showConfirm() {
    confirm({
      title: `Are you sure you want to change status of ${record?.orderUniqueId} to '${status}'`,
      onOk() {
        firebase
          .firestore()
          .collection('orders')
          .where('orderUniqueId', '==', record?.orderUniqueId)
          .get()
          .then((order) => {
            firebase
              .firestore()
              .collection('orders')
              .doc(order?.docs[0]?.id)
              .update({
                status,
                state
              })
              .then(() => {
                message.success('Order status updated successfully');
                handleRecordHistory(record?.orderUniqueId, status, loggerState);
                getOrders();
              });
          });
      },
      onCancel() {}
    });
  }
  return (
    <div>
      <Button
        type="text"
        icon={<CheckOutlined />}
        onClick={showConfirm}
        style={{ color: 'green' }}
      />
    </div>
  );
}

export function BatchPopModal({
  record,
  firebase,
  status,
  setLoader,
  loader,
  state,
  loggerState,
  getOrders
}) {
  function showConfirm() {
    confirm({
      title: `Are you sure you want to change status of selected orders to '${status}'`,
      onOk() {
        setLoader(true);
        record.map((value) => {
          firebase
            .firestore()
            .collection('orders')
            .where('orderUniqueId', '==', value?.orderUniqueId)
            .get()
            .then((order) => {
              firebase
                .firestore()
                .collection('orders')
                .doc(order?.docs[0]?.id)
                .update({
                  status,
                  state
                })
                .then(() => {
                  message.success('Order status updated successfully');
                  setLoader(false);
                  handleRecordHistory(
                    value?.orderUniqueId,
                    status,
                    loggerState
                  );
                  getOrders();
                });
            });
        });
      },
      onCancel() {}
    });
  }
  return (
    <div>
      <Button
        type="primary"
        onClick={showConfirm}
        loading={loader}
        disabled={record?.length > 0 ? false : true}
      >
        Complete Selected
      </Button>
    </div>
  );
}
