import React, { useState } from 'react';

import { Button, Form, Input, message, Modal } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import api from '../../../common/api';

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 }
};
const tailLayout = {
  wrapperCol: { offset: 8, span: 16 }
};
function SyncOrderForm({ showSyncModal, setShowSyncModal }) {
  const [form] = useForm();
  const [formLoading, setFormLoading] = useState(false);

  const handleSubmit = (event) => {
    setFormLoading(true);
    const { orderNumber } = event;
    api
      .post(`${process?.env?.REACT_APP_SERVER_URL_V2}/orders/new/sync`, {
        orderNumber
      })
      .then(() => {
        setFormLoading(false);
        setShowSyncModal(false);
        form.resetFields();
      })
      .catch((err) => {
        setFormLoading(false);
      });
  };
  return (
    <div>
      <Modal
        title="Sync Credits"
        visible={showSyncModal}
        footer={null}
        onCancel={() => setShowSyncModal(false)}
      >
        <Form
          {...layout}
          form={form}
          name="basic"
          onFinish={handleSubmit}
          initialValues={{ remember: true }}
        >
          <Form.Item
            label="WC order number"
            name="orderNumber"
            rules={[{ required: true, message: 'Please enter order number!' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item {...tailLayout}>
            <Button type="primary" loading={formLoading} htmlType="submit">
              Sync
            </Button>
            <Button
              type="default"
              onClick={() => {
                form.resetFields();
                setShowSyncModal(false);
              }}
              style={{ marginLeft: '1em' }}
            >
              Cancel
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}

export default SyncOrderForm;
