import React, { useState } from 'react';
import {
  Button,
  Checkbox,
  message,
  Modal,
  Space,
  Tooltip,
  Typography
} from 'antd';
import { firebase } from '../../../firebase';
import { RedoOutlined } from '@ant-design/icons';
import api from '../../../common/api';
import { catchErrorInSentry } from '../../../common/utils';
const { confirm } = Modal;

function ReplaceCard({ record, disabled, setDisabled, onFinish }) {
  const [loading, setLoading] = useState(false);
  const [completed, setCompleted] = useState(false);

  try {
    if (record?.orderUniqueId) {
      firebase
        .firestore()
        .collection('orders')
        .where('orderUniqueId', '==', record?.orderUniqueId)
        .onSnapshot((querySnapshot) => {
          const state = querySnapshot?.docs?.length
            ? querySnapshot?.docs[0]?.data()?.state
            : '';
          if (state === 'COMPLETED') {
            setCompleted(true);
          } else {
            setCompleted(false);
          }
        });
    }
  } catch (error) {
    catchErrorInSentry(error);
  }

  function confirmReplace() {
    let checked = false;
    confirm({
      title: (
        <Space direction="vertical">
          <Typography.Text>
            Are you sure you want to regenerate card '{record?.cardCode}'?
          </Typography.Text>
          <Checkbox
            defaultChecked={checked}
            onChange={(e) => {
              checked = e?.target?.checked;
            }}
          >
            Keep original card enabled?
          </Checkbox>
        </Space>
      ),
      onOk() {
        setDisabled(true);
        setLoading(true);
        api
          .post(
            `${process.env.REACT_APP_SERVER_URL_V2}/card/${record?.cardCode}/replace`,
            {
              disableCard: !checked
            }
          )
          .then(() => {
            setLoading(false);
            setDisabled(false);
            if (onFinish) {
              onFinish();
            }
          })
          .catch(() => {
            setLoading(false);
            setDisabled(false);
            if (onFinish) {
              onFinish();
            }
          });
      },
      onCancel() {}
    });
  }

  return (
    <Tooltip title="Replace">
      <Button
        loading={loading}
        disabled={
          record?.storageSignedUrl === undefined ||
          !record?.orderUniqueId ||
          !record?.setUniqueId ||
          !completed ||
          disabled
        }
        type="text"
        onClick={confirmReplace}
        icon={<RedoOutlined />}
      />
    </Tooltip>
  );
}

export default ReplaceCard;
