import { Button, message, Modal, Tooltip } from 'antd';
import React, { useState } from 'react';
import { firebase } from '../../../firebase';
import { RetweetOutlined } from '@ant-design/icons';
import api from '../../../common/api';
import { catchErrorInSentry } from '../../../common/utils';

const { confirm } = Modal;

function ReprintCard({ record, disabled, setDisabled, onFinish }) {
  const [loading, setLoading] = useState(false);
  const [completed, setCompleted] = useState(false);
  try {
    if (record?.orderUniqueId) {
      firebase
        .firestore()
        .collection('orders')
        .where('orderUniqueId', '==', record?.orderUniqueId)
        .onSnapshot((querySnapshot) => {
          const state = querySnapshot?.docs?.length
            ? querySnapshot?.docs[0]?.data()?.state
            : '';
          if (state === 'COMPLETED') {
            setCompleted(true);
          } else {
            setCompleted(false);
          }
        });
    }
  } catch (error) {
    catchErrorInSentry(error);
  }
  function confirmReprint() {
    const alertMessage = record?.disabled
      ? 'This card is disabled, it will be enabled if you proceed to reprint it.'
      : '';
    confirm({
      title: `${alertMessage} Are you sure you want to reprint card '${record?.cardCode}'? `,
      onOk() {
        setDisabled(true);
        setLoading(true);
        if (record?.disabled) {
          firebase
            .firestore()
            .collection('cards')
            .doc(record?.cardCode)
            .update({ disabled: false });
        }
        api
          .post(
            `${process.env.REACT_APP_SERVER_URL_V2}/card/${record?.cardCode}/reprint`
          )
          .then(() => {
            setLoading(false);
            setDisabled(false);
            if (onFinish) {
              onFinish();
            }
          })
          .catch((error) => {
            setLoading(false);
            setDisabled(false);
            if (onFinish) {
              onFinish();
            }
          });
      },
      onCancel() {}
    });
  }

  return (
    <Tooltip title="Reprint">
      <Button
        loading={loading}
        type="text"
        disabled={
          record?.storageSignedUrl === undefined ||
          !record?.orderUniqueId ||
          !record?.setUniqueId ||
          !completed ||
          disabled
        }
        onClick={confirmReprint}
        icon={<RetweetOutlined />}
      ></Button>
    </Tooltip>
  );
}

export default ReprintCard;
