import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';

import { Avatar, Card, Descriptions, Typography } from 'antd';
import { LeftOutlined } from '@ant-design/icons';

import { firebase } from '../../../firebase';
import TabSection from './TabSection';
import './customerProfile.css';
import { catchErrorInSentry } from '../../../common/utils';

function CustomerProfile() {
  const history = useHistory();
  const { userId } = useParams();
  const [profileData, setProfileData] = useState();
  const profileRef = firebase.firestore().collection('profiles').doc(userId);
  const getProfileData = async () => {
    try {
      const profile = await profileRef.get();
      setProfileData({ ...profile?.data(), id: profile?.id });
    } catch (error) {
      catchErrorInSentry(error);
      console.log(error);
    }
  };
  useEffect(() => {
    getProfileData();
  }, []);
  return (
    <div className="customer-profile-container">
      <Card
        title={
          <Typography.Title style={{ margin: 0 }} level={2}>
            <LeftOutlined
              onClick={() => {
                history.goBack();
              }}
            />{' '}
            Customer Directory
          </Typography.Title>
        }
      >
        <Card.Meta
          avatar={<Avatar src={profileData?.avatarUrl} />}
          title={profileData?.['Full Name']}
        />
        <Descriptions column={1}>
          <Descriptions.Item label="Company">
            {profileData?.Company ?? '-'}
          </Descriptions.Item>
          <Descriptions.Item label="Email Address">
            {profileData?.user ?? '-'}
          </Descriptions.Item>
          <Descriptions.Item label="Account Type">
            {profileData?.account_type ?? '-'}
          </Descriptions.Item>
          <Descriptions.Item label="Phone number">
            {profileData?.['Mobile Phone Number'] ?? '-'}
          </Descriptions.Item>
          <Descriptions.Item label="Address">
            {profileData?.['Address Line 1']},{profileData?.['Address Line 2']}
          </Descriptions.Item>
        </Descriptions>
        <TabSection profileData={profileData} />
      </Card>
    </div>
  );
}

export default CustomerProfile;
