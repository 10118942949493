import React, { useEffect, useState } from 'react';
import algoliasearch from 'algoliasearch/lite';
import { InstantSearch, SearchBox, connectHits } from 'react-instantsearch-dom';
import { Table } from 'antd';
import './searchOrg.css';

const searchClient = algoliasearch(
  process.env.REACT_APP_ALGOLIA_APP_ID,
  process.env.REACT_APP_ALGOLIA_APP_KEY
);

const Hits = ({
  hits,
  onChange,
  columns,
  loading,
  organisation,
  searchText,
  setHasMore,
  hasMore
}) => {
  if (hits?.length > 0 && searchText?.length > 0) {
    setHasMore(false);
  }

  return (
    <div>
      <Table
        dataSource={searchText?.length > 0 ? hits : organisation}
        columns={columns}
        bordered={true}
        pagination={false}
        loading={loading}
        onChange={onChange}
      />
    </div>
  );
};
const CustomHits = connectHits(Hits);

function Search({
  organisation,
  setOrganisation,
  columns,
  loading,
  onChange,
  setHasMore,
  hasMore
}) {
  const [searchText, setSearchText] = useState('');

  return (
    <div>
      <InstantSearch
        searchClient={searchClient}
        indexName={process.env.REACT_APP_ALGOLIA_INDEX || 'organizations'}
      >
        <SearchBox
          translations={{ placeholder: 'Search for organization name' }}
          onChange={(e) => {
            setSearchText(e?.target?.value);
          }}
          onReset={() => {
            setSearchText('');
            setHasMore(true);
          }}
        />
        <main>
          <div>
            <CustomHits
              onChange={onChange}
              columns={columns}
              loading={loading}
              organisation={organisation}
              searchText={searchText}
              setHasMore={setHasMore}
              hasMore={hasMore}
            />
          </div>
        </main>
      </InstantSearch>
    </div>
  );
}
export default Search;
