import { Card, Table, Tabs, Typography } from 'antd';
import React, { useEffect, useContext } from 'react';
import api from '../../../../common/api';
import { LeftOutlined } from '@ant-design/icons';
import { AuthContext } from '../../../../Auth';
import { useState } from 'react';
import { useHistory } from 'react-router';
import { generateDate } from '../../../../utils/generateDate';

function BatchList() {
  const { data } = useContext(AuthContext);
  const [activeTab, setActiveTab] = useState('IN_PROGRESS');
  const [batchList, setBatchList] = useState([]);
  const [loading, setLoading] = useState(true);
  const history = useHistory();

  const columns = [
    {
      title: 'Batch',
      dataIndex: 'createdAt',
      render: (text) => {
        return <div>{generateDate(text)}</div>;
      }
    }
  ];

  const getBatchData = async function () {
    try {
      setLoading(true);
      const result = await api.get(
        `${process.env.REACT_APP_PDF_DOWNLOAD_URL}/mat/printer/get-batch-by-user-id?status=${activeTab}`,
        { headers: { secretkey: process.env.REACT_APP_PDF_FIX_SECRET } }
      );
      return result;
    } catch (error) {}
  };
  useEffect(() => {
    if (data?.userId) {
      getBatchData().then(function (result) {
        setLoading(false);
        setBatchList(result?.data?.data);
      });
    }
  }, [data?.userId, activeTab]);

  return (
    <div>
      <Card
        title={
          <Typography.Text>
            <LeftOutlined
              onClick={() => {
                history.goBack();
              }}
              className="mr-16"
            />
            Batch List
          </Typography.Text>
        }
      >
        <Tabs
          defaultActiveKey="1"
          onTabClick={(e) => {
            setActiveTab(e);
          }}
        >
          <Tabs.TabPane tab="In Progress" key="IN_PROGRESS">
            <Table
              columns={columns}
              dataSource={batchList}
              loading={loading}
              pagination={false}
              onRow={(record) => {
                return {
                  onClick: () => {
                    history.push(`/print/batch/${record?.id}`);
                  }
                };
              }}
              className="cursor-pointer"
            />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Completed" key="COMPLETED">
            <Table
              columns={columns}
              dataSource={batchList}
              loading={loading}
              pagination={false}
              onRow={(record) => {
                return {
                  onClick: () => {
                    history.push(`/print/batch/${record?.id}`);
                  }
                };
              }}
              className="cursor-pointer"
            />
          </Tabs.TabPane>
        </Tabs>
      </Card>
    </div>
  );
}

export default BatchList;
