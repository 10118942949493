import React, { useState } from 'react';

import {
  Button,
  Card,
  Checkbox,
  Form,
  Input,
  message,
  Modal,
  Typography
} from 'antd';
import { useForm } from 'antd/lib/form/Form';
import axios from 'axios';

import { firebase } from '../../../firebase';
import CustomerTable from './CustomerTable';
import { catchErrorInSentry } from '../../../common/utils';

const tailLayout = {
  wrapperCol: { offset: 8, span: 16 }
};
const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 }
};
function Customers() {
  const [selected, setSelected] = useState();
  const [showModal, setShowModal] = useState(false);
  const [formLoading, setFormLoading] = useState(false);
  const [form] = useForm();

  const handleModal = (record) => {
    setSelected(record);
    setShowModal(true);
  };
  const handleClose = () => {
    form.resetFields();
    setShowModal(false);
  };
  const handleSubmit = ({ email, resetPassword }) => {
    setFormLoading(true);
    firebase
      .auth()
      .currentUser.getIdToken(true)
      .then((token) => {
        axios
          .post(
            `${process.env.REACT_APP_SERVER_URL}/user/change_auth_email`,
            {
              email: email.toLowerCase(),
              resetPassword: !!resetPassword,
              userUID: selected?.userId
            },
            { headers: { authorization: `Bearer ${token}` } }
          )
          .then((res) => {
            message.success('Email updated successfully!');
            handleClose();
          })
          .catch((err) => {
            catchErrorInSentry(err?.response?.data?.message);
            message.error(err?.response?.data?.message);
            console.log(err);
          })
          .finally(() => {
            setFormLoading(false);
          });
      });
  };

  return (
    <Card
      title={
        <Typography.Title style={{ margin: 0 }} level={3}>
          Customers
        </Typography.Title>
      }
    >
      <CustomerTable handleModal={handleModal} />
      <Modal
        title="Change email"
        visible={showModal}
        footer={null}
        onCancel={() => handleClose()}
      >
        <Form
          form={form}
          name="basic"
          onFinish={handleSubmit}
          {...layout}
          initialValues={{
            resetPassword: true
          }}
        >
          <Form.Item
            label="New email"
            name="email"
            rules={[
              { required: true, message: 'Please enter email!' },
              {
                pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/g,
                message: 'Please enter valid email!'
              }
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Reset Password"
            name="resetPassword"
            valuePropName="checked"
          >
            <Checkbox />
          </Form.Item>
          <Form.Item {...tailLayout} style={{ marginBottom: 0 }}>
            <Button type="primary" loading={formLoading} htmlType="submit">
              Save
            </Button>
            <Button
              type="default"
              onClick={() => handleClose()}
              disabled={formLoading}
              style={{ marginLeft: '1em' }}
            >
              Cancel
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </Card>
  );
}

export default Customers;
