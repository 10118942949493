import React, { useEffect, useState } from 'react';

import {
  Button,
  Card,
  Col,
  Empty,
  Input,
  Row,
  Select,
  Space,
  Spin,
  Tabs
} from 'antd';
import { firebase } from '../../../firebase';
import './news.css';
import AddNews from './AddNews';
import NewsTile from './NewsTile';
import api from '../../../common/api';

const { Option } = Select;

function News() {
  const [news, setNews] = useState();
  const [loading, setLoading] = useState(false);
  const [loadingNews, setLoadingNews] = useState(false);
  const [newsType, setNewsType] = useState('Active');
  const [newsRestoreStatus, setNewsRestoreStatus] = useState(false);
  const [filterType, setFilterType] = useState();
  const [filterValue, setFilterValue] = useState();
  const [filterCategory, setFilterCategory] = useState();
  const [filterCategoryValue, setFilterCategoryValue] = useState();
  const [searchNameValue, setSearchNameValue] = useState();
  const [searchName, setSearchName] = useState();
  const [categories, setCategories] = useState([]);
  const getNews = async () => {
    try {
      setLoadingNews(true);
      const news = await api?.get(
        `${process.env.REACT_APP_SERVER_URL_V2}/news?isActive=${
          newsType === 'Active' && newsType !== 'trash' ? true : false
        }${
          searchName && searchNameValue
            ? `&${searchName}=${searchNameValue}`
            : ''
        }${filterType && filterValue ? `&${filterType}=${filterValue}` : ''}${
          filterCategory && filterCategoryValue
            ? `&${filterCategory}=${filterCategoryValue}`
            : ''
        }`
      );
      setNews(news?.data?.data?.news);
      setLoadingNews(false);
    } catch {
      setLoadingNews(false);
    }
  };
  const getDeletedNews = async () => {
    try {
      setLoadingNews(true);
      const deletedNews = await api.get(
        `${process.env.REACT_APP_SERVER_URL_V2}/news/deleted`
      );
      setNews(deletedNews?.data?.data?.news);
      setLoadingNews(false);
    } catch {
      console.log('error');
    }
  };
  useEffect(() => {
    if (newsType === 'Active' || newsType === 'Inactive') {
      getNews();
    } else {
      getDeletedNews();
    }
  }, [
    newsType,
    filterType,
    filterValue,
    filterCategory,
    filterCategoryValue,
    searchNameValue,
    searchName
  ]);
  useEffect(() => {
    getAllcategories();
  }, []);

  const deleteNews = (id) => {
    setLoading(true);
    firebase.auth().currentUser.getIdToken(true);
    api
      .delete(`${process.env.REACT_APP_SERVER_URL_V2}/news/${id}`)
      .then(() => {
        setLoading(false);
        getNews();
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };
  const handleRestoreNews = async (id) => {
    if (id) {
      try {
        const token = await firebase.auth().currentUser.getIdToken(true);
        if (token) {
          api
            .put(`${process.env.REACT_APP_SERVER_URL_V2}/news/${id}/restore`, {
              isActive: newsRestoreStatus ?? false
            })
            .then(() => {
              getDeletedNews();
            });
        }
      } catch (error) {
        console.log(error);
      }
    }
  };
  const onTabClick = (key) => {
    setNewsType(key);
  };
  const getAllcategories = async () => {
    const news = await api.get(
      `${process.env.REACT_APP_SERVER_URL_V2}/news/category`
    );
    setCategories(news?.data?.data?.newsCategory);
  };
  return (
    <Card
      title="News"
      extra={[<AddNews getNews={getNews} />]}
      className="news-container"
    >
      <Tabs
        activeKey={newsType}
        defaultActiveKey={newsType}
        onTabClick={onTabClick}
        tabBarExtraContent={
          (newsType === 'Active' || newsType === 'Inactive') && (
            <Space>
              <Select
                value={filterValue}
                placeholder="Select News Type"
                onChange={(e) => {
                  setFilterType('type');
                  setFilterValue(e);
                }}
              >
                <Select.Option value="NO_MEDIA">No media</Select.Option>
                <Select.Option value="VIDEO">Video</Select.Option>
                <Select.Option value="IMAGE">Image</Select.Option>
              </Select>
              <Select
                value={filterCategoryValue}
                placeholder="Select Category Type"
                onChange={(e) => {
                  setFilterCategory('categoryName');
                  setFilterCategoryValue(e);
                }}
              >
                {categories?.map((category) => {
                  return (
                    <Option value={category?.categoryName} key={category?.id}>
                      {category?.categoryName}
                    </Option>
                  );
                })}
              </Select>
              <Input.Search
                placeholder="Search News Title"
                onSearch={(value) => {
                  setSearchName('searchValue');
                  setSearchNameValue(value);
                }}
                value={searchNameValue}
                onChange={(e) => setSearchNameValue(e?.target?.value)}
              />
              <Button
                onClick={() => {
                  setFilterType();
                  setFilterValue();
                  setFilterCategoryValue();
                  setFilterCategory();
                  setSearchName();
                  setSearchNameValue();
                }}
              >
                Reset
              </Button>
            </Space>
          )
        }
      >
        <Tabs.TabPane tab="Active" key="Active">
          {loadingNews ? (
            <Spin />
          ) : (
            <Row gutter={[16, 16]}>
              {news?.length > 0 ? (
                news?.map((item, i) => {
                  return (
                    <Col sm={12} md={12} lg={12} xl={8} key={i}>
                      <NewsTile
                        title={item?.title}
                        description={item?.description}
                        image={item?.imageLocation}
                        video={item?.videoLocation}
                        createdAt={item?.createdAt}
                        newsId={item?.newsId}
                        mediaType={item?.type}
                        deleteNews={deleteNews}
                        getNews={getNews}
                        loading={loading}
                        isDeleted={item?.isDelete}
                        handleRestoreNews={handleRestoreNews}
                        setNewsRestoreStatus={setNewsRestoreStatus}
                        newsType={newsType}
                      />
                    </Col>
                  );
                })
              ) : (
                <Empty />
              )}
            </Row>
          )}
        </Tabs.TabPane>
        <Tabs.TabPane tab="Inactive" key="Inactive">
          {loadingNews ? (
            <Spin />
          ) : (
            <Row gutter={[16, 16]}>
              {news?.map((item, i) => {
                return (
                  <Col sm={12} md={12} lg={12} xl={8} key={i}>
                    <NewsTile
                      title={item?.title}
                      description={item?.description}
                      image={item?.imageLocation}
                      video={item?.videoLocation}
                      createdAt={item?.createdAt}
                      newsId={item?.newsId}
                      mediaType={item?.type}
                      deleteNews={deleteNews}
                      getNews={getNews}
                      loading={loading}
                      isDeleted={item?.isDelete}
                      handleRestoreNews={handleRestoreNews}
                      setNewsRestoreStatus={setNewsRestoreStatus}
                      newsType={newsType}
                    />
                  </Col>
                );
              })}
            </Row>
          )}
        </Tabs.TabPane>
        <Tabs.TabPane tab="Trash" key="trash">
          {loadingNews ? (
            <Spin />
          ) : (
            <Row gutter={[16, 16]}>
              {news?.map((item, i) => {
                return (
                  <Col sm={12} md={12} lg={12} xl={8} key={i}>
                    <NewsTile
                      title={item?.title}
                      description={item?.description}
                      image={item?.imageLocation}
                      video={item?.videoLocation}
                      createdAt={item?.createdAt}
                      newsId={item?.newsId}
                      mediaType={item?.type}
                      deleteNews={deleteNews}
                      getNews={getNews}
                      loading={loading}
                      isDeleted={item?.isDelete}
                      handleRestoreNews={handleRestoreNews}
                      setNewsRestoreStatus={setNewsRestoreStatus}
                      newsType={newsType}
                    />
                  </Col>
                );
              })}
            </Row>
          )}
        </Tabs.TabPane>
      </Tabs>
    </Card>
  );
}

export default News;
