import React, { useEffect } from 'react';
import {
  Button,
  Card,
  Checkbox,
  Col,
  Form,
  Input,
  message,
  Popconfirm,
  Row,
  Slider,
  Spin,
  Typography,
  Upload
} from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import { QR_TYPES } from '../../../../common/constant';
import { firebase } from '../../../../firebase';
import api from '../../../../common/api';
import { catchErrorInSentry } from '../../../../common/utils';

const QrSettings = ({
  set,
  showQrPositionSetting,
  setShowQrPositionSetting,
  base64Str,
  setBase64Str,
  qrConfig,
  setQrConfig,
  qrSettingLoader,
  setQrSettingLoader,
  showLogo,
  setShowLogo,
  isPreviousConfig,
  setQrX,
  setQrY,
  setQrRotation,
  setQrWidth,
  setQrHeight
}) => {
  const [form] = Form.useForm();
  const onQrSelectHandler = (type) => {
    if (type === 'standard' || type === 'round') {
      setQrConfig({
        ...qrConfig,
        qrType: type,
        qrLogoUrl: '',
        qrRoundedCorner: false
      });
    } else {
      setQrConfig({
        ...qrConfig,
        qrType: type,
        qrLogoUrl: '',
        qrRoundedCorner: true
      });
    }
    // clear image state when qr type change
    setBase64Str('');
    // setLogoUrl('')
    form.resetFields();
  };
  // for uploading qr logo
  const handleLogoUpload = (logoFile) => {
    if (logoFile.type !== 'image/png') {
      message.error('Only support png file');
      return;
    }
    try {
      setQrSettingLoader(true);
      const reader = new FileReader();
      reader.readAsDataURL(logoFile);
      reader.onload = function (e) {
        const image = new Image();
        image.src = e.target.result;
        image.onload = function () {
          const storageRef = firebase.storage().ref();
          const uploadTask = storageRef
            .child(`qrLogos/${set.orderUniqueId}-logo`)
            .put(logoFile);
          uploadTask.on(
            'state_changed',
            function (snapshot) {},
            function (error) {
              message.error(
                'Error occurred while uploading, please try again!'
              );
              console.log(error);
            },
            function () {
              uploadTask.snapshot.ref
                .getDownloadURL()
                .then(function (downloadURL) {
                  setQrConfig({ ...qrConfig, qrLogoUrl: downloadURL });
                  message.success('Logo uploaded successfully');
                  setQrSettingLoader(false);
                });
            }
          );
        };
      };
    } catch (err) {
      catchErrorInSentry(err);
      setQrSettingLoader(false);
      console.log(err);
    }
  };
  const getPreviousConfig = async () => {
    try {
      const previousData = await api({
        method: 'GET',
        url: `${process?.env?.REACT_APP_SERVER_URL_V2}/qr-code/previous/${set?.setUniqueId}`
      });
      setQrConfig(previousData?.data?.qrDesignConfig);
      setQrX(previousData?.data?.qrX / 5);
      setQrY(previousData?.data?.qrY / 5);
      setQrRotation(previousData?.data?.qrRotation);
      setQrWidth(previousData?.data?.qrWidth / 5);
      setQrHeight(previousData?.data?.qrHeight / 5);
    } catch (error) {
      console.log(error);
    }
  };

  const onFinish = (values) => {
    const {
      qrPadding = 100,
      qrPixelColor = '#000',
      qrRadius = false,
      showText = true,
      qrInverted = false,
      qrRoundedCorner = false,
      qrShowLogo = false
    } = values;
    setQrConfig({
      ...qrConfig,
      qrPadding,
      qrPixelColor,
      qrRadius,
      showText,
      qrInverted,
      qrRoundedCorner,
      qrShowLogo
    });
  };
  useEffect(() => {
    if (isPreviousConfig) {
      form.resetFields();
    }
  }, [isPreviousConfig]);
  useEffect(() => form.resetFields(), [qrConfig?.qrType]);
  return (
    <Spin spinning={qrSettingLoader}>
      <div style={{ marginTop: '1rem' }}>
        <Row gutter={16} align="center" justify="center">
          {QR_TYPES.map((qrObj, index) => {
            const { title, type } = qrObj;
            return (
              <Col span={6} key={index}>
                <Card
                  type="info"
                  onClick={() => onQrSelectHandler(type)}
                  style={
                    type === qrConfig?.qrType
                      ? { backgroundColor: '#e6f7ff', cursor: 'pointer' }
                      : { cursor: 'pointer' }
                  }
                >
                  {title}
                </Card>
              </Col>
            );
          })}
        </Row>
      </div>

      <Card style={{ padding: '1rem', marginTop: '1rem' }}>
        <Row>
          <Col
            span={7}
            style={{
              background: '#c4c4c4',
              height: 250
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                alignItems: 'center'
              }}
            >
              <Typography.Title
                level={4}
                style={{ marginBottom: '10px', textAlign: 'center' }}
              >
                Preview
              </Typography.Title>
              {base64Str ? (
                <div style={{}}>
                  <img
                    src={`${base64Str}`}
                    alt="qrCode"
                    style={{
                      height: '145px',
                      width: '145px',
                      backgroundColor: 'red'
                    }}
                  />
                </div>
              ) : (
                <div>Please click on generate for preview</div>
              )}
            </div>
          </Col>
          <Col span={13}>
            <div style={{ padding: '1rem' }}>
              <Form
                onFinish={onFinish}
                form={form}
                labelCol={{ span: 6 }}
                style={{ width: '30rem' }}
                initialValues={qrConfig}
              >
                {(qrConfig?.qrType === 'background' || showLogo) &&
                qrConfig?.qrType !== 'standard' ? (
                  <Form.Item
                    name="qrLogo"
                    rules={[
                      { required: true, message: 'Please upload logo file!' }
                    ]}
                  >
                    <Upload
                      style={{ width: '50%' }}
                      name="qrLogo"
                      listType="picture-card"
                      showUploadList={false}
                      action={(file) => {
                        handleLogoUpload(file);
                      }}
                    >
                      {qrConfig?.qrLogoUrl ? (
                        <img
                          src={qrConfig.qrLogoUrl}
                          alt="qr logo"
                          style={{ width: '100%', height: '100%' }}
                        />
                      ) : (
                        <div>
                          <PlusOutlined />
                          <div style={{ marginTop: 8 }}>Upload</div>
                        </div>
                      )}
                    </Upload>
                  </Form.Item>
                ) : (
                  <>
                    {qrConfig?.qrType === 'standard' && (
                      <Form.Item
                        label="Rounded Box"
                        name="qrRadius"
                        valuePropName="checked"
                      >
                        <Checkbox>Apply Rounded Box</Checkbox>
                      </Form.Item>
                    )}
                  </>
                )}
                <Form.Item
                  label="Show Text"
                  name="showText"
                  valuePropName="checked"
                >
                  <Checkbox>show card code at bottom</Checkbox>
                </Form.Item>
                {qrConfig?.qrType !== 'background' ? (
                  <Form.Item
                    label="Qr Inverted"
                    name="qrInverted"
                    valuePropName="checked"
                  >
                    <Checkbox>Qr Inverted</Checkbox>
                  </Form.Item>
                ) : (
                  ''
                )}
                {qrConfig?.qrType !== 'round' ? (
                  <Form.Item label="Padding" name="qrPadding">
                    <Slider max={400} defaultValue={100} />
                  </Form.Item>
                ) : (
                  ''
                )}
                {qrConfig?.qrType !== 'background' ? (
                  <Form.Item label="Pixel Color" name="qrPixelColor">
                    <Input type="color" />
                  </Form.Item>
                ) : (
                  ''
                )}
                {qrConfig?.qrType !== 'round' ? (
                  <Form.Item
                    label="Rounded Corner"
                    name="qrRoundedCorner"
                    valuePropName="checked"
                  >
                    <Checkbox>Rounded Corner</Checkbox>
                  </Form.Item>
                ) : (
                  ''
                )}
                {qrConfig?.qrType !== 'standard' &&
                qrConfig?.qrType !== 'background' ? (
                  <Form.Item
                    label="Show Logo"
                    name="qrShowLogo"
                    valuePropName="checked"
                  >
                    <Checkbox
                      onChange={(e) => {
                        setShowLogo(e?.target?.checked);
                      }}
                    >
                      Show Logo
                    </Checkbox>
                  </Form.Item>
                ) : (
                  ''
                )}

                <Form.Item>
                  <Button type="primary" htmlType="submit">
                    Generate
                  </Button>
                </Form.Item>
                <Popconfirm
                  placement="topLeft"
                  title="Are you sure you want to apply this design?"
                  onConfirm={() =>
                    setShowQrPositionSetting(!showQrPositionSetting)
                  }
                  okText="Yes"
                  cancelText="No"
                >
                  <Button type="primary">Confirm QR Code</Button>
                </Popconfirm>
              </Form>
            </div>
          </Col>
          <Col span={4}>
            <Button
              onClick={() => {
                getPreviousConfig();
              }}
            >
              Get previous QR
            </Button>
          </Col>
        </Row>
      </Card>
    </Spin>
  );
};

export default QrSettings;
