import { Button, Col, Row, Table, Tag } from 'antd';
import { UpCircleOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import { LIMIT_PRIMARY } from '../../../common/constant';
import { firebase } from '../../../firebase';
import { scrollToTop } from '../../../common/utils';

function CardsTable({ orgId }) {
  const [orgCards, setOrgCards] = useState();
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [offset, setOffset] = useState();
  const [scrollTopVisible, setScrollTopVisible] = useState(false);

  let cardsRef = firebase.firestore().collection('cards');
  cardsRef = cardsRef.limit(LIMIT_PRIMARY);
  const getOrgCards = () => {
    setLoading(true);
    cardsRef.where('orgId', '==', orgId).onSnapshot((querySnapshot) => {
      let cards = [];
      querySnapshot.forEach((doc) => {
        cards.push({ cardCode: doc?.id, ...doc?.data() });
      });
      setOffset(querySnapshot?.docs[querySnapshot?.docs?.length - 1]);
      if (cards?.length < LIMIT_PRIMARY) {
        setHasMore(false);
      }
      setOrgCards(cards);
      setLoading(false);
    });
  };
  const getNextOrgCards = () => {
    cardsRef
      .where('orgId', '==', orgId)
      .startAfter(offset)
      .onSnapshot((querySnapshot) => {
        let cards = [];
        querySnapshot.forEach((doc) => {
          cards.push({ cardCode: doc?.id, ...doc?.data() });
        });
        setOffset(querySnapshot?.docs[querySnapshot?.docs?.length - 1]);
        if (cards?.length < LIMIT_PRIMARY) {
          setHasMore(false);
        }
        setOrgCards([...orgCards, ...cards]);
      });
  };

  const columns = [
    {
      title: 'Card Code',
      key: 'cardCode',
      dataIndex: ['cardCode']
    },
    {
      title: 'Status',
      key: 'status',
      dataIndex: 'showProfile',
      render: (showProfile) => {
        return (
          <>
            {showProfile ? (
              <Tag color="green">Assigned</Tag>
            ) : (
              <Tag color="red">Not Assigned</Tag>
            )}
          </>
        );
      }
    }
  ];

  useEffect(() => {
    getOrgCards();
    window.addEventListener('scroll', () => {
      setScrollTopVisible(window.pageYOffset > 300);
    });
  }, []);

  return (
    <div>
      <Table
        dataSource={orgCards}
        columns={columns}
        bordered={true}
        pagination={false}
        loading={loading}
      />
      <Row>
        <Col offset={10} span={12}>
          <Button
            className="margin-t-16"
            disabled={!hasMore}
            onClick={getNextOrgCards}
          >
            Load More
          </Button>
        </Col>
        <Col span={2}>
          {scrollTopVisible && (
            <Button
              shape="circle"
              onClick={() => {
                scrollToTop();
              }}
              className="margin-t-16 scroll-top-btn"
            >
              <UpCircleOutlined />
            </Button>
          )}
        </Col>
      </Row>
    </div>
  );
}

export default CardsTable;
