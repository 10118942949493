import { EyeOutlined } from '@ant-design/icons';
import { Button, Modal, Tabs } from 'antd';
import React, { useState } from 'react';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import { RESOLUTION_FACTOR } from '../../../../common/constant';
import './showCardPreview.css';

function ShowCardPreview({ frontNode, backNode }) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <div>
      <Button icon={<EyeOutlined />} onClick={showModal} type="primary">
        Preview
      </Button>
      <Modal
        title="Card preview"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        wrapClassName="preview-modal"
      >
        <Tabs>
          <Tabs.TabPane tab="Front" key="front" className="card-preview">
            <TransformWrapper
              defaultScale={RESOLUTION_FACTOR}
              centerContent={false}
              defaultPositionX={0}
              defaultPositionY={0}
            >
              {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                <React.Fragment>
                  <TransformComponent
                    style={{
                      transform: `translate(0px,0px) scale(${RESOLUTION_FACTOR}) !important`
                    }}
                  >
                    {frontNode && (
                      <div dangerouslySetInnerHTML={{ __html: frontNode }} />
                    )}
                  </TransformComponent>
                </React.Fragment>
              )}
            </TransformWrapper>
          </Tabs.TabPane>
          <Tabs.TabPane tab="Back" key="back" className="card-preview">
            <TransformWrapper defaultScale={RESOLUTION_FACTOR} centerContent={false}>
              {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                <React.Fragment>
                  <TransformComponent style={{
                    transform: `translate(0px,0px) scale(${RESOLUTION_FACTOR}) !important`
                  }}>
                    {backNode && (
                      <div dangerouslySetInnerHTML={{ __html: backNode }} />
                    )}
                  </TransformComponent>
                </React.Fragment>
              )}
            </TransformWrapper>
          </Tabs.TabPane>
        </Tabs>
      </Modal>
    </div>
  );
}

export default ShowCardPreview;
