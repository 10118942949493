export const newCollectionDataType = [
    {
        "name": "Card only",
        "type": "card_only",
    },
    {
        "name": "+ Key Fob",
        "type": "key_fob",
    },
    {
        "name": "+ Smart Button",
        "type": "smart_button",
    },
    {
        "name": "+ Key Fob + Smart Button",
        "type": "key_fob_smart_button",
    },
]