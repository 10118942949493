import React, { useState, useContext, useRef, useEffect } from 'react';
import { firebase } from '../../../../firebase';
import {
  Table,
  Layout,
  Typography,
  Button,
  Tag,
  Spin,
  Space,
  Tooltip,
  Badge,
  Col,
  Row,
  Card
} from 'antd';
import { AuthContext } from '../../../../Auth';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { getNewMessageIndicator } from '../../../../utils/getNewMessageIndicator';
import {
  MessageOutlined,
  MessageTwoTone,
  FlagOutlined,
  FlagFilled,
  UpCircleOutlined
} from '@ant-design/icons';
import { LIMIT_PRIMARY } from '../../../../common/constant';
import { getColumnSearchPropsV2 } from '../../../../utils/searchV2';
import { generateDate } from '../../../../utils/generateDate';
import _isEmpty from 'lodash/isEmpty';
import { catchErrorInSentry, scrollToTop } from '../../../../common/utils';
import { AppContext } from '../../../../AppContext';
import { handleOrderLengthAndScrollOffset } from '../../../../utils/handleOrderLengthAndScrollOffset';

function PrintCompleted() {
  const { data } = useContext(AuthContext);

  const [orders, setOrders] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [offset, setOffset] = useState();
  const [hasMore, setHasMore] = useState(true);
  const [sortColumn, setSortColumn] = useState('createdAt');
  const [sorting, setSorting] = useState('desc');
  const [scrollTopVisible, setScrollTopVisible] = useState(false);
  const [variation, setVariation] = useState();
  const [sorterInfo, setSorterInfo] = useState();
  const [loading, setLoading] = useState(false);
  const [action, setAction] = useState();
  const [selectedOrderType, setSelectedOrderType] = useState('system');
  const {
    dispatch,
    state: { totalLoadedOrders, scrollOffset, searchTerm, searchedField }
  } = useContext(AppContext);
  const searchInput = useRef(null);
  let ref;
  if (data?.vendorId) {
    ref = firebase
      .firestore()
      .collection('orders')
      .where('state', 'in', ['SHIPPING', 'COMPLETED'])
      .where('printingVendorId', '==', data?.vendorId);
    if (searchedColumn && searchText) {
      if (
        searchedColumn === 'orderNumber' ||
        searchedColumn === 'orderUniqueId'
      ) {
        if (selectedOrderType === 'system') {
          ref = ref
            .orderBy(searchedColumn)
            .startAt(searchText?.toUpperCase())
            .endAt(searchText?.toUpperCase() + '\uf8ff');
        } else if (selectedOrderType === 'woocommerce') {
          ref = ref
            .orderBy('shippingAddress.order_number')
            .startAt(searchText?.toUpperCase())
            .endAt(searchText?.toUpperCase() + '\uf8ff');
        }
      } else if (sorting && sortColumn === 'updatedAt') {
        ref = ref
          .orderBy(searchedColumn)
          .orderBy(sortColumn, sorting)
          .startAt(searchText?.toLowerCase())
          .endAt(searchText?.toLowerCase() + '\uf8ff');
      } else {
        ref = ref
          .orderBy(searchedColumn)
          .startAt(searchText?.toLowerCase())
          .endAt(searchText?.toLowerCase() + '\uf8ff');
      }
    }
    if (variation?.length > 0) {
      ref = ref.where('cardVariation', '==', variation);
    }
    if (action !== null && action !== undefined) {
      ref = ref.where('bug', '==', action?.[0] === 'true' ? true : false);
    }
    if (!searchText && action == null && action == undefined) {
      ref = ref.orderBy(sortColumn, sorting);
    }
    ref = ref.limit(totalLoadedOrders || LIMIT_PRIMARY);
  }

  const columns = [
    {
      title: 'ID',
      dataIndex: 'orderUniqueId',
      key: 'id',
      ...getColumnSearchPropsV2(
        'orderUniqueId',
        searchText,
        searchedColumn,
        setSearchText,
        setSearchedColumn,
        searchInput,
        false,
        setHasMore
      ),
      render: function OrderUniqueId(text, record) {
        const [newMessageFlag, setNewMessageFlag] = useState(false);
        const [active, setActive] = useState(false);
        const [spin, setSPin] = useState(true);
        getNewMessageIndicator(
          record?.docId,
          data?.userId,
          setNewMessageFlag,
          setActive,
          setSPin
        );
        if (spin) return <Spin />;

        return (
          <Space>
            <Link
              to={`/print/cards/${text}`}
              onClick={() => {
                handleOrderLengthAndScrollOffset(
                  dispatch,
                  orders?.length,
                  window?.pageYOffset,
                  searchText,
                  searchedColumn
                );
              }}
            >
              {text}
            </Link>
            <Link
              to={`/chat/${text}`}
              onClick={() => {
                handleOrderLengthAndScrollOffset(
                  dispatch,
                  orders?.length,
                  window?.pageYOffset,
                  searchText,
                  searchedColumn
                );
              }}
            >
              <Tooltip title="Internal Chat">
                <Badge dot={newMessageFlag}>
                  {active ? (
                    <MessageTwoTone
                      twoToneColor="#52c41a"
                      style={{ color: 'green', margin: 2 }}
                    />
                  ) : (
                    <MessageOutlined style={{ color: '#a0a0a0', margin: 2 }} />
                  )}
                </Badge>
              </Tooltip>
            </Link>
          </Space>
        );
      }
    },
    {
      title: 'Order Number',
      dataIndex: 'orderNumber',
      key: 'orderNumber',
      ...getColumnSearchPropsV2(
        'orderNumber',
        searchText,
        searchedColumn,
        setSearchText,
        setSearchedColumn,
        searchInput,
        false,
        setHasMore,
        setSelectedOrderType,
        selectedOrderType,
        'orders'
      )
    },
    {
      title: 'Customer Email',
      dataIndex: 'customerEmail',
      key: 'customerEmail'
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (text) => {
        return <Typography.Text>{text?.replace(/_/g, ' ')}</Typography.Text>;
      }
    },
    {
      title: 'References',
      key: 'references',
      render: (text, record) => {
        const batchStartIndexes = [];
        const batchSize = 50;
        if (record?.quantity > batchSize) {
          for (let i = 0; i < record?.quantity; i++) {
            if (i % batchSize == 0) {
              if (record?.quantity - i >= batchSize)
                batchStartIndexes.push({
                  startIndex: i,
                  count: batchSize
                });
              else
                batchStartIndexes.push({
                  startIndex: i,
                  count: record?.quantity - i
                });
            }
          }
        }

        if (
          record?.cardType == 'Mobilo Card' ||
          record?.cardType == 'Free Mobilo Card'
        ) {
          return <></>;
        }
        if (!batchStartIndexes?.length)
          return (
            <Button
              target="_blank"
              href={`${process?.env?.REACT_APP_PDF_DOWNLOAD_URL}/zip?orderUniqueId=${record?.orderUniqueId}&startIndex=0&count=${record?.quantity}`}
            >
              Download
            </Button>
          );
        else
          return (
            <>
              {batchStartIndexes.map((value, index) => {
                return (
                  <Button
                    key={index}
                    target="_blank"
                    href={`${process?.env?.REACT_APP_PDF_DOWNLOAD_URL}/zip?orderUniqueId=${record?.orderUniqueId}&startIndex=${value?.startIndex}&count=${value?.count}`}
                  >
                    B{index + 1}
                  </Button>
                );
              })}
            </>
          );
      }
    },
    {
      title: 'Add-ons',
      key: 'variation',
      dataIndex: 'cardVariation',
      filters: [
        { text: 'No accessories', value: 'Card only' },
        { text: '+ Key Fob', value: '+ Key Fob' },
        { text: '+ Smart Button', value: '+ Smart Button' },
        { text: '+ Key Fob + Smart Button', value: '+ Key Fob + Smart Button' }
      ],
      onFilter: (text, record) => {
        sessionStorage.setItem('addOnsFilter', JSON.stringify(text));
        return record?.cardVariation === text;
      },
      filterMultiple: false,
      defaultFilteredValue:
        JSON.parse(sessionStorage.getItem('addOnsFilter')) || [],
      render: (text, record) => {
        const tags = [];
        try {
          const string = text?.toLowerCase();
          if (!string?.includes('card only')) {
            if (string?.includes('key fob'))
              tags.push({ text: '+ Key Fob', color: 'orange' });
            if (string?.includes('smart button'))
              tags.push({ text: '+ Smart Button', color: 'green' });
          }
        } catch (error) {
          console.log(error);
        }
        return (
          <>
            {tags.map((tag) => {
              return (
                <Tag key={tag} color={tag?.color}>
                  {tag?.text}
                </Tag>
              );
            })}
          </>
        );
      }
    },
    {
      title: 'Last Updated At',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      sorter: true,
      sortOrder: sorterInfo?.columnKey === 'updatedAt' && sorterInfo?.order,
      render: (text) => {
        return <div>{generateDate(text)}</div>;
      }
    },
    {
      title: 'Actions',
      key: 'action',
      filters: [
        {
          text: 'Bug',
          value: true
        },
        {
          text: 'No bug',
          value: undefined || false
        }
      ],
      filterMultiple: false,
      onFilter: (value, record) => record?.bug === value,
      render: function Bug(text, record) {
        const [flag, setFlag] = useState(record?.bug || false);
        async function handleFlagClick() {
          await firebase
            .firestore()
            .collection('orders')
            .doc(record?.docId)
            .update({
              ...record,
              bug: !flag
            })
            .then(() => {
              setFlag(!flag);
            });
        }
        return (
          <Space size={0}>
            <Tooltip title={'Report bug'}>
              {!(record?.bug || false) ? (
                <Button
                  type="text"
                  icon={<FlagOutlined />}
                  onClick={handleFlagClick}
                />
              ) : (
                <Button
                  type="text"
                  icon={<FlagFilled style={{ color: '#d33e48' }} />}
                  onClick={handleFlagClick}
                />
              )}
            </Tooltip>
          </Space>
        );
      }
    }
  ];

  function getOrders() {
    setLoading(true);
    ref.get().then((querySnapshot) => {
      const items = [];
      querySnapshot.forEach((doc) => {
        let orderNumber = doc?.data()?.orderNumber;
        if (doc?.data()?.shippingAddress?.order_number) {
          orderNumber = `${orderNumber} (${
            doc?.data()?.shippingAddress?.order_number
          })`;
        }
        items.push({
          ...doc?.data(),
          docId: doc?.id,
          orderNumber,
          customerEmail: doc?.data()?.user
        });
      });
      if (items?.length < LIMIT_PRIMARY) {
        setHasMore(false);
      }
      setOffset(querySnapshot?.docs[querySnapshot?.docs?.length - 1]);
      setOrders(items);
      setLoading(false);
      window.scrollTo(0, scrollOffset);
      dispatch({ type: 'SET_TOTAL_ORDERS', data: LIMIT_PRIMARY });
      dispatch({ type: 'SET_SCROLL_OFFSET', data: 0 });
      dispatch({ type: 'SET_SEARCH_TEXT', data: '' });
      dispatch({ type: 'SET_SEARCHED_COLUMN', data: '' });
      dispatch({ type: 'SET_ORDER_TYPE', data: 'system' });
    });
  }

  const getNextOrder = async () => {
    try {
      ref = ref.startAfter(offset);
      ref.onSnapshot((querySnapshot) => {
        const items = [];
        querySnapshot.forEach((doc) => {
          let orderNumber = doc?.data()?.orderNumber;
          if (doc?.data()?.shippingAddress?.order_number) {
            orderNumber = `${orderNumber} (${
              doc?.data()?.shippingAddress?.order_number
            })`;
          }
          items.push({
            ...doc?.data(),
            key: doc?.data()?.orderUniqueId,
            docId: doc?.id,
            customerEmail: doc?.data()?.user,
            orderNumber
          });
        });
        if (items?.length < LIMIT_PRIMARY) {
          setHasMore(false);
        }
        setOffset(querySnapshot?.docs[querySnapshot?.docs?.length - 1]);
        setOrders([...orders, ...items]);
      });
    } catch (err) {
      catchErrorInSentry(err);
      console.log(err);
    }
  };
  useEffect(() => {
    if (data?.vendorId) {
      getOrders();
    }
    window.addEventListener('scroll', () => {
      setScrollTopVisible(window?.pageYOffset > 300);
    });
  }, [data, searchText, variation, sorting, action]);
  useEffect(() => {
    if (data?.vendorId) {
      if (searchTerm?.length > 0 && searchedField) {
        setSearchText(searchTerm);
        setSearchedColumn(searchedField);
      }
    }
  }, [data]);
  const handleTableChange = (pagination, filter, sorter) => {
    setAction(filter?.action);
    setVariation(filter?.variation?.[0]);
    if (!_isEmpty(sorter)) {
      setSorterInfo(sorter);
      const { order, columnKey } = sorter;
      switch (order) {
        case 'ascend':
          switch (columnKey) {
            case 'orderNumber':
              setSorting('asc');
              setSortColumn('orderNumber');
              break;
            case 'updatedAt':
              setSorting('asc');
              setSortColumn('updatedAt');
              break;
            default:
              break;
          }
          break;
        case 'descend':
          switch (columnKey) {
            case 'orderNumber':
              setSorting('desc');
              setSortColumn('orderNumber');
              break;
            case 'updatedAt':
              setSorting('desc');
              setSortColumn('updatedAt');
              break;
            default:
              break;
          }
          break;

        default:
          setSorting('desc');
          setSortColumn('createdAt');
      }
    }
  };
  return (
    <Layout>
      <Layout.Header
        size="small"
        style={{
          display: 'flex',
          alignItems: 'center',
          backgroundColor: 'white',
          padding: 16,
          border: '1px solid #CCCCCC',
          boxSizing: 'border-box'
        }}
      >
        <Typography.Title style={{ margin: 0 }} level={3}>
          Completed
        </Typography.Title>
      </Layout.Header>
      <Card>
        <Table
          bordered
          dataSource={orders}
          columns={columns}
          onChange={handleTableChange}
          pagination={false}
          loading={loading}
        />
        <Row>
          <Col offset={10} span={12}>
            <Button
              className="margin-t-16"
              onClick={() => {
                if (hasMore) {
                  getNextOrder();
                }
              }}
              disabled={!hasMore}
            >
              Load More
            </Button>
          </Col>
          <Col span={2}>
            {scrollTopVisible && (
              <Button
                shape="circle"
                onClick={() => {
                  scrollToTop();
                  dispatch({ type: 'SET_TOTAL_ORDERS', data: LIMIT_PRIMARY });
                  dispatch({
                    type: 'SET_SCROLL_OFFSET',
                    data: 0
                  });
                }}
                className="margin-t-16 scroll-top-btn"
              >
                <UpCircleOutlined />
              </Button>
            )}
          </Col>
        </Row>
      </Card>
    </Layout>
  );
}

export default withRouter(PrintCompleted);
