import React from 'react';
import { Menu } from 'antd';
import {
  FolderOutlined,
  UsergroupAddOutlined,
  RocketOutlined,
  BankOutlined,
  CreditCardOutlined,
  SolutionOutlined
} from '@ant-design/icons';
import { Link, useLocation } from 'react-router-dom';

function HomeMenu() {
  const { pathname } = useLocation();

  return (
    <Menu
      mode="inline"
      size="large"
      onClick={() => sessionStorage.clear()}
      selectedKeys={
        pathname.split('/')[1] ? `/${pathname.split('/')[1]}` : '/v2-orders'
      }
    >
      <Menu.Item icon={<FolderOutlined />} key="/v2-orders">
        <Link to="/v2-orders">Orders</Link>
      </Menu.Item>
      <Menu.Item icon={<UsergroupAddOutlined />} key="/v2-customers">
        <Link to="/v2-customers">Customer</Link>
      </Menu.Item>
      <Menu.Item icon={<CreditCardOutlined />} key="/v2-cards">
        <Link to="/v2-cards">Cards</Link>
      </Menu.Item>
      <Menu.Item icon={<UsergroupAddOutlined />} key="/v2-organizations">
        <Link to="/v2-organizations">Organizations</Link>
      </Menu.Item>
      <Menu.Item icon={<RocketOutlined />} key="/v2-vendors">
        <Link to="/v2-vendors">Vendors</Link>
      </Menu.Item>
      <Menu.Item icon={<BankOutlined />} key="/v2-systemusers">
        <Link to="/v2-systemusers">System Users</Link>
      </Menu.Item>
      <Menu.Item icon={<CreditCardOutlined />} key="/v2-assignwork">
        <Link to="/v2-assignwork">Assign Work</Link>
      </Menu.Item>
      <Menu.Item icon={<CreditCardOutlined />} key="/generate">
        <Link to="/generate">Generate Cards</Link>
      </Menu.Item>
      <Menu.Item icon={<SolutionOutlined />} key="news">
        <Link to="/news">News</Link>
      </Menu.Item>
    </Menu>
  );
}

export default HomeMenu;
