import React, { useState } from 'react';
import { Button, Modal, Form, Input, Select, message, Checkbox } from 'antd';
import { firebase } from '../../../firebase';
import api from '../../../common/api';
import { catchErrorInSentry } from '../../../common/utils';

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 }
};
const tailLayout = {
  wrapperCol: { offset: 8, span: 16 }
};

function AddVendor() {
  const [visible, setVisible] = useState(false);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const handleSubmit = (event) => {
    const { email, name, vendorType, phoneNumber, systemUser } = event;
    const isSystemUser = systemUser || false;
    setLoading(true);
    try {
      firebase
        .firestore()
        .collection('vendor')
        .add({
          email: email?.toLowerCase()?.trim(),
          name,
          vendorType,
          phoneNumber,
          isActive: true,
          countries: [],
          isBusy: false,
          latestPayment: '',
          cardType: [],
          isAssigned: false,
          threshold: 100
        })
        .then((ref) => {
          firebase
            .firestore()
            .collection('vendor')
            .doc(ref?.id)
            .set(
              {
                id: ref?.id
              },
              { merge: true }
            )
            .then(() => {
              if (isSystemUser) {
                api.post(
                  `${process.env.REACT_APP_SERVER_URL_V2}/user/system-user`,
                  {
                    email: email?.toLowerCase()?.trim(),
                    fullName: name,
                    phoneNumber,
                    role: vendorType,
                    isAdmin: false,
                    isActive: true,
                    vendorId: ref?.id,
                    createdAt: new Date(),
                    isDefault: isSystemUser
                  }
                );
              }
              setLoading(false);
              setVisible(false);
              form.resetFields();
            });
        });
    } catch (error) {
      catchErrorInSentry(error);
      alert(error);
    }
  };
  const showModal = () => {
    setVisible(true);
  };

  return (
    <>
      <Button type="primary" onClick={() => showModal()}>
        Add Vendor
      </Button>
      <Modal
        title="Add Vendor"
        visible={visible}
        footer={null}
        onCancel={() => setVisible(false)}
      >
        <Form
          {...layout}
          form={form}
          name="basic"
          onFinish={handleSubmit}
          initialValues={{ remember: true }}
        >
          <Form.Item
            label="Full Name"
            name="name"
            rules={[{ required: true, message: 'Please enter Name!' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Phone Number"
            name="phoneNumber"
            rules={[{ required: true, message: 'Please enter Phone number!' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Email"
            name="email"
            rules={[{ required: true, message: 'Please enter email!' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Service"
            name="vendorType"
            rules={[{ required: true, message: 'Please enter services!' }]}
          >
            <Select
              mode="multiple"
              placeholder="Select Services"
              className="select-w-100"
            >
              <Select.Option key="PRINTER">Print</Select.Option>
              <Select.Option key="SHIPPER">Ship</Select.Option>
              <Select.Option key="DESIGNER">Design</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="Is system user?"
            name="systemUser"
            valuePropName="checked"
          >
            <Checkbox></Checkbox>
          </Form.Item>
          <Form.Item {...tailLayout}>
            <Button type="primary" loading={loading} htmlType="submit">
              Submit
            </Button>
            <Button
              type="default"
              onClick={() => {
                form.resetFields();
                setVisible(false);
              }}
              style={{ marginLeft: '1em' }}
            >
              Cancel
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}

export default AddVendor;
