import React, { memo, useEffect, useState } from 'react';
import { Button, Input, message, Form, Spin, Space } from 'antd';
import QrReader from 'react-qr-reader';
import { firebase } from '../../../../firebase';
import api from '../../../../common/api';
import Axios from 'axios';
import { catchErrorInSentry } from '../../../../common/utils';

const CardCodeInput = ({
  record,
  incrementAssignedCardQuantity,
  itemNumber,
  emailIds,
  uniqueIds
}) => {
  const [assigned, setAssigned] = useState(false);
  const [loading, setLoading] = useState(false);
  const [cardCode, setCardCode] = useState('');
  const [qrShow, setQRShow] = useState(false);
  const [showDialog, setDiaglog] = useState(false);
  const [processing, setProcessing] = useState(false);
  let cardRef = firebase.firestore().collection('cards');
  let profileRef = firebase
    .firestore()
    .collection('profiles')
    .where('user', '==', record?.email);
  const onFinish = async () => {
    setLoading(true);
    if (cardCode) {
      const response = await api.post(
        `${process?.env?.REACT_APP_SERVER_URL_V2}/orders/assign-card`,
        {
          email: record?.email,
          cardCode: cardCode,
          setUniqueId: record?.setUniqueId,
          cardColor: record?.cardColor || ''
        }
      );
      if (response?.data) {
        setAssigned(true);
        incrementAssignedCardQuantity();
        setLoading(false);
      } else {
        setLoading(false);
      }
    } else {
      message.error('Card code is missing!');
      setLoading(false);
    }
  };
  const getAssignedCodes = async (setUniqueId, email, quantity) => {
    try {
      let userId = '';
      const data = await profileRef.get();
      data.docs.forEach((doc) => {
        userId = doc?.data()?.userId;
      });
      const cardData = await cardRef
        .where('showProfile', '==', userId)
        .where('setUniqueId', '==', setUniqueId)
        .get();
      cardData.docs.forEach((doc, index) => {
        if (itemNumber === index) {
          setCardCode(doc?.data()?.cardCode);
          incrementAssignedCardQuantity();
        }
      });
    } catch (error) {
      catchErrorInSentry(error);
      console.log('Error occured', error);
    }
  };
  useEffect(() => {
    const emailCount = emailIds.filter(
      (data) => data === uniqueIds[uniqueIds?.length - 1]
    );
    if (emailCount?.length <= 1) {
      getAssignedCodes(record?.setUniqueId, record?.email, record?.quantity);
    }
  }, [record]);

  const onCardCodeChange = (e) => {
    setCardCode(e?.target?.value);
  };
  const handleScan = async (scanData) => {
    if (scanData && scanData !== '' && !showDialog && !processing) {
      setCardCode(scanData?.split('/')[4]);
      setQRShow(false);
    }
  };
  const handleError = (err) => {
    console.error(err);
  };
  return (
    <>
      <Spin spinning={loading}>
        <Form name="Card Code Form" onFinish={onFinish} autoComplete="off">
          <Space>
            <Form.Item
              rules={[{ required: true, message: 'Please enter card code!' }]}
            >
              <Input
                placeholder="Enter card code"
                disabled={assigned}
                value={cardCode}
                onChange={onCardCodeChange}
                style={{ width: '800px' }}
              />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit" disabled={assigned}>
                Check
              </Button>
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                htmlType="button"
                disabled={assigned}
                onClick={() => setQRShow(!qrShow)}
              >
                {qrShow ? 'Close' : 'Add QR'}
              </Button>
            </Form.Item>
          </Space>
        </Form>
      </Spin>
      <div style={{ display: 'flex', justifyContent: 'start' }}>
        {qrShow && (
          <div style={{ width: '28%', height: '40%' }}>
            {!showDialog && !processing && (
              <QrReader
                facingMode="environment"
                delay={500}
                onError={handleError}
                onScan={handleScan}
              />
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default memo(CardCodeInput);
