import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  message,
  Modal,
  Radio,
  Row
} from 'antd';
import { useForm } from 'antd/lib/form/Form';
import Axios from 'axios';
import React, { useState } from 'react';
import ReactPhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import api from '../../../common/api';
import { RELEASE_WARNING } from '../../../common/constant';
import { catchErrorInSentry, formValidatorRules } from '../../../common/utils';
import { firebase } from '../../../firebase';

const { required } = formValidatorRules;

function ReleaseReassign({ record }) {
  const [showModalVisible, setShowModalVisible] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [showReassign, setShowReassign] = useState(false);
  const [showProfileForm, setShowProfileForm] = useState(false);
  const [releaseLoading, setReleaseLoading] = useState(false);
  const [reassignLoading, setReassignLoading] = useState(false);
  const [profileLoading, setProfileLoading] = useState(false);
  const [form] = useForm();
  const [reassignForm] = useForm();
  const [profileForm] = useForm();

  const showModal = () => {
    setShowModalVisible(true);
  };
  const handleOk = () => {
    form.submit();
    setShowModalVisible(false);
  };
  const handleCancel = () => {
    form.resetFields();
    setShowModalVisible(false);
  };

  const onFinish = (values) => {
    if (values?.cardAction === 'release') {
      setShowWarning(true);
      form.resetFields();
    } else {
      setShowReassign(true);
      form.resetFields();
    }
  };
  const handleWarningConfirm = async () => {
    firebase
      .auth()
      .currentUser.getIdToken(true)
      .then(async (token) => {
        setReleaseLoading(true);
        await Axios({
          method: 'post',
          url: `${process?.env?.REACT_APP_SERVER_URL}/v2/card/release-card`,
          data: {
            cardCode: record?.cardCode,
            linkMode: record?.linkMode
          },
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
          }
        })
          .then((res) => {
            if (res?.status === 200) {
              message.success('Card released successfully');
              setReleaseLoading(false);
              setShowWarning(false);
            }
          })
          .catch((error) => {
            catchErrorInSentry(error?.response?.data?.message);
            message.error('Something went wrong');
            setReleaseLoading(false);
          });
      });
  };
  const handleWarningDeny = () => {
    setShowWarning(false);
  };
  const reassignCard = async (email, cardCode, linkMode, token) => {
    setReassignLoading(true);
    await Axios({
      method: 'post',
      url: `${process?.env?.REACT_APP_SERVER_URL}/v2/card/reassign-card`,
      data: {
        newEmail: email,
        cardCode: cardCode,
        updatedLinkMode: linkMode
      },
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    })
      .then((res) => {
        setReassignLoading(false);
        setShowReassign(false);
        message.success(res?.data?.message);
      })
      .catch((error) => {
        setReassignLoading(false);
        setShowReassign(false);
        if (error?.response?.data?.code === 412) {
          profileForm.setFieldsValue({ email: email });
          setShowProfileForm(true);
        } else {
          catchErrorInSentry(error?.response?.data?.message);
          message.error(error?.response?.data?.message);
        }
      });
  };
  const handleReassign = (values) => {
    firebase
      .auth()
      .currentUser.getIdToken(true)
      .then(async (token) => {
        reassignCard(
          values?.email?.toLowerCase()?.trim(),
          record?.cardCode,
          record?.linkMode,
          token
        );
        reassignForm.resetFields();
      });
  };
  const handleOkReassign = () => {
    reassignForm.submit();
  };
  const handleCancelReassign = () => {
    reassignForm.resetFields();
    setShowReassign(false);
  };

  const handleProfile = async (values) => {
    const {
      name,
      email,
      title,
      company,
      phoneWork,
      phoneCountryCodeWork,
      sendLoginCredential,
      sendProductUpdates
    } = values;
    const emailToLowerCase = email?.toLowerCase()?.trim();
    try {
      setProfileLoading(true);
      firebase
        .auth()
        .currentUser.getIdToken(true)
        .then(async (token) => {
          await api({
            method: 'post',
            url: `${process?.env?.REACT_APP_PDF_DOWNLOAD_URL}/profile`,
            data: {
              name,
              email: emailToLowerCase,
              title,
              company,
              phoneWork,
              phoneCountryCodeWork,
              sendLoginCredential,
              sendProductUpdates
            }
          })
            .then(() => {
              setProfileLoading(false);
              reassignCard(
                email?.toLowerCase()?.trim(),
                record?.cardCode,
                record?.linkMode,
                token
              );
              setShowProfileForm(false);
            })
            .catch((error) => {
              setProfileLoading(false);
              console.log(error);
            });
        });
    } catch (error) {
      message.error('Something went wrong!');
      setProfileLoading(false);
      console.log(error);
    }
  };
  const handleProfileOk = () => {
    profileForm.submit();
  };
  const handleProfileCancel = () => {
    profileForm.resetFields();
    setShowProfileForm(false);
  };
  return (
    <div>
      <Button
        type="text"
        onClick={showModal}
        disabled={record?.showProfile === '' || !record?.showProfile}
        className="release-reassign-btn"
      >
        <img
          src="/icons/replace-release-icon.png"
          className="release-reassign-icon"
          alt="release/reassign"
        />
      </Button>
      <Modal
        visible={showModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        title="Release/Re-assign"
      >
        <Form onFinish={onFinish} form={form}>
          <Form.Item label="Card release/reassign" name="cardAction">
            <Radio.Group>
              <Radio.Button value="release">Release</Radio.Button>
              <Radio.Button value="reassign">Reassign</Radio.Button>
            </Radio.Group>
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        visible={showWarning}
        onOk={handleWarningConfirm}
        onCancel={handleWarningDeny}
        title="Release card"
        okButtonProps={{ loading: releaseLoading }}
      >
        {RELEASE_WARNING}
      </Modal>
      <Modal
        visible={showReassign}
        onOk={handleOkReassign}
        onCancel={handleCancelReassign}
        okButtonProps={{ loading: reassignLoading }}
      >
        <Form form={reassignForm} onFinish={handleReassign}>
          <div className="form-header">
            Enter email address whom you like to assign this?
          </div>
          <Form.Item label="Email" name="email" rules={[required]}>
            <Input placeholder="Enter email" type="email" />
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        visible={showProfileForm}
        onOk={handleProfileOk}
        onCancel={handleProfileCancel}
        okButtonProps={{ loading: profileLoading }}
      >
        <Form form={profileForm} onFinish={handleProfile}>
          <div className="form-header-left">Profile Details</div>
          <Form.Item label="Full Name" name="name" rules={[required]}>
            <Input placeholder="Enter full name" />
          </Form.Item>
          <Form.Item label="Title(optional)" name="title">
            <Input placeholder="Enter title" />
          </Form.Item>
          <Form.Item label="Company(optional)" name="company">
            <Input placeholder="Enter company name" />
          </Form.Item>
          <div className="form-header-left">Contact Information</div>
          <Form.Item label="Email" name="email" rules={[required]}>
            <Input placeholder="example@gmail.com" type="email" />
          </Form.Item>
          <Row align="middle">
            <Col span={10}>
              <Form.Item
                label="Phone Number | Work"
                name="phoneCountryCodeWork"
              >
                <ReactPhoneInput />
              </Form.Item>
            </Col>
            <Col span={14}>
              <Form.Item name="phoneWork">
                <Input placeholder="Enter work phone number" />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item name="sendLoginCredential" valuePropName="checked">
            <Checkbox>Send login instructions</Checkbox>
          </Form.Item>
          <Form.Item name="sendProductUpdates" valuePropName="checked">
            <Checkbox>Send product tips, updates and mobilo news</Checkbox>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}

export default ReleaseReassign;
