import _isEmpty from 'lodash/isEmpty';

export const handleTableChange = (pagination, filter, sorter, setters) => {
  const { setAction, setVariation, setSorterInfo, setSorting, setSortColumn } =
    setters;
  if (setAction) {
    setAction(filter?.action);
  }
  setVariation(filter?.variation);
  if (!_isEmpty(sorter)) {
    setSorterInfo(sorter);
    const { order, columnKey } = sorter;
    switch (order) {
      case 'ascend':
        switch (columnKey) {
          case 'orderNumber':
            setSorting('asc');
            setSortColumn('orderNumber');
            break;
          case 'updatedAt':
            setSorting('asc');
            setSortColumn('updatedAt');
            break;
          default:
            break;
        }
        break;
      case 'descend':
        switch (columnKey) {
          case 'orderNumber':
            setSorting('desc');
            setSortColumn('orderNumber');
            break;
          case 'updatedAt':
            setSorting('desc');
            setSortColumn('updatedAt');
            break;
          default:
            break;
        }
        break;

      default:
        setSorting('desc');
        setSortColumn('createdAt');
    }
  }
};
