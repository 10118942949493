import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import {
  Card,
  Col,
  Descriptions,
  Button,
  Empty,
  Row,
  Tabs,
  Spin,
  Typography,
  message,
  Collapse
} from 'antd';
import find from 'lodash/find';

import {
  CARD_TYPES,
  LIMIT_SECONDARY,
  NEW_COLLECTION_DATA_TYPE
} from '../../../common/constant';
import { firebase } from '../../../firebase';
import { catchErrorInSentry } from '../../../common/utils';

const { TabPane } = Tabs;
const { Panel } = Collapse;

function TabSection({ profileData }) {
  const { userId } = useParams();
  const [currentTab, setCurrentTab] = useState('cards');
  const [hasMore, setHasMore] = useState(true);
  const [cardsData, setCardsData] = useState([]);
  const [ordersData, setOrdersData] = useState([]);
  const [organisationData, setOrganizationData] = useState([]);
  const [cardCreditsData, setCardCreditsData] = useState([]);
  const [cardsLoading, setCardsLoading] = useState(false);
  const [cardsOffset, setCardsOffset] = useState();
  const [ordersOffset, setOrdersOffset] = useState();

  let cardsRef = firebase.firestore().collection('cards');
  let orderRef = firebase.firestore().collection('orders');
  let organisationRef = firebase.firestore().collection('organizations');
  let creditsRef = firebase.firestore().collection('credits');

  const getCards = (id) => {
    setCardsLoading(true);
    try {
      if (id || userId) {
        cardsRef = cardsRef
          .where('owner', '==', id || userId)
          .limit(LIMIT_SECONDARY);
        cardsRef.onSnapshot((querySnapshot) => {
          let cards = [];
          if (querySnapshot?.size === 0) {
            setCardsData();
          }
          querySnapshot.forEach((doc) => {
            cards.push(doc?.data());
          });
          setCardsOffset(querySnapshot?.docs[querySnapshot?.docs?.length - 1]);
          setCardsData(cards);
          setCardsLoading(false);
        });
      }
    } catch (error) {
      catchErrorInSentry(error);
      console.log(error);
    }
  };
  const getNextCards = () => {
    try {
      cardsRef = cardsRef
        .where('owner', '==', profileData?.id)
        .limit(LIMIT_SECONDARY)
        .startAfter(cardsOffset);
      cardsRef.onSnapshot((querySnapshot) => {
        const items = [];
        querySnapshot.forEach((doc) => {
          let orderNumber = doc?.data()?.orderNumber;
          if (doc?.data()?.shippingAddress?.order_number) {
            orderNumber = `${orderNumber} (${
              doc?.data()?.shippingAddress.order_number
            })`;
          }
          items.push({ ...doc?.data(), docId: doc?.id, orderNumber });
        });
        if (items?.length < LIMIT_SECONDARY) {
          setHasMore(false);
        }
        setCardsOffset(querySnapshot?.docs[querySnapshot?.docs?.length - 1]);
        setCardsData([...cardsData, ...items]);
      });
    } catch (error) {
      catchErrorInSentry(error);
      console.log(error);
    }
  };
  const getOrders = (email) => {
    try {
      if (email) {
        orderRef = orderRef.where('user', '==', email).limit(LIMIT_SECONDARY);
        orderRef.onSnapshot((querySnapshot) => {
          let orders = [];
          if (querySnapshot?.size === 0) {
            setOrdersData();
          }
          querySnapshot.forEach((doc) => {
            orders.push(doc?.data());
          });
          setOrdersOffset(querySnapshot?.docs[querySnapshot?.docs?.length - 1]);
          setOrdersData(orders);
        });
      }
    } catch (error) {
      catchErrorInSentry(error);
      console.log(error);
    }
  };
  const getNextOrders = async () => {
    try {
      orderRef = orderRef
        .where('user', '==', profileData?.user)
        .limit(LIMIT_SECONDARY)
        .startAfter(ordersOffset);
      orderRef.onSnapshot((querySnapshot) => {
        const items = [];
        querySnapshot.forEach((doc) => {
          let orderNumber = doc?.data()?.orderNumber;
          if (doc?.data()?.shippingAddress?.order_number) {
            orderNumber = `${orderNumber} (${
              doc?.data()?.shippingAddress.order_number
            })`;
          }
          items.push({ ...doc?.data(), docId: doc?.id, orderNumber });
        });
        if (items?.length < LIMIT_SECONDARY) {
          setHasMore(false);
        }
        setOrdersOffset(querySnapshot?.docs[querySnapshot?.docs?.length - 1]);
        setOrdersData([...ordersData, ...items]);
      });
    } catch (error) {
      catchErrorInSentry(error);
      console.log(error);
    }
  };
  const getOrganizations = async (orgId) => {
    try {
      const orgData = await organisationRef.doc(orgId).get();
      setOrganizationData(orgData?.data());
    } catch (error) {
      catchErrorInSentry(error);
      console.log(error);
    }
  };
  const getCredits = async (id) => {
    if (id) {
      let cardCredits = await creditsRef.doc(id).collection('new').get();
      cardCredits = cardCredits.docs;
      let initialCardCredits = [];
      for (const card of cardCredits) {
        initialCardCredits.push({ cardType: card?.id, cardData: card?.data() });
      }
      setCardCreditsData(initialCardCredits);
    } else {
      message.error('User not found');
    }
  };
  const getData = (currentTab) => {
    switch (currentTab) {
      case 'cards':
        getCards(profileData?.id);
        break;
      case 'orders':
        getOrders(profileData?.user);
        break;
      case 'organizations':
        getOrganizations(profileData?.orgId);
        break;
      case 'credits':
        getCredits(profileData?.userId);
        break;
      default:
        break;
    }
  };
  useEffect(() => {
    getData(currentTab);
  }, [currentTab]);

  if (cardsLoading && !cardsData) return <Spin />;
  return (
    <>
      <Tabs
        defaultActiveKey="cards"
        onTabClick={(e) => {
          setCurrentTab(e);
        }}
      >
        <TabPane tab="Cards" key="cards">
          {cardsData?.length > 0 ? (
            <Row gutter={[16, 16]}>
              {cardsData?.map((card) => {
                return (
                  <Col span={8}>
                    <Card
                      title={card?.cardCode}
                      actions={[
                        <Typography.Text type="secondary" italic>
                          <a href={`/v2-orders/${card?.orderUniqueId}`}>
                            Order Details
                          </a>
                        </Typography.Text>,
                        <Typography.Text type="secondary" italic>
                          <a href={`${card?.activeLink}`} target="_blank">
                            Active Link
                          </a>
                        </Typography.Text>
                      ]}
                    >
                      <Descriptions column={1}>
                        <Descriptions.Item label="Order Number">
                          {card?.orderNumber}
                        </Descriptions.Item>
                        <Descriptions.Item label="Set unique id">
                          {card?.setUniqueId}
                        </Descriptions.Item>
                      </Descriptions>
                    </Card>
                  </Col>
                );
              })}
              <Col span={24}>
                <Button
                  disabled={!hasMore}
                  onClick={() => {
                    if (hasMore) {
                      getNextCards();
                    }
                  }}
                >
                  Load more cards
                </Button>
              </Col>
            </Row>
          ) : (
            <Empty />
          )}
        </TabPane>
        <TabPane tab="Orders" key="orders">
          {ordersData?.length > 0 ? (
            <>
              <Row gutter={[16, 16]}>
                {ordersData?.map((order) => {
                  return (
                    <Col span={8}>
                      <Card
                        title={
                          <Typography.Title style={{ margin: 0 }} level={5}>
                            <a href={`/v2-orders/${order?.orderUniqueId}`}>
                              {order?.cardType}
                            </a>
                          </Typography.Title>
                        }
                      >
                        <Descriptions column={1}>
                          <Descriptions.Item label="Order Number">
                            {order?.orderNumber}
                          </Descriptions.Item>
                          <Descriptions.Item label="Woo commerece Order Number">
                            {order?.shippingAddress?.order_number ?? '-'}
                          </Descriptions.Item>
                          <Descriptions.Item label="Quantity">
                            {order?.quantity}
                          </Descriptions.Item>
                          <Descriptions.Item label="Status">
                            {order?.status}
                          </Descriptions.Item>
                        </Descriptions>
                      </Card>
                    </Col>
                  );
                })}
              </Row>
              <Row>
                <Col>
                  <Button
                    disabled={!hasMore}
                    onClick={() => {
                      if (hasMore) {
                        getNextOrders();
                      }
                    }}
                  >
                    Load more orders
                  </Button>
                </Col>
              </Row>
            </>
          ) : (
            <Empty />
          )}
        </TabPane>
        <TabPane tab="Organisation" key="organizations">
          {organisationData?.id ? (
            <Row>
              <Col span={8}>
                <Card>
                  <Row justify="space-between">
                    <Col>
                      <Typography.Text>
                        {organisationData?.name}
                      </Typography.Text>
                    </Col>
                    <Col>
                      <Typography.Text type="secondary" italic>
                        <a href={`/v2-organizations/${organisationData?.id}`}>
                          Visit organisation
                        </a>
                      </Typography.Text>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          ) : (
            <Empty />
          )}
        </TabPane>
        <TabPane tab="Credits" key="credits">
          <Typography.Title level={4}>
            Card credits for new employee
          </Typography.Title>
          {cardCreditsData?.length ? (
            <Collapse>
              {cardCreditsData?.map((cardCreditData, i) => {
                let total = 0;
                for (let value of Object.values(cardCreditData?.cardData)) {
                  total = total + value;
                }
                const getCardName = (cardData) => {
                  const keys = Object.keys(cardData);
                  let cardTp = [];
                  keys.forEach((i) => {
                    find(NEW_COLLECTION_DATA_TYPE, (e) => {
                      if (e?.type === i) {
                        cardTp.push({ name: e?.name, count: cardData[i] });
                      }
                    });
                  });
                  return (
                    <>
                      {cardTp?.map((type) => {
                        return (
                          <Descriptions>
                            <Descriptions.Item label={type?.name}>
                              {type?.count}
                            </Descriptions.Item>
                          </Descriptions>
                        );
                      })}
                    </>
                  );
                };
                const getTitle = (cardType) => {
                  for (const [key, value] of Object.entries(CARD_TYPES)) {
                    if (key?.toLowerCase() === cardType) {
                      return (
                        <Row justify="space-between">
                          <Col>{value?.name}</Col>
                          <Col>{total}</Col>
                        </Row>
                      );
                    }
                  }
                };
                return (
                  <>
                    <Panel header={getTitle(cardCreditData?.cardType)} key={i}>
                      {getCardName(cardCreditData?.cardData)}
                    </Panel>
                  </>
                );
              })}
            </Collapse>
          ) : (
            <Empty />
          )}
        </TabPane>
      </Tabs>
    </>
  );
}

export default TabSection;
