import React, { useState, useEffect } from 'react'
import { firebase } from './firebase'
import { notification } from 'antd';

import Loader from './components/Loader/Loader';

export const AuthContext = React.createContext();

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = React.useState(null);
  const [data, setData] = React.useState(null)
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        firebase.firestore().collection('systemUser').doc(user.uid).get().then((docData) => {
          if (docData.exists) {
            setData(docData.data())
          } else {
            firebase.auth().signOut();
            notification['error']({
              message: 'Authentication Error',
              description: 'You are not authorized'
            });
          }
        })
      }
      setCurrentUser(user);
      setLoading(false);
    });
  }, []);

  if (loading) return <Loader />
  return (
    <AuthContext.Provider value={{ currentUser, data }}>
      {children}
    </AuthContext.Provider>
  )
}

