import React, { useState, useEffect } from 'react';

import { Table, Row, Col, Button, Typography, Card, Tag, Tooltip } from 'antd';
import { UpCircleOutlined } from '@ant-design/icons';

import { firebase } from '../../../firebase';
import Assign from './Assign';
import { withRouter } from 'react-router';
import EditAssign from './Edit';
import Loader from '../../Loader/Loader';
import { LIMIT_PRIMARY } from '../../../common/constant';
import { scrollToTop } from '../../../common/utils';

function AssignWork() {
  const [assigned, setAssigned] = useState([]);
  const [unassigned, setUnassigned] = useState([]);
  const [scrollTopVisible, setScrollTopVisible] = useState(false);
  const [offset, setOffset] = useState();
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(false);

  let assignedRef = firebase
    .firestore()
    .collection('vendor')
    .where('isAssigned', '==', true);
  assignedRef = assignedRef.limit(LIMIT_PRIMARY);
  const unassignedRef = firebase
    .firestore()
    .collection('vendor')
    .where('isAssigned', '==', false);

  const getAssigned = () => {
    setLoading(true);
    assignedRef.onSnapshot((querySnapshot) => {
      const items = [];
      querySnapshot.forEach((doc) => {
        items.push(doc?.data());
      });
      setOffset(querySnapshot?.docs[querySnapshot?.docs?.length - 1]);
      setAssigned(items);
      setLoading(false);
      if (items?.length < LIMIT_PRIMARY) {
        setHasMore(false);
      }
    });
  };
  const getNextAssigned = () => {
    assignedRef.startAfter(offset).onSnapshot((querySnapshot) => {
      const items = [];
      querySnapshot.forEach((doc) => {
        items.push(doc?.data());
      });
      if (items.length < LIMIT_PRIMARY) {
        setHasMore(false);
      }
      setOffset(querySnapshot?.docs[querySnapshot?.docs?.length - 1]);
      setAssigned([...assigned, ...items]);
      setLoading(false);
    });
  };
  function getUnassigned() {
    setLoading(true);
    unassignedRef.onSnapshot((querySnapshot) => {
      const items = [];
      querySnapshot.forEach((doc) => {
        items.push(doc?.data());
      });
      setUnassigned(items);
      setLoading(false);
    });
  }

  useEffect(() => {
    getAssigned();
    getUnassigned();
    window.addEventListener('scroll', () => {
      setScrollTopVisible(window.pageYOffset > 300);
    });
  }, []);
  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'Email ID',
      dataIndex: 'email',
      key: 'email'
    },
    {
      title: 'Phone',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber'
    },
    {
      title: 'Services',
      dataIndex: 'vendorType',
      key: 'vendorType',
      render: (tags) => (
        <>
          {tags.map((tag) => {
            return <Tag key={tag}>{tag?.toUpperCase()}</Tag>;
          })}
        </>
      )
    },
    {
      title: 'Countries',
      dataIndex: 'countries',
      key: 'countries',
      width: 200,
      render: (tags) => (
        <>
          {tags?.length < 6 ? (
            tags.map((tag) => {
              return <Tag key={tag}>{tag?.toUpperCase()}</Tag>;
            })
          ) : (
            <Tooltip
              autoAdjustOverflow={false}
              overlayStyle={{ height: 300, overflowY: 'scroll' }}
              color="black"
              placement="rightTop"
              title={tags.map((tag, index) => {
                if (index > 5)
                  return (
                    <Typography.Text style={{ color: 'white' }}>
                      {tag},{' '}
                    </Typography.Text>
                  );
              })}
            >
              {tags.map((tag, index) => {
                if (index < 5) return <Tag key={tag}>{tag?.toUpperCase()}</Tag>;
              })}{' '}
              +{tags?.length - 5}
            </Tooltip>
          )}
        </>
      )
    },
    {
      title: 'Card Types',
      dataIndex: 'cardType',
      key: 'cardType',
      render: (tags) => (
        <>
          {tags.map((tag) => {
            return <Tag key={tag}>{tag?.toUpperCase()}</Tag>;
          })}
        </>
      )
    },
    {
      title: 'Actions',
      key: 'action',
      render: (text, record) => <EditAssign record={record} />
    }
  ];

  return (
    <Card
      extra={<Assign vendors={unassigned || []} />}
      title={
        <Typography.Title style={{ margin: 0 }} level={3}>
          Assign Work
        </Typography.Title>
      }
    >
      <Table
        dataSource={assigned}
        columns={columns}
        bordered={true}
        pagination={false}
        loading={loading}
      />
      <Row>
        <Col offset={10} span={12}>
          <Button
            className="margin-t-16"
            onClick={() => {
              if (hasMore) {
                getNextAssigned();
              }
            }}
            disabled={!hasMore}
          >
            Load More
          </Button>
        </Col>
        <Col span={2}>
          {scrollTopVisible && (
            <Button
              shape="circle"
              onClick={() => {
                scrollToTop();
              }}
              className="margin-t-16 scroll-top-btn"
            >
              <UpCircleOutlined />
            </Button>
          )}
        </Col>
      </Row>
    </Card>
  );
}

export default withRouter(AssignWork);
