import React, { useState, useEffect } from 'react';
import { Link, withRouter } from 'react-router-dom';

import {
  Typography,
  Table,
  Card,
  Button,
  Tag,
  Space,
  Switch,
  Modal,
  Row,
  Col,
  Select
} from 'antd';
import { DeleteOutlined, UpCircleOutlined } from '@ant-design/icons';

import { firebase } from '../../../firebase';
import { LIMIT_PRIMARY } from '../../../common/constant';
import AddVendor from './AddVendor';
import EditVendor from './EditVendor';
import './vendor.css';
import { scrollToTop } from '../../../common/utils';
import api from '../../../common/api';

const { confirm } = Modal;
const { Option } = Select;

function Vendors() {
  const [vendors, setVendors] = useState([]);
  const [scrollTopVisible, setScrollTopVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [offset, setOffset] = useState();
  const [shippingService, setShippingService] = useState([]);
  let ref = firebase.firestore().collection('vendor');
  ref = ref.limit(LIMIT_PRIMARY);

  function getVendors() {
    setLoading(true);
    ref.onSnapshot((querySnapshot) => {
      const items = [];
      querySnapshot.forEach((doc) => {
        items.push(doc?.data());
      });
      if (items?.length < LIMIT_PRIMARY) {
        setHasMore(false);
      }
      setOffset(querySnapshot?.docs[querySnapshot?.docs?.length - 1]);
      setVendors(items);
      setLoading(false);
    });
  }
  const getNextVendors = () => {
    ref.startAfter(offset).onSnapshot((querySnapshot) => {
      const items = [];
      querySnapshot.forEach((doc) => {
        items.push(doc?.data());
      });
      if (items?.length < LIMIT_PRIMARY) {
        setHasMore(false);
      }
      setOffset(querySnapshot?.docs[querySnapshot?.docs?.length - 1]);
      setVendors([...vendors, ...items]);
      setLoading(false);
    });
  };

  const getShippingServiceList = async () => {
    const config = {
      method: 'get',
      url: `${process.env.REACT_APP_API_GTW_URL}/v1/factory/shipping/get-shipping-services`
    };
    const shippingService = await api(config);
    setShippingService(shippingService?.data?.data);
  };

  const handleDefaultShippingService = async (service, record) => {
    const config = {
      method: 'post',
      url: `${process.env.REACT_APP_API_GTW_URL}/v1/factory/shipping/set-default-shipping-service`,
      data: {
        shippingService: service,
        vendorId: record
      }
    };
    await api(config);
  };
  useEffect(() => {
    getVendors();
    getShippingServiceList();
    window.addEventListener('scroll', () => {
      setScrollTopVisible(window.screenY > 300);
    });
  }, []);

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => {
        return <Link to={`/v2-vendors/${record?.id}`}>{text}</Link>;
      }
    },
    {
      title: 'Email ID',
      dataIndex: 'email',
      key: 'email'
    },
    {
      title: 'Shipping Aggregators',
      dataIndex: 'shipping_aggregators',
      key: 'shipping_aggregators',
      render: (text, record) => {
        return (
          <Select
            placeholder="Not Specified"
            onChange={(e) => {
              handleDefaultShippingService(e, record?.id);
            }}
            defaultValue={record?.defaultShippingService}
          >
            {React.Children.toArray(
              shippingService?.map((item) => {
                return <Option value={item}>{item}</Option>;
              })
            )}
          </Select>
        );
      }
    },
    {
      title: 'Phone',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber'
    },
    {
      title: 'Services',
      dataIndex: 'vendorType',
      key: 'vendorType',
      render: (tags) => (
        <>
          {tags?.map((tag) => {
            return <Tag key={tag}>{tag?.toUpperCase()}</Tag>;
          })}
        </>
      )
    },
    {
      title: 'Actions',
      key: 'action',
      render: (text, record) => {
        function showConfirm() {
          confirm({
            title: `Are you sure you want to delete Vendor '${record?.name}''`,
            onOk() {
              firebase
                .firestore()
                .collection('vendor')
                .doc(record?.id)
                .delete();
              firebase
                .firestore()
                .collection('systemUser')
                .where('vendorId', '==', record?.id)
                .get()
                .then((querySnapshot) => {
                  const batch = firebase.firestore().batch();
                  querySnapshot.forEach(function (doc) {
                    batch.delete(doc?.ref);
                  });
                  return batch.commit();
                });
            },
            onCancel() {}
          });
        }
        return (
          <Space>
            <Switch
              size="small"
              checked={record?.isActive}
              onChange={(e) =>
                firebase
                  .firestore()
                  .collection('vendor')
                  .doc(record?.id)
                  .update({
                    isActive: e
                  })
              }
            />
            <EditVendor record={record} />
            <Button
              type="text"
              icon={<DeleteOutlined style={{ color: 'red' }} />}
              onClick={showConfirm}
            />
          </Space>
        );
      }
    }
  ];
  return (
    <Card
      size="small"
      className="vendors-container"
      title={<Typography.Title level={3}>Vendors</Typography.Title>}
      extra={<AddVendor />}
    >
      <Table
        dataSource={vendors}
        columns={columns}
        pagination={false}
        bordered={true}
        loading={loading}
      />
      <Row>
        <Col offset={10} span={12}>
          <Button
            className="margin-t-16"
            onClick={() => {
              if (hasMore) {
                getNextVendors();
              }
            }}
            disabled={!hasMore}
          >
            Load More
          </Button>
        </Col>
        <Col span={2}>
          {scrollTopVisible && (
            <Button
              shape="circle"
              onClick={() => {
                scrollToTop();
              }}
              className="scroll-to-top-btn"
            >
              <UpCircleOutlined />
            </Button>
          )}
        </Col>
      </Row>
    </Card>
  );
}

export default withRouter(Vendors);
