import { Button, Form, Input, Modal } from 'antd';
import React, { useState } from 'react';
import { EditOutlined } from '@ant-design/icons';
import { useForm } from 'antd/lib/form/Form';
import { useEffect } from 'react';
import api from '../../../../common/api';

function AddressForm({ record, orderUniqueId, setIsEditAddress }) {
  const [visible, setVisible] = useState(false);
  const [form] = useForm();

  const showEditAddressModal = () => {
    setVisible(true);
  };

  useEffect(() => {
    form.resetFields();
  }, []);

  const handleOk = () => {
    form.submit();
  };
  const handleCancel = () => {
    setVisible(false);
  };
  const onFinish = async (value) => {
    const {
      first_name,
      last_name,
      address_1,
      address_2,
      city,
      state,
      postcode,
      country
    } = value;
    const config = {
      method: 'POST',
      url: `${process.env.REACT_APP_API_GTW_URL}/v1/factory/shipping/update-order-shipping-address?orderUniqueId=${orderUniqueId}`,
      data: {
        first_name: first_name,
        last_name: last_name,
        address1: address_1,
        address2: address_2,
        city,
        state,
        postcode,
        country,
        company: record?.shippingAddress?.company,
        email: record?.shippingAddress?.email,
        orderUniqueId: orderUniqueId
      }
    };
    await api(config);
    setVisible(false);
    setIsEditAddress(orderUniqueId);
  };

  return (
    <div>
      <Button type="text" onClick={showEditAddressModal}>
        <EditOutlined />
      </Button>
      <Modal
        onOk={handleOk}
        visible={visible}
        onCancel={handleCancel}
        title="Edit Address"
      >
        <Form
          initialValues={record?.shippingAddress}
          form={form}
          onFinish={onFinish}
        >
          <Form.Item name="first_name" label="Firstname">
            <Input />
          </Form.Item>
          <Form.Item name="last_name" label="Lastname">
            <Input />
          </Form.Item>
          <Form.Item name="address_1" label="Address Line 1">
            <Input />
          </Form.Item>
          <Form.Item name="address_2" label="Address Line 2">
            <Input />
          </Form.Item>
          <Form.Item name="city" label="City">
            <Input />
          </Form.Item>
          <Form.Item name="postcode" label="Postcode">
            <Input />
          </Form.Item>
          <Form.Item name="state" label="State">
            <Input />
          </Form.Item>
          <Form.Item name="country" label="Country">
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}

export default AddressForm;
