import React, { useState } from 'react';
import { Button, Modal, Form, Input, Select, message, Upload } from 'antd';
import app from 'firebase/app';
import { firebase } from '../../../firebase';
import { ImportOutlined, CheckCircleOutlined } from '@ant-design/icons';
import { catchErrorInSentry, formValidatorRules } from '../../../common/utils';
import api from '../../../common/api';
import { filter } from 'lodash';

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 }
};
const tailLayout = {
  wrapperCol: { offset: 8, span: 16 }
};

const { email } = formValidatorRules;

function AddMember({ orgId }) {
  const [visible, setVisible] = useState(false);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [uploadingCsv, setUploadingCsv] = useState(false);
  const [csvImports, setCsvImports] = useState([]);
  const [csvFlag, setCsvFlag] = useState(false);
  const orgRef = firebase.firestore().collection('organizations').doc(orgId);
  const profileRef = firebase.firestore().collection('profiles');

  const handleUser = async (event) => {
    let { member } = event;
    let memberIds;
    if (!member) {
      memberIds = [...csvImports];
    } else {
      await firebase
        .firestore()
        .collection('profiles')
        .where('user', '==', member?.toLowerCase())
        .get()
        .then((data) => {
          if (data?.docs?.length) {
            data.docs.forEach((doc) => {
              memberIds = [doc?.data()?.userId];
            });
          } else {
            memberIds = [];
            message.error('Profile not found');
          }
        })
        .catch((error) => {
          catchErrorInSentry(error);
          console.log(error);
        });
    }
    if (!memberIds?.length && !member?.length) {
      message.error('Please add members');
      return;
    }
    setLoading(true);
    for (const id of memberIds) {
      const doc = await profileRef.doc(id).get();
      if (
        doc?.data()?.orgId === '' ||
        doc?.data()?.orgId === null ||
        doc?.data()?.orgId === undefined ||
        !doc?.data()?.orgId
      ) {
        await profileRef.doc(id).update({
          orgId,
          qrCode: app?.firestore?.FieldValue?.delete()
        });
      } else {
        message.warning('Member already belongs to some other organization');
        memberIds = filter(memberIds, (item) => {
          return item !== id;
        });
      }
    }
    orgRef.get().then((org) => {
      orgRef
        .set(
          { members: [...new Set([...org?.data()?.members, ...memberIds])] },
          { merge: true }
        )
        .then(() => {
          setLoading(false);
          setVisible(false);
          setCsvImports([]);
          form.resetFields();
          setCsvFlag(false);
          if (memberIds?.length) {
            for (const userId of memberIds) {
              const config = {
                method: 'post',
                url: `${process?.env?.REACT_APP_SERVER_URL_V2}/license/clear`,
                data: {
                  userId,
                  orgId
                }
              };
              api(config).catch((error) => {
                console.log(error);
              });
            }
          }
        });
    });
  };
  const showModal = () => {
    setVisible(true);
  };

  return (
    <>
      <Button type="primary" onClick={() => showModal()}>
        Add Member
      </Button>
      <Modal
        title="Add Members"
        visible={visible}
        footer={null}
        onCancel={() => {
          setVisible(false);
          form.resetFields();
          setCsvImports([]);
          setCsvFlag(false);
        }}
      >
        <Form {...layout} form={form} name="basic" onFinish={handleUser}>
          <Form.Item label="Enter email" name="member" rules={[email]}>
            <Input />
          </Form.Item>
          <Form.Item {...layout} label="Import CSV">
            <Upload
              accept=".csv"
              showUploadList={false}
              beforeUpload={(file) => {
                const reader = new FileReader();
                reader.onload = async (e) => {
                  const profileIds = [];
                  const csvArray = e?.target?.result?.split('\n');
                  setUploadingCsv(true);
                  for (const email of csvArray) {
                    if (email?.length) {
                      await firebase
                        .firestore()
                        .collection('profiles')
                        .where('user', '==', email?.toLowerCase()?.trim())
                        .get()
                        .then((data) => {
                          data.docs.forEach((doc) => {
                            profileIds.push(doc?.data()?.userId);
                          });
                        });
                    }
                  }
                  setCsvImports([...new Set([...csvImports, ...profileIds])]);
                  setUploadingCsv(false);
                };
                reader.readAsText(file);
                return false;
              }}
            >
              <Button
                disabled={csvFlag}
                icon={
                  csvImports?.length > 0 ? (
                    <CheckCircleOutlined />
                  ) : (
                    <ImportOutlined />
                  )
                }
                loading={uploadingCsv}
              >
                CSV
              </Button>
            </Upload>
          </Form.Item>
          <Form.Item {...tailLayout}>
            <Button loading={loading} type="primary" htmlType="submit">
              Add Member
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}

export default AddMember;
