import { Collapse, Row } from 'antd';
import React from 'react';
import { CaretRightOutlined } from '@ant-design/icons';
import { newCollectionDataType } from './newCollectionDataType';
const { Panel } = Collapse;

const CommonCollapse = ({ header, panelData }) => {
  // find total cards in particular collapse
  const total = panelData?.reduce(
    (accumulator, current) => accumulator + current?.cardValue,
    0
  );

  // find card_only value and push into array beginning position
  const findCardOnlyIndex = panelData?.findIndex((data) => {
    return data?.cardKey === 'card_only';
  });
  const findCardOnly = panelData?.find((data) => {
    return data?.cardKey === 'card_only';
  });

  if (findCardOnlyIndex !== -1) {
    // delete card_only value  Obj in between the array
    panelData.splice(findCardOnlyIndex, 1);
    // push card_only value Obj at array beginning
    panelData.unshift(findCardOnly);
  }

  return (
    <>
      {/* if digital card then only not show collapse */}
      {header === 'Digital Card' ? (
        <Row justify="space-between" style={{ padding: '0px 16px 0px 40px' }}>
          <p>Digital Card</p>
          <p>{panelData && panelData[0]?.cardValue}</p>
        </Row>
      ) : (
        <Collapse
          bordered={false}
          expandIcon={({ isActive }) => (
            <CaretRightOutlined rotate={isActive ? 90 : 0} />
          )}
          className="site-collapse-custom-collapse"
          ghost
        >
          <Panel
            header={header}
            className="site-collapse-custom-panel"
            extra={total || '0'}
          >
            <>
              {panelData &&
                panelData.length &&
                panelData?.map((data) => {
                  return (
                    <>
                      {newCollectionDataType?.map((singleData) => {
                        return (
                          <>
                            {singleData?.type === data?.cardKey && (
                              <>
                                <Row
                                  justify="space-between"
                                  style={{ marginLeft: '20px' }}
                                >
                                  <p>{singleData?.name}</p>
                                  <p>{data?.cardValue || '0'}</p>
                                </Row>
                              </>
                            )}
                          </>
                        );
                      })}
                    </>
                  );
                })}
            </>
          </Panel>
        </Collapse>
      )}
    </>
  );
};

export default CommonCollapse;
