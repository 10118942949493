import React from 'react';
import { Button, Modal, Form, Input, Select, message, Checkbox } from 'antd';
import { firebase } from '../../../firebase';
import { nanoid } from 'nanoid';
import { ACTION_TYPE } from '../../../common/constant';

const axios = require('axios');

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 }
};
const tailLayout = {
  wrapperCol: { offset: 8, span: 16 }
};

function AddOrganization() {
  const [visible, setVisible] = React.useState(false);
  const [form] = Form.useForm();
  const [loading, setLoading] = React.useState(false);

  const handleSubmit = async (event) => {
    const { name } = event;
    setLoading(true);
    try {
      const body = {
        app_id: process.env.REACT_APP_FIREBASE_PROJECT_ID
      };
      const config = { locked: false, show: true };
      const cardCode = nanoid(12);
      await firebase.firestore().collection('organizations').doc(cardCode).set({
        name,
        admins: [],
        members: [],
        cardCredits: 0,
        digitalCard: '',
        enterpriseSeats: 0,
        facebookUrl: '',
        googlemaps: '',
        instagramUrl: '',
        leadPage: '',
        logoUrl: '',
        website: '',
        youtubeUrl: '',
        id: cardCode,
        customLeadPage: false,
        customSocialPage: false,
        addressLine1: '',
        addressLine2: '',
        city: '',
        country: '',
        state: '',
        zipcode: '',
        companyName: '',
        stockTicker: '',
        twitterUrl: '',
        linkedInUrl: '',
        isMicrosoftSsoMandatory: false
      });
      await firebase
        .firestore()
        .collection('organizations')
        .doc(cardCode)
        .collection('config')
        .doc('fieldConfig')
        .set({
          addressLine1Config: config,
          addressLine2Config: config,
          cityConfig: config,
          companyConfig: config,
          countryConfig: config,
          emailConfig: config,
          facebookConfig: config,
          fullNameConfig: config,
          instagramConfig: config,
          appleAppStoreConfig: config,
          calendarLinkConfig: config,
          linkedInConfig: config,
          mobileNumConfig: config,
          phoneNumConfig: config,
          pinterestConfig: config,
          playstoreConfig: config,
          soundcloudConfig: config,
          snapchatConfig: config,
          spotifyConfig: config,
          stateConfig: config,
          titleConfig: config,
          twitterConfig: config,
          venmoConfig: config,
          webLinkConfig: config,
          webLink01Config: config,
          webLink02Config: config,
          websiteConfig: config,
          whatsappConfig: config,
          youtubeUrlConfig: config,
          zipcodeConfig: config,
          tikTokConfig: config,
          paypalConfig: config,
          weChatConfig: config,
          twitchConfig: config,
          cashAppConfig: config,
          parlerConfig: config,
          qqAppConfig: config,
          faxConfig: config,
          realEstateLicenseConfig: config,
          profileNoteConfig: config,
          certificatesConfig: config,
          yelpConfig: config,
          signalConfig: config,
          discordConfig: config,
          skypeConfig: config,
          telegramConfig: config,
          messengerConfig: config
        });
      await firebase
        .firestore()
        .collection('organizations')
        .doc(cardCode)
        .collection('config')
        .doc('overrideConfig')
        .set({
          overrideOrgAddress: false,
          overrideOrgCity: false,
          overrideOrgCountry: false,
          overrideOrgState: false,
          overrideOrgZipcode: false,
          overrideOrgCompany: false,
          overrideOrgWeb: false,
          overrideOrgLogo: false,
          overrideOrgAvatar: false,
          overrideOrgFacebook: false,
          overrideOrgInstagram: false,
          overrideOrgYoutube: false,
          overrideOrgLinkedInPage: false,
          overrideOrgTwitter: false
        });

      await axios
        .post(`${process.env.REACT_APP_SERVER_URL}/v2/algolia/token`, body)
        .then((res) => {
          if (res?.data?.data) {
            axios
              .post(
                `${process.env.REACT_APP_SERVER_URL}/v2/algolia/sync`,
                {
                  type: process.env.REACT_APP_ALGOLIA_INDEX || 'organizations',
                  action: ACTION_TYPE.CREATE,
                  id: cardCode
                },
                {
                  headers: {
                    authorization: `Bearer ${res?.data?.data}`
                  }
                }
              )
              .then((res) => {
                setLoading(false);
                setVisible(false);
                form.resetFields();
                message.success('Organization added successfully');
              });
          }
        });
    } catch (error) {
      alert(error);
    }
  };

  const showModal = () => {
    setVisible(true);
  };

  return (
    <>
      <Button type="primary" onClick={() => showModal()}>
        Create Organization
      </Button>
      <Modal
        title="Create Organization"
        visible={visible}
        footer={null}
        onCancel={() => setVisible(false)}
      >
        <Form
          {...layout}
          form={form}
          name="basic"
          onFinish={handleSubmit}
          initialValues={{ remember: true }}
        >
          <Form.Item
            label="Name"
            name="name"
            rules={[{ required: true, message: 'Please enter Name!' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item {...tailLayout}>
            <Button type="primary" loading={loading} htmlType="submit">
              Create
            </Button>
            <Button
              type="default"
              onClick={() => {
                form.resetFields();
                setVisible(false);
              }}
              style={{ marginLeft: '1em' }}
            >
              Cancel
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}

export default AddOrganization;
