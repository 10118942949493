import React, { useEffect, useState } from 'react'
import { Descriptions, Modal, Image } from 'antd'
import _get from 'lodash/get'
import { firebase } from '../../../../firebase';

export default function ({ visible, onCancel, designId }) {
  const [design, setDesign] = useState({})
  const ref = firebase
    .firestore()
    .collection("designSet")

  useEffect(() => {
    if (visible && designId) {
      ref.doc(designId)
        .onSnapshot((querySnapshot) => {
          setDesign(querySnapshot.data())
        })
    }
  }, [designId, visible])
  return (
    <Modal
      title="Previous Design"
      visible={visible}
      footer={null}
      onCancel={onCancel}
      width={1024}
    >
      <Descriptions
        bordered
        column={2}
      >
        <Descriptions.Item label="Front Image" span={2} >
          <Image
            style={{ maxHeight: 200, maxWidth: 300 }}
            src={_get(design, _get(design, 'qrSide') === 'FRONT' ? 'cardDesign[0].qrReference' : 'cardDesign[0].frontImageUrl')}
          />
        </Descriptions.Item>
        <Descriptions.Item label="Back Image" span={2}>
          <Image
            style={{ maxHeight: 200, maxWidth: 300 }}
            src={_get(design, _get(design, 'qrSide') === 'BACK' ? 'cardDesign[0].qrReference' : 'cardDesign[0].backImageUrl')}
          />
        </Descriptions.Item>
        <Descriptions.Item label="cardHeight">{_get(design, 'cardHeight')}</Descriptions.Item>
        <Descriptions.Item label="cardWidth">{_get(design, 'cardWidth')}</Descriptions.Item>
        <Descriptions.Item label="qrHeight">{_get(design, 'qrHeight')}</Descriptions.Item>
        <Descriptions.Item label="qrWidth">{_get(design, 'qrWidth')}</Descriptions.Item>
        <Descriptions.Item label="qrX">{_get(design, 'qrX')}</Descriptions.Item>
        <Descriptions.Item label="qrY">{_get(design, 'qrY')}</Descriptions.Item>
        <Descriptions.Item label="qrSide">{_get(design, 'qrSide')}</Descriptions.Item>
      </Descriptions>
    </Modal>
  )
}
