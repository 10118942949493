import React, { useState } from 'react';
import { Button, Modal, Form, Input, Select, message, Upload } from 'antd';
import { firebase } from '../../../firebase';
import { ImportOutlined, CheckCircleOutlined } from '@ant-design/icons';
import { formValidatorRules } from '../../../common/utils';
import { filter } from 'lodash';

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 }
};
const tailLayout = {
  wrapperCol: { offset: 8, span: 16 }
};

const { email } = formValidatorRules;

function AddDepartmentMember({ orgId, deptId }) {
  const [visible, setVisible] = React.useState(false);
  const [form] = Form.useForm();
  const [loading, setLoading] = React.useState(false);
  const [csvImports, setCsvImports] = React.useState([]);
  const [csvFlag, setCsvFlag] = React.useState(false);
  const [uploadingCsv, setUploadingCsv] = useState(false);
  const depRef = firebase
    .firestore()
    .collection('organizations')
    .doc(orgId)
    .collection('departments')
    .doc(deptId);
  const profileRef = firebase.firestore().collection('profiles');

  const handleUser = async (event) => {
    let { member } = event;
    let memberIds;
    if (!member) {
      memberIds = [...csvImports];
    } else {
      await firebase
        .firestore()
        .collection('profiles')
        .where('user', '==', member)
        .get()
        .then((data) => {
          if (data?.docs?.length) {
            data.docs.forEach((doc) => {
              memberIds = [doc?.data()?.userId];
            });
          } else {
            memberIds = [];
            message.error('Profile not found');
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
    if (!memberIds.length) {
      message.error('Please add members');
      return;
    }
    setLoading(true);
    for (const id of memberIds) {
      const doc = await profileRef.doc(id).get();
      if (
        (doc?.data()?.deptId === '' ||
          doc?.data()?.deptId === null ||
          doc?.data()?.deptId === undefined ||
          !doc?.data()?.deptId) &&
        doc?.data()?.orgId === orgId
      ) {
        await profileRef?.doc(id)?.update({ deptId });
      } else {
        message.warning(
          'Member already belongs to some other organization/department'
        );
        memberIds = filter(memberIds, (item) => {
          return item !== id;
        });
      }
    }

    depRef.get().then((dep) => {
      depRef
        .set(
          { members: [...new Set([...dep?.data().members, ...memberIds])] },
          { merge: true }
        )
        .then(() => {
          setLoading(false);
          setVisible(false);
          setCsvImports([]);
          form.resetFields();
          setCsvFlag(false);
        })
        .catch((error) => {
          console.log('error', error);
        });
    });
  };
  const showModal = () => {
    setVisible(true);
  };

  return (
    <>
      <Button type="primary" onClick={() => showModal()}>
        Add Member
      </Button>
      <Modal
        title="Add Members"
        visible={visible}
        footer={null}
        onCancel={() => {
          setVisible(false);
          setCsvImports([]);
          form.resetFields();
          setCsvFlag(false);
        }}
      >
        <Form {...layout} form={form} name="basic" onFinish={handleUser}>
          <Form.Item label="Select Member" name="member" rules={[email]}>
            <Input />
          </Form.Item>

          <Form.Item label="Import CSV">
            <Upload
              accept=".csv"
              showUploadList={false}
              beforeUpload={(file) => {
                const reader = new FileReader();
                reader.onload = async (e) => {
                  const csvArray = e?.target?.result?.split('\n');
                  const matchedProfiles = [];
                  setUploadingCsv(true);
                  for (const email of csvArray) {
                    if (email?.length) {
                      await firebase
                        .firestore()
                        .collection('profiles')
                        .where('user', '==', email?.toLowerCase()?.trim())
                        .get()
                        .then((data) => {
                          data.docs.forEach((doc) => {
                            matchedProfiles.push(doc?.data()?.userId);
                          });
                        });
                    }
                  }
                  setCsvImports([
                    ...new Set([...csvImports, ...matchedProfiles])
                  ]);
                  setUploadingCsv(false);
                };
                reader.readAsText(file);
                return false;
              }}
            >
              <Button
                disabled={csvFlag}
                icon={
                  csvImports?.length > 0 ? (
                    <CheckCircleOutlined />
                  ) : (
                    <ImportOutlined />
                  )
                }
                loading={uploadingCsv}
              >
                CSV
              </Button>
            </Upload>
          </Form.Item>
          <Form.Item {...tailLayout}>
            <Button loading={loading} type="primary" htmlType="submit">
              Add Member
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}

export default AddDepartmentMember;
