import { Typography } from 'antd';
import React, { useState } from 'react';
import { firebase } from '../firebase';

const GetVendorNameFromOrderState = ({ order }) => {
  const [Name, setName] = useState('');
  const [spin, setSpin] = useState(true);
  let vendorId;
  if (order?.state === 'DESIGNING') {
    vendorId = order?.designingVendorId;
  } else if (order?.state === 'PRINTING') {
    vendorId = order?.printingVendorId;
  } else if (order?.state === 'SHIPPING') {
    vendorId = order?.shippingVendorId;
  }
  firebase
    .firestore()
    .collection('vendor')
    .doc(vendorId || 'DUMMY')
    .get()
    .then((vendor) => {
      setName(vendor?.data() ? vendor?.data()?.name : '');
      setSpin(false);
    });
  return <Typography.Text>{Name}</Typography.Text>;
};

export default GetVendorNameFromOrderState;
