import React, { useState } from 'react';
import NewsForm from './NewsForm';
import api from '../../../common/api';

function EditNews({ id, getNews }) {
  const [init, setInit] = useState();

  const getNewsById = async (id) => {
    try {
      const singleNews = await api?.get(
        `${process?.env?.REACT_APP_SERVER_URL_V2}/news/${id}`
      );
      setInit(singleNews?.data?.data?.news);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div>
      <NewsForm
        edit
        initialValues={init}
        id={id}
        getNews={getNews}
        getNewsById={getNewsById}
      />
    </div>
  );
}

export default EditNews;
