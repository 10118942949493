import { Typography } from 'antd';
import React from 'react';

export const generateDate = (text, font, color) => {
  try {
    const date = new Date((text?.seconds || text?._seconds) * 1000);
    const day = date?.getDate();
    const month = date?.getMonth();
    const year = date?.getFullYear();
    const hours = date?.getHours();
    const minutes = date?.getMinutes();
    const formattedMinutes = minutes < 9 ? `0${minutes}` : minutes;

    return (
      <Typography.Text style={{ fontSize: font, color: color }}>{`${day}/${
        month + 1
      }/${year} ${hours}:${formattedMinutes}`}</Typography.Text>
    );
  } catch {
    return <></>;
  }
};
