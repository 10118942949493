import React, { useState } from 'react';

import { Button, Modal, Form, Select, message } from 'antd';

import { firebase } from '../../../firebase';
import { CARD_TYPE_NAME, COUNTRIES } from '../../../common/constant';
import { catchErrorInSentry } from '../../../common/utils';

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 }
};
const tailLayout = {
  wrapperCol: { offset: 8, span: 16 }
};

function Assign({ vendors }) {
  const [visible, setVisible] = useState(false);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (event) => {
    const { vendor, countries, cardType } = event;
    setLoading(true);
    try {
      await firebase.firestore().collection('vendor').doc(vendor).update({
        countries,
        cardType,
        isAssigned: true
      });
      setLoading(false);
      setVisible(false);
      form.resetFields();
      message.success('Assigned successfully');
    } catch (error) {
      catchErrorInSentry(error);
      alert(error);
    }
  };

  const showModal = () => {
    setVisible(true);
  };
  function handleSelectAllCountries(value) {
    if (value?.length && value?.includes('all')) {
      if (value?.length === COUNTRIES?.length + 1) {
        form.setFieldsValue({ countries: [] });
        return;
      }
      const values = [];
      COUNTRIES.map((country) => values.push(country?.code));
      form.setFieldsValue({ countries: values });
      return;
    }
    form.setFieldsValue({ countries: value });
    return;
  }

  const unassignedOptions = [];
  vendors.map((vendor) => {
    unassignedOptions.push(
      <Select.Option key={vendor?.id}>{vendor?.name}</Select.Option>
    );
  });
  const cardTypeOptions = [];
  CARD_TYPE_NAME.map((cardType) => {
    cardTypeOptions.push(
      <Select.Option key={cardType}>{cardType}</Select.Option>
    );
  });
  const countryOptions = [];
  COUNTRIES.map((country) => {
    countryOptions.push(
      <Select.Option key={country?.code} value={country?.code}>
        {country.name}
      </Select.Option>
    );
  });

  return (
    <>
      <Button type="primary" onClick={() => showModal()}>
        Assign Work
      </Button>
      <Modal
        title="Assign Work"
        visible={visible}
        footer={null}
        onCancel={() => setVisible(false)}
      >
        <Form
          {...layout}
          form={form}
          name="basic"
          onFinish={handleSubmit}
          initialValues={{ remember: true }}
        >
          <Form.Item
            label="Select Vendor"
            name="vendor"
            rules={[{ required: true, message: 'Please select vendor!' }]}
          >
            <Select placeholder="Please select">{unassignedOptions}</Select>
          </Form.Item>

          <Form.Item
            label="Card Type"
            name="cardType"
            rules={[{ required: true, message: 'Please select card types!' }]}
          >
            <Select mode="multiple" placeholder="Please select">
              {cardTypeOptions}
            </Select>
          </Form.Item>
          <Form.Item
            label="Countries"
            name="countries"
            rules={[{ required: true, message: 'Please select Countries!' }]}
          >
            <Select
              mode="multiple"
              placeholder="Please select"
              maxTagCount={5}
              showSearch
              optionFilterProp="children"
              onChange={handleSelectAllCountries}
            >
              <Select.Option key="all" value="all">
                ---SELECT ALL---
              </Select.Option>
              {countryOptions}
            </Select>
          </Form.Item>

          <Form.Item {...tailLayout}>
            <Button type="primary" loading={loading} htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}

export default Assign;
