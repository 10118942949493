import React, { useState, useEffect } from 'react';
import { useParams, withRouter, useHistory } from 'react-router-dom';

import { Typography, Button, Card, Space, List } from 'antd';
import { LeftOutlined } from '@ant-design/icons';

import { firebase } from '../../../firebase';
import Loader from '../../Loader/Loader';
import EditableTable from './EditableTable';
import { Prompt } from 'react-router-dom';

function OrderCard() {
  let { setId, id } = useParams();
  const [set, setSet] = useState([]);
  const [cardUsers, setCardUsers] = useState();
  const [isPromptRequired, setIsPromptRequired] = useState(false);
  const [status, setStatus] = useState();
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const ref = firebase
    .firestore()
    .collection('sets')
    .where('setUniqueId', '==', setId);
  function getSet() {
    setLoading(true);
    ref.onSnapshot((querySnapshot) => {
      setStatus(querySnapshot?.docs[0]?.data()?.status);
      const cardUsersArray = querySnapshot?.docs[0]?.data()?.cardUsers;
      setCardUsers(cardUsersArray);
      const data = cardUsersArray.map((element, index) => {
        return {
          firstName: element?.firstName,
          lastName: element?.lastName,
          company: element?.company,
          title: element?.title,
          email: element?.email,
          mobileCountryCode: element?.mobileCountryCode,
          mobilePhone: element?.mobilePhone,
          workPhoneCountryCode: element?.workPhoneCountryCode,
          workPhone: element?.workPhone,
          addressLine1: element?.addressLine1,
          addressLine2: element?.addressLine2,
          city: element?.city,
          zipcode: element?.zipcode,
          state: element?.state,
          country: element?.country,
          linkedIn: element?.linkedIn,
          quantity: element?.quantity,
          key: index
        };
      });
      setSet(data);
    });
    setLoading(false);
  }

  useEffect(() => {
    getSet();
  }, []);
  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <Prompt
        message="You have unsaved changes! Are you sure you want to leave this page"
        when={isPromptRequired}
      />
      <Card
        title={
          <div className="cards-header">
            {' '}
            <Button
              type="text"
              onClick={() => {
                history.goBack();
              }}
              icon={<LeftOutlined />}
            />
            <Typography.Title style={{ margin: 0 }} level={3}>
              {setId}
            </Typography.Title>
          </div>
        }
      >
        <EditableTable
          data={set?.cardUsers}
          set={set}
          setSet={setSet}
          orderUniqueId={id}
          cardUsers={cardUsers}
          setCardUsers={setCardUsers}
          setIsPromptRequired={setIsPromptRequired}
          isPromptRequired={isPromptRequired}
          status={status}
          getSet={getSet}
        />
        {set?.length > 0 && (
          <Card size="small" title="Reference Files">
            <Space>
              {set ? (
                set?.frontImage ? (
                  set?.frontImage?.length > 0 ? (
                    <Card
                      size="small"
                      title="Front Side"
                      style={{ width: '300px' }}
                      bordered={false}
                    >
                      <List
                        itemLayout="horizontal"
                        dataSource={set?.frontImage}
                        renderItem={(item) => (
                          <List.Item>
                            <List.Item.Meta
                              title={
                                item.match(/images([^?]*)/) ? (
                                  <a target="_blank" href={item}>
                                    {item
                                      .match(/images([^?]*)/)[1]
                                      ?.replaceAll('%20', ' ')
                                      ?.replaceAll('%2F', '')
                                      ?.substr(8)}
                                  </a>
                                ) : null
                              }
                            />
                          </List.Item>
                        )}
                      />
                    </Card>
                  ) : null
                ) : null
              ) : null}
              {set ? (
                set?.backImage ? (
                  set?.backImage?.length > 0 ? (
                    <Card
                      size="small"
                      title="Back Side"
                      style={{ width: '300px' }}
                      bordered={false}
                    >
                      <List
                        itemLayout="horizontal"
                        dataSource={set?.backImage}
                        renderItem={(item) => (
                          <List.Item>
                            <List.Item.Meta
                              title={
                                item.match(/images([^?]*)/) ? (
                                  <a target="_blank" href={item}>
                                    {item
                                      .match(/images([^?]*)/)[1]
                                      ?.replaceAll('%20', ' ')
                                      ?.replaceAll('%2F', '')
                                      ?.substr(8)}
                                  </a>
                                ) : null
                              }
                            />
                          </List.Item>
                        )}
                      />
                    </Card>
                  ) : null
                ) : null
              ) : null}
            </Space>
          </Card>
        )}
      </Card>
    </>
  );
}

export default withRouter(OrderCard);
