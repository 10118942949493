import React, { useEffect } from 'react';

//! Antd imports
import { Card, Input } from 'antd';
import { Rnd } from 'react-rnd';
import AntText from './AntText';
import {
  checkFieldInObject,
  getAllCoordinates
} from '../../../../common/utils';
import { RESOLUTION_FACTOR } from '../../../../common/constant';

const AntCard = ({
  ref,
  cardId,
  backgroundColor,
  cardData,
  currentTab,
  newField,
  front,
  setFront,
  back,
  setBack,
  backgroundImage,
  side,
  activeUserTab
}) => {
  return (
    <div
      ref={ref}
      id={cardId}
      style={{
        position: 'relative',
        backgroundColor: `${backgroundColor}`,
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'cover',
        width: 405,
        height: 255
      }}
    >
      <Rnd
        position={
          currentTab === 'front'
            ? {
                x: front?.customField?.x / RESOLUTION_FACTOR || 0,
                y: front?.customField?.y / RESOLUTION_FACTOR || 0
              }
            : {
                x: back?.customField?.x / RESOLUTION_FACTOR || 0,
                y: back?.customField?.y / RESOLUTION_FACTOR || 0
              }
        }
        enableResizing={false}
        onDragStop={(e, d) => {
          if (currentTab === 'front') {
            front?.customField?.alignment &&
              delete front?.customField?.alignment;
            getAllCoordinates(
              front,
              setFront,
              'customField-front',
              currentTab,
              d?.x,
              d?.y
            );
          } else {
            back?.customField?.alignment && delete back?.customField?.alignment;
            getAllCoordinates(
              back,
              setBack,
              'customField-back',
              currentTab,
              d?.x,
              d?.y
            );
          }
        }}
        bounds="parent"
      >
        {newField?.length > 0 &&
          checkFieldInObject(front, back, 'customField', currentTab) && (
            <AntText
              text={newField}
              id={`customField-${side}`}
              fontSize={
                currentTab === 'front'
                  ? front?.customField?.fontSize
                  : back?.customField?.fontSize
              }
              color={
                currentTab === 'front'
                  ? front?.customField?.color
                  : back?.customField?.color
              }
              url={
                currentTab === 'front'
                  ? front?.customField?.url
                  : back?.customField?.url
              }
              fontFamily={
                currentTab === 'front'
                  ? front?.customField?.family
                  : back?.customField?.family
              }
              coordinates={
                currentTab === 'front' ? front?.customField : back?.customField
              }
              /* Rotation is the part of next sprint so that commenting UI for now */
              // transform={`rotate(${front?.customField?.rotation}deg)`}
            />
          )}
      </Rnd>
      <Rnd
        position={
          currentTab === 'front'
            ? {
                x: front?.fullName?.x / RESOLUTION_FACTOR || 0,
                y: front?.fullName?.y / RESOLUTION_FACTOR || 0
              }
            : {
                x: back?.fullName?.x / RESOLUTION_FACTOR || 0,
                y: back?.fullName?.y / RESOLUTION_FACTOR || 0
              }
        }
        enableResizing={false}
        onDragStop={(e, d) => {
          front?.fullName?.alignment && delete front?.fullName?.alignment;
          if (currentTab === 'front') {
            getAllCoordinates(
              front,
              setFront,
              'fullName-front',
              currentTab,
              d?.x,
              d?.y
            );
          } else {
            back?.fullName?.alignment && delete back?.fullName?.alignment;
            getAllCoordinates(
              back,
              setBack,
              'fullName-back',
              currentTab,
              d?.x,
              d?.y
            );
          }
        }}
        bounds="parent"
      >
        {checkFieldInObject(front, back, 'fullName', currentTab) && (
          <AntText
            id={`fullName-${side}`}
            fontSize={
              currentTab === 'front'
                ? front?.fullName?.fontSize
                : back?.fullName?.fontSize
            }
            color={
              currentTab === 'front'
                ? front?.fullName?.color
                : back?.fullName?.color
            }
            text={cardData[activeUserTab]?.fullName}
            url={
              currentTab === 'front'
                ? front?.fullName?.url
                : back?.fullName?.url
            }
            fontFamily={
              currentTab === 'front'
                ? front?.fullName?.family
                : back?.fullName?.family
            }
            coordinates={
              currentTab === 'front' ? front?.fullName : back?.fullName
            }
            /* Rotation is the part of next sprint so that commenting UI for now */
            // transform={`rotate(${front?.fullName?.rotation}deg)`}
          />
        )}
      </Rnd>
      <Rnd
        position={
          currentTab === 'front'
            ? {
                x: front?.firstName?.x / RESOLUTION_FACTOR || 0,
                y: front?.firstName?.y / RESOLUTION_FACTOR || 0
              }
            : {
                x: back?.firstName?.x / RESOLUTION_FACTOR || 0,
                y: back?.firstName?.y / RESOLUTION_FACTOR || 0
              }
        }
        enableResizing={false}
        onDragStop={(e, d) => {
          if (currentTab === 'front') {
            front?.firstName?.alignment && delete front?.firstName?.alignment;
            getAllCoordinates(
              front,
              setFront,
              'firstName-front',
              currentTab,
              d?.x,
              d?.y
            );
          } else {
            back?.firstName?.alignment && delete back?.firstName?.alignment;
            getAllCoordinates(
              back,
              setBack,
              'firstName-back',
              currentTab,
              d?.x,
              d?.y
            );
          }
        }}
        bounds="parent"
      >
        {checkFieldInObject(front, back, 'firstName', currentTab) && (
          <AntText
            id={`firstName-${side}`}
            fontSize={
              currentTab === 'front'
                ? front?.firstName?.fontSize
                : back?.firstName?.fontSize
            }
            color={
              currentTab === 'front'
                ? front?.firstName?.color
                : back?.firstName?.color
            }
            text={cardData[activeUserTab]?.firstName}
            url={
              currentTab === 'front'
                ? front?.firstName?.url
                : back?.firstName?.url
            }
            fontFamily={
              currentTab === 'front'
                ? front?.firstName?.family
                : back?.firstName?.family
            }
            coordinates={
              currentTab === 'front' ? front?.firstName : back?.firstName
            }
            /* Rotation is the part of next sprint so that commenting UI for now */
            // transform={`rotate(${front?.firstName?.rotation}deg)`}
          />
        )}
      </Rnd>
      <Rnd
        position={
          currentTab === 'front'
            ? {
                x: front?.lastName?.x / RESOLUTION_FACTOR || 0,
                y: front?.lastName?.y / RESOLUTION_FACTOR || 0
              }
            : {
                x: back?.lastName?.x / RESOLUTION_FACTOR || 0,
                y: back?.lastName?.y / RESOLUTION_FACTOR || 0
              }
        }
        enableResizing={false}
        onDragStop={(e, d) => {
          if (currentTab === 'front') {
            front?.lastName?.alignment && delete front?.lastName?.alignment;
            getAllCoordinates(
              front,
              setFront,
              'lastName-front',
              currentTab,
              d?.x,
              d?.y
            );
          } else {
            back?.lastName?.alignment && delete back?.lastName?.alignment;
            getAllCoordinates(
              back,
              setBack,
              'lastName-back',
              currentTab,
              d?.x,
              d?.y
            );
          }
        }}
        bounds="parent"
      >
        {checkFieldInObject(front, back, 'lastName', currentTab) && (
          <AntText
            id={`lastName-${side}`}
            fontSize={
              currentTab === 'front'
                ? front?.lastName?.fontSize
                : back?.lastName?.fontSize
            }
            color={
              currentTab === 'front'
                ? front?.lastName?.color
                : back?.lastName?.color
            }
            text={cardData[activeUserTab]?.lastName}
            url={
              currentTab === 'front'
                ? front?.lastName?.url
                : back?.lastName?.url
            }
            fontFamily={
              currentTab === 'front'
                ? front?.lastName?.family
                : back?.lastName?.family
            }
            coordinates={
              currentTab === 'front' ? front?.lastName : back?.lastName
            }
            /* Rotation is the part of next sprint so that commenting UI for now */
            // transform={`rotate(${front?.lastName?.rotation}deg)`}
          />
        )}
      </Rnd>
      <Rnd
        position={
          currentTab === 'front'
            ? {
                x: front?.email?.x / RESOLUTION_FACTOR || 0,
                y: front?.email?.y / RESOLUTION_FACTOR || 0
              }
            : {
                x: back?.email?.x / RESOLUTION_FACTOR || 0,
                y: back?.email?.y / RESOLUTION_FACTOR || 0
              }
        }
        enableResizing={false}
        onDragStop={(e, d) => {
          if (currentTab === 'front') {
            front?.email?.alignment && delete front?.email?.alignment;
            getAllCoordinates(
              front,
              setFront,
              'email-front',
              currentTab,
              d?.x,
              d?.y
            );
          } else {
            back?.email?.alignment && delete back?.email?.alignment;
            getAllCoordinates(
              back,
              setBack,
              'email-back',
              currentTab,
              d?.x,
              d?.y
            );
          }
        }}
        bounds="parent"
      >
        {checkFieldInObject(front, back, 'email', currentTab) && (
          <AntText
            id={`email-${side}`}
            fontSize={
              currentTab === 'front'
                ? front?.email?.fontSize
                : back?.email?.fontSize
            }
            color={
              currentTab === 'front' ? front?.email?.color : back?.email?.color
            }
            text={cardData[activeUserTab]?.email}
            url={currentTab === 'front' ? front?.email?.url : back?.email?.url}
            fontFamily={
              currentTab === 'front'
                ? front?.email?.family
                : back?.email?.family
            }
            coordinates={currentTab === 'front' ? front?.email : back?.email}
            /* Rotation is the part of next sprint so that commenting UI for now */
            // transform={`rotate(${front?.email?.rotation}deg)`}
          />
        )}
      </Rnd>
      <Rnd
        position={
          currentTab === 'front'
            ? {
                x: front?.mobilePhone?.x / RESOLUTION_FACTOR || 0,
                y: front?.mobilePhone?.y / RESOLUTION_FACTOR || 0
              }
            : {
                x: back?.mobilePhone?.x / RESOLUTION_FACTOR || 0,
                y: back?.mobilePhone?.y / RESOLUTION_FACTOR || 0
              }
        }
        enableResizing={false}
        onDragStop={(e, d) => {
          if (currentTab === 'front') {
            front?.mobilePhone?.alignment &&
              delete front?.mobilePhone?.alignment;
            getAllCoordinates(
              front,
              setFront,
              'mobilePhone-front',
              currentTab,
              d?.x,
              d?.y
            );
          } else {
            back?.mobilePhone?.alignment && delete back?.mobilePhone?.alignment;
            getAllCoordinates(
              back,
              setBack,
              'mobilePhone-back',
              currentTab,
              d?.x,
              d?.y
            );
          }
        }}
        bounds="parent"
      >
        {checkFieldInObject(front, back, 'mobilePhone', currentTab) && (
          <AntText
            id={`mobilePhone-${side}`}
            fontSize={
              currentTab === 'front'
                ? front?.mobilePhone?.fontSize
                : back?.mobilePhone?.fontSize
            }
            color={
              currentTab === 'front'
                ? front?.mobilePhone?.color
                : back?.mobilePhone?.color
            }
            text={cardData[activeUserTab]?.mobilePhone}
            url={
              currentTab === 'front'
                ? front?.mobilePhone?.url
                : back?.mobilePhone?.url
            }
            fontFamily={
              currentTab === 'front'
                ? front?.mobilePhone?.family
                : back?.mobilePhone?.family
            }
            coordinates={
              currentTab === 'front' ? front?.mobilePhone : back?.mobilePhone
            }
          />
        )}
      </Rnd>
      <Rnd
        position={
          currentTab === 'front'
            ? {
                x: front?.workPhone?.x / RESOLUTION_FACTOR || 0,
                y: front?.workPhone?.y / RESOLUTION_FACTOR || 0
              }
            : {
                x: back?.workPhone?.x / RESOLUTION_FACTOR || 0,
                y: back?.workPhone?.y / RESOLUTION_FACTOR || 0
              }
        }
        enableResizing={false}
        onDragStop={(e, d) => {
          if (currentTab === 'front') {
            front?.workPhone?.alignment && delete front?.workPhone?.alignment;
            getAllCoordinates(
              front,
              setFront,
              'workPhone-front',
              currentTab,
              d?.x,
              d?.y
            );
          } else {
            back?.workPhone?.alignment && delete back?.workPhone?.alignment;
            getAllCoordinates(
              back,
              setBack,
              'workPhone-back',
              currentTab,
              d?.x,
              d?.y
            );
          }
        }}
        bounds="parent"
      >
        {checkFieldInObject(front, back, 'workPhone', currentTab) && (
          <AntText
            id={`workPhone-${side}`}
            fontSize={
              currentTab === 'front'
                ? front?.workPhone?.fontSize
                : back?.workPhone?.fontSize
            }
            color={
              currentTab === 'front'
                ? front?.workPhone?.color
                : back?.workPhone?.color
            }
            text={cardData[activeUserTab]?.workPhone}
            url={
              currentTab === 'front'
                ? front?.workPhone?.url
                : back?.workPhone?.url
            }
            fontFamily={
              currentTab === 'front'
                ? front?.workPhone?.family
                : back?.workPhone?.family
            }
            coordinates={
              currentTab === 'front' ? front?.workPhone : back?.workPhone
            }
          />
        )}
      </Rnd>
      <Rnd
        position={
          currentTab === 'front'
            ? {
                x: front?.company?.x / RESOLUTION_FACTOR || 0,
                y: front?.company?.y / RESOLUTION_FACTOR || 0
              }
            : {
                x: back?.company?.x / RESOLUTION_FACTOR || 0,
                y: back?.company?.y / RESOLUTION_FACTOR || 0
              }
        }
        enableResizing={false}
        onDragStop={(e, d) => {
          if (currentTab === 'front') {
            front?.company?.alignment && delete front?.company?.alignment;
            getAllCoordinates(
              front,
              setFront,
              'company-front',
              currentTab,
              d?.x,
              d?.y
            );
          } else {
            back?.company?.alignment && delete back?.company?.alignment;
            getAllCoordinates(
              back,
              setBack,
              'company-back',
              currentTab,
              d?.x,
              d?.y
            );
          }
        }}
        bounds="parent"
      >
        {checkFieldInObject(front, back, 'company', currentTab) && (
          <AntText
            id={`company-${side}`}
            fontSize={
              currentTab === 'front'
                ? front?.company?.fontSize
                : back?.company?.fontSize
            }
            color={
              currentTab === 'front'
                ? front?.company?.color
                : back?.company?.color
            }
            text={cardData[activeUserTab]?.company}
            url={
              currentTab === 'front' ? front?.company?.url : back?.company?.url
            }
            fontFamily={
              currentTab === 'front'
                ? front?.company?.family
                : back?.company?.family
            }
            coordinates={
              currentTab === 'front' ? front?.company : back?.company
            }
          />
        )}
      </Rnd>
      <Rnd
        position={
          currentTab === 'front'
            ? {
                x: front?.title?.x / RESOLUTION_FACTOR || 0,
                y: front?.title?.y / RESOLUTION_FACTOR || 0
              }
            : {
                x: back?.title?.x / RESOLUTION_FACTOR || 0,
                y: back?.title?.y / RESOLUTION_FACTOR || 0
              }
        }
        enableResizing={false}
        onDragStop={(e, d) => {
          if (currentTab === 'front') {
            front?.title?.alignment && delete front?.title?.alignment;
            getAllCoordinates(
              front,
              setFront,
              'title-front',
              currentTab,
              d?.x,
              d?.y
            );
          } else {
            back?.title?.alignment && delete back?.title?.alignment;
            getAllCoordinates(
              back,
              setBack,
              'title-back',
              currentTab,
              d?.x,
              d?.y
            );
          }
        }}
        bounds="parent"
      >
        {checkFieldInObject(front, back, 'title', currentTab) && (
          <AntText
            id={`title-${side}`}
            fontSize={
              currentTab === 'front'
                ? front?.title?.fontSize
                : back?.title?.fontSize
            }
            color={
              currentTab === 'front' ? front?.title?.color : back?.title?.color
            }
            text={cardData[activeUserTab]?.title}
            url={currentTab === 'front' ? front?.title?.url : back?.title?.url}
            fontFamily={
              currentTab === 'front'
                ? front?.title?.family
                : back?.title?.family
            }
            coordinates={currentTab === 'front' ? front?.title : back?.title}
          />
        )}
      </Rnd>
    </div>
  );
};

export default AntCard;
