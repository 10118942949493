import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import {
  Button,
  Card,
  Col,
  message,
  Row,
  Space,
  Spin,
  Switch,
  Table,
  Tooltip,
  Alert,
  Typography
} from 'antd';
import { LeftOutlined, QrcodeOutlined } from '@ant-design/icons';

import { firebase } from '../../../firebase';
import Loader from '../../Loader/Loader';
import { LIMIT_PRIMARY } from '../../../common/constant';
import { getColumnSearchPropsV2 } from '../../../utils/searchV2';
import ReprintCard from '../cards/Reprint';
import ReplaceCard from '../cards/Replace';
import { catchErrorInSentry } from '../../../common/utils';

function GetEmailFromId({ id }) {
  const [email, setEmail] = useState('');
  useEffect(() => {
    firebase
      .firestore()
      .collection('profiles')
      .doc(id)
      .get()
      .then((profile) => {
        setEmail(profile?.data() ? profile?.data()?.user : '');
      });
  }, []);

  return <Typography.Text>{email}</Typography.Text>;
}

function UserProfileCards() {
  const { id } = useParams();
  const history = useHistory();
  const [cards, setCards] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [loading, setLoading] = useState(false);
  const [offset, setOffset] = useState();
  const [hasMore, setHasMore] = useState(true);
  const searchInput = useRef(null);

  let ref = firebase.firestore().collection('cards').where('owner', '==', id);
  let showProfileref = firebase
    .firestore()
    .collection('cards')
    .where('showProfile', '==', id);
  ref = ref.limit(LIMIT_PRIMARY);

  const columns = [
    {
      title: 'Card code',
      dataIndex: 'cardCode',
      key: 'cardCode',
      ...getColumnSearchPropsV2(
        'cardCode',
        searchText,
        searchedColumn,
        setSearchText,
        setSearchedColumn,
        searchInput
      )
    },
    {
      title: 'Order unique ID',
      dataIndex: 'orderUniqueId',
      key: 'orderUniqueId',
      ...getColumnSearchPropsV2(
        'orderUniqueId',
        searchText,
        searchedColumn,
        setSearchText,
        setSearchedColumn,
        searchInput
      )
    },
    {
      title: 'Order Number',
      dataIndex: 'orderNumber',
      key: 'orderNumber',
      ...getColumnSearchPropsV2(
        'orderNumber',
        searchText,
        searchedColumn,
        setSearchText,
        setSearchedColumn,
        searchInput
      )
    },
    {
      title: 'Show Profile ID',
      key: 'profileId',
      render: function VenodorName(text, record) {
        const [user, setUser] = useState();
        const [spin, setSpin] = useState(true);
        try {
          if (record?.showProfile) {
            firebase
              .firestore()
              .collection('profiles')
              .doc(record?.showProfile)
              .get()
              .then((profile) => {
                setUser(profile?.data() ? profile?.data()?.user : '');
                setSpin(false);
              });
          } else {
            return '-';
          }
        } catch (error) {
          catchErrorInSentry(error);
        }

        if (spin) return <Spin />;
        return <Typography.Text>{user}</Typography.Text>;
      }
    },
    {
      title: 'Reprint count',
      dataIndex: 'reprintCount',
      key: 'reprintCount'
    },
    {
      title: 'Original Card',
      dataIndex: 'originalCardCode',
      key: 'originalCardCode'
    },
    {
      title: 'Actions',
      key: 'actions',
      render: function Reprocess(text, record) {
        const [disabled, setDisabled] = useState(false);
        return (
          <Space size={0}>
            <Switch
              size="small"
              checked={!record?.disabled}
              disabled={
                record?.activeLink?.includes('/cards/expired') ? true : false
              }
              onChange={(e) => {
                firebase
                  .firestore()
                  .collection('cards')
                  .doc(record?.cardCode)
                  .update({
                    activeLink: !e
                      ? `${process?.env?.REACT_APP_SERVER_URL}/cards/blocked`
                      : record?.disabledActiveLink || '',
                    disabledActiveLink: record?.activeLink || '',
                    disabled: !e
                  })
                  .then(() => {
                    const successMessage = e ? 'Card Enabled' : 'Card Disabled';
                    message.success(successMessage);
                  })
                  .catch((e) => {
                    catchErrorInSentry(e);
                    message.error('Some error occured');
                    console.log(e);
                  });
              }}
            />
            <ReprintCard
              record={record}
              disabled={disabled}
              setDisabled={setDisabled}
            />
            <ReplaceCard
              record={record}
              disabled={disabled}
              setDisabled={setDisabled}
            />
            <Tooltip title="Click to scan">
              <a target="_blank" href={record?.qrcode}>
                <Button
                  disabled={!record?.qrcode}
                  type="text"
                  icon={<QrcodeOutlined />}
                />
              </a>
            </Tooltip>
          </Space>
        );
      }
    }
  ];

  const getCards = () => {
    setLoading(true);
    ref.onSnapshot((cards) => {
      const items = [];
      cards.forEach((card) => {
        items.push(card?.data());
      });
      if (items?.length < 10) {
        setHasMore(false);
      }
      setOffset(cards?.docs[cards?.docs?.length - 1]);
      setCards(items);
      setLoading(false);
    });
  };

  const getNextCards = () => {
    try {
      ref = ref.startAfter(offset);
      ref.onSnapshot((querySnapshot) => {
        const items = [];
        querySnapshot.forEach((doc) => {
          items.push({ ...doc?.data() });
        });
        if (items?.length < LIMIT_PRIMARY) {
          setHasMore(false);
        }
        setOffset(querySnapshot?.docs[querySnapshot?.docs?.length - 1]);
        setCards([...cards, ...items]);
      });
    } catch (error) {
      catchErrorInSentry(error);
      console.log(error);
    }
  };
  useEffect(() => {
    getCards();
  }, []);

  if (loading) {
    return <Loader />;
  }
  const handleShowProfileCards = async () => {
    try {
      showProfileref.onSnapshot((cards) => {
        const items = [];
        cards.forEach((card) => {
          items.push(card?.data());
        });
        setOffset(cards?.docs[cards?.docs?.length - 1]);
        setCards(items);
        setLoading(false);
      });
    } catch (error) {
      catchErrorInSentry(error);
      console.log(error);
    }
  };

  return (
    <Card
      title={
        <Space>
          <Button
            onClick={() => {
              history.goBack();
            }}
            type="text"
            icon={<LeftOutlined />}
          />
          <Typography.Title style={{ margin: 0 }} level={3}>
            <GetEmailFromId id={id} /> | Cards
          </Typography.Title>
        </Space>
      }
    >
      {cards?.length === 0 && (
        <Alert
          message={
            <p>
              This user has no card owner roles click &nbsp;
              <Typography.Text
                type="danger"
                onClick={() => {
                  handleShowProfileCards();
                }}
                style={{ cursor: 'pointer' }}
              >
                here
              </Typography.Text>{' '}
              to get profile card lists
            </p>
          }
          type="warning"
          className="warning-text"
        />
      )}
      <Table dataSource={cards} columns={columns} pagination={false} bordered />
      <Row justify="center">
        <Col>
          <Button
            className="margin-t-16"
            disabled={!hasMore}
            onClick={() => {
              if (hasMore) {
                getNextCards();
              }
            }}
          >
            Load More
          </Button>
        </Col>
      </Row>
    </Card>
  );
}

export default UserProfileCards;
