import React, { useContext } from 'react';
import { AuthContext } from '../../Auth';
import { Menu } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import {
  FieldTimeOutlined,
  CheckCircleOutlined,
  PushpinOutlined,
  HomeOutlined,
  CarOutlined,
  ClockCircleOutlined
} from '@ant-design/icons';
const { SubMenu } = Menu;

function VendorMenu() {
  const { data } = useContext(AuthContext);
  const { pathname } = useLocation();
  let pseudoPath = '/';
  if (!data) {
    return <></>;
  }
  if (data && data.role.includes('PRINTER') && data.role.includes('SHIPPER')) {
    pseudoPath = '/print/inprogress';
  }
  if (data && data.role.length === 1 && data.role.includes('DESIGNER')) {
    pseudoPath = '/design/inprogress';
  }
  if (data && data.role.length === 1 && data.role.includes('PRINTER')) {
    pseudoPath = '/print/inprogress';
  }
  if (data && data.role.length === 1 && data.role.includes('SHIPPER')) {
    pseudoPath = '/ship/inwarehouse';
  }
  return (
    <Menu
      mode="inline"
      onClick={() => sessionStorage.clear()}
      selectedKeys={pathname === '/' ? pseudoPath : pathname}
      defaultOpenKeys={['designer', 'printer', 'shipper']}
      defaultSelectedKeys={['selected']}
    >
      {data?.role?.includes('DESIGNER') ? (
        <SubMenu
          key="designer"
          title={
            <span>
              <span>DESIGNER</span>
            </span>
          }
        >
          <Menu.Item icon={<FieldTimeOutlined />} key="/design/inprogress">
            <Link to="/design/inprogress">In Progress</Link>
          </Menu.Item>
          <Menu.Item
            icon={<ClockCircleOutlined />}
            key="/design/waitingforapproval"
          >
            <Link to="/design/waitingforapproval">Waiting for Approval</Link>
          </Menu.Item>
          <Menu.Item icon={<CheckCircleOutlined />} key="/design/completed">
            <Link to="/design/completed">Completed</Link>
          </Menu.Item>
        </SubMenu>
      ) : null}

      {data?.role?.includes('PRINTER') ? (
        <SubMenu
          key="printer"
          title={
            <span>
              <span>PRINTER</span>
            </span>
          }
        >
          <Menu.Item icon={<FieldTimeOutlined />} key={'/print/inprogress'}>
            <Link to="/print/inprogress">In Progress</Link>
          </Menu.Item>
          <Menu.Item icon={<CheckCircleOutlined />} key={'/print/completed'}>
            <Link to="/print/completed">Completed</Link>
          </Menu.Item>
        </SubMenu>
      ) : null}
      {data?.role?.includes('SHIPPER') ? (
        <SubMenu
          key="shipper"
          title={
            <span>
              <span>SHIPPER</span>
            </span>
          }
        >
          <Menu.Item icon={<HomeOutlined />} key="/ship/inwarehouse">
            <Link to="/ship/inwarehouse">In Warehouse</Link>
          </Menu.Item>
          <Menu.Item icon={<PushpinOutlined />} key={'/ship/pickup'}>
            <Link to="/ship/pickup">Pickup</Link>
          </Menu.Item>
          <Menu.Item icon={<CarOutlined />} key="/ship/intransit">
            <Link to="/ship/intransit">In Transit</Link>
          </Menu.Item>
          <Menu.Item icon={<CheckCircleOutlined />} key="/ship/completed">
            <Link to="/ship/completed">Completed</Link>
          </Menu.Item>
        </SubMenu>
      ) : null}
    </Menu>
  );
}

export default VendorMenu;
