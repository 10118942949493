import React from "react";
import NewsForm from "./NewsForm";

function AddNews({getNews}) {
  
  return (
    <div>
      <NewsForm getNews={getNews} />
    </div>
  );
}

export default AddNews;
