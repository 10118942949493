import React, { useCallback, useContext } from 'react';
import { withRouter, Redirect } from 'react-router';
import { firebase } from '../firebase.js';
import { AuthContext } from '../Auth.js';
import { Form, Input, Button, Card, notification } from 'antd';

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 }
};
const tailLayout = {
  wrapperCol: { offset: 8, span: 16 }
};

const Login = ({ history }) => {
  const handleLogin = useCallback(
    async (event) => {
      const { email, password } = event;
      const lowerEmail = email.toLowerCase();
      try {
        await firebase.auth().signInWithEmailAndPassword(lowerEmail, password);
        history.push('/');
      } catch (error) {
        notification['error']({
          message: 'Login Error',
          description: error?.message
        });
      }
    },
    [history]
  );

  const { currentUser } = useContext(AuthContext);

  if (currentUser) {
    return <Redirect to="/" />;
  }

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
        backgroundColor: '#e5e5e5'
      }}
    >
      <Card title="Login" style={{ width: 400 }}>
        <Form
          {...layout}
          name="basic"
          initialValues={{ remember: true }}
          onFinish={handleLogin}
        >
          <Form.Item
            label="Email"
            name="email"
            rules={[{ required: true, message: 'Please input your username!' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Password"
            name="password"
            rules={[{ required: true, message: 'Please input your password!' }]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item {...tailLayout}>
            <Button type="primary" htmlType="submit">
              Login
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
};

export default withRouter(Login);
