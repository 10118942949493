import { Button, Col, Modal, Row, Table } from 'antd';
import React, { useState } from 'react';
import api from '../../../common/api';
import { firebase } from '../../../firebase';
import AddLicense from './AddLicense';
import AssignLicense from './AssignLicense';
import ReleaseLicense from './ReleaseLicense';
import RemoveLicense from './RemoveLicense';

function ManageLicense({ orgId }) {
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [organisationLicenses, setOrganisationLicenses] = useState();

  const showModal = () => {
    setVisible(true);
    fetchLicensesOfOrg();
  };
  const columns = [
    {
      title: 'License Type',
      dataIndex: 'licenseType',
      key: 'licenseType'
    },
    {
      title: 'Assigned',
      dataIndex: 'assigned',
      key: 'assigned'
    },
    {
      title: 'Unassigned',
      dataIndex: 'unassigned',
      key: 'unassigned'
    }
  ];

  const fetchLicensesOfOrg = async () => {
    setLoading(true);
    const config = {
      method: 'post',
      url: `${process.env.REACT_APP_PDF_DOWNLOAD_URL}/mat/license/get`,
      headers: {
        'Content-Type': 'application/json',
        secretkey: process.env.REACT_APP_PDF_FIX_SECRET
      },
      data: {
        orgId,
        count: true
      }
    };
    await api(config)
      .then((res) => {
        let array = [];
        if (res?.data?.data) {
          for (const key in res?.data?.data) {
            if (Object.hasOwnProperty.call(res?.data?.data, key)) {
              const element = res?.data?.data[key];
              if (key !== 'totalCount') {
                array.push({
                  licenseType: key,
                  assigned: element?.assigned,
                  unassigned: element?.unassigned
                });
              }
            }
          }
          setOrganisationLicenses(array);
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };

  return (
    <div>
      <Button type="primary" onClick={() => showModal()}>
        Manage License
      </Button>
      <Modal
        title="Manage License"
        visible={visible}
        footer={null}
        onCancel={() => {
          setVisible(false);
        }}
        width={760}
      >
        <Row gutter={16}>
          <Col>
            <AddLicense orgId={orgId} />
          </Col>
          <Col>
            <RemoveLicense orgId={orgId} />
          </Col>
          <Col>
            <AssignLicense
              orgId={orgId}
              organisationLicenses={organisationLicenses}
            />
          </Col>
          <Col>
            <ReleaseLicense orgId={orgId} />
          </Col>
        </Row>
        <div className="available-license-table">
          <Table
            columns={columns}
            dataSource={organisationLicenses}
            bordered
            loading={loading}
            pagination={false}
          />
        </div>
      </Modal>
    </div>
  );
}

export default ManageLicense;
