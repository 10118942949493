import React, { useState } from 'react';

import { Button, Card, Checkbox, Popover, Space, Typography } from 'antd';
import { DeleteOutlined, UndoOutlined } from '@ant-design/icons';
import moment from 'moment';

import EditNews from './EditNews';
import './news.css';

function NewsTile({
  title,
  description,
  image,
  video,
  createdAt,
  newsId,
  mediaType,
  deleteNews,
  getNews,
  loading,
  isDeleted = false,
  handleRestoreNews,
  setNewsRestoreStatus,
  newsType
}) {
  const [deletPopupVisible, setDeletPopupVisible] = useState(false);
  return (
    <Card
      type="inner"
      title={title}
      extra={[
        <div>
          <Space>
            {isDeleted === false &&
            (newsType === 'Active' || newsType === 'Inactive') ? (
              <>
                <EditNews id={newsId} getNews={getNews} />
                <Popover
                  trigger="click"
                  visible={deletPopupVisible}
                  content={
                    <>
                      <p>Are you sure you want to delete this News</p>
                      <Button
                        size="small"
                        type="primary"
                        onClick={() => {
                          deleteNews(newsId);
                        }}
                        loading={loading}
                        className="mr-16"
                      >
                        Yes
                      </Button>
                      <Button
                        size="small"
                        onClick={() => {
                          setDeletPopupVisible(false);
                        }}
                      >
                        No
                      </Button>
                    </>
                  }
                >
                  <Button
                    type="text"
                    onClick={(e) => {
                      e.stopPropagation();
                      setDeletPopupVisible(true);
                    }}
                    className="delete-btn"
                  >
                    <DeleteOutlined />
                  </Button>
                </Popover>
              </>
            ) : (
              <Popover
                trigger="click"
                visible={deletPopupVisible}
                content={
                  <>
                    <p>Do you want to restore this news?</p>
                    <div>
                      <Checkbox
                        onChange={(e) => {
                          setNewsRestoreStatus(e?.target?.checked);
                        }}
                      >
                        Make it active news
                      </Checkbox>
                    </div>
                    <div className="confirmation-button">
                      <Button
                        size="small"
                        type="primary"
                        onClick={() => {
                          handleRestoreNews(newsId);
                        }}
                        loading={loading}
                        className="mr-16"
                      >
                        Yes
                      </Button>
                      <Button
                        size="small"
                        onClick={() => {
                          setDeletPopupVisible(false);
                        }}
                      >
                        No
                      </Button>
                    </div>
                  </>
                }
              >
                <Button
                  type="text"
                  onClick={(e) => {
                    e.stopPropagation();
                    setDeletPopupVisible(true);
                  }}
                >
                  <UndoOutlined />
                </Button>
              </Popover>
            )}
          </Space>
        </div>
      ]}
    >
      {mediaType === 'VIDEO' && (
        <video width="280" height="300" controls>
          <source src={video} type="video/mp4" />
        </video>
      )}
      {mediaType === 'IMAGE' && image && (
        <img src={image} alt="news assets" className="img"></img>
      )}
      <p className="text-center date-text">
        {moment(createdAt).format('MM/DD/YYYY')}
      </p>
      <Typography.Paragraph ellipsis={{ expandable: true }}>
        {description}
      </Typography.Paragraph>
    </Card>
  );
}

export default NewsTile;
